(function(){
	angular.module('ERApp')
	.controller('PoliticasVentaController',PoliticasVentaController);

	function PoliticasVentaController($rootScope){
		$rootScope.curretOption = "POLÌTICAS DE VIAJE";


}

})();
