var paises= [
      {
         "ID_PAIS": "1",
         "ID_REGION": "7",
         "DS_NOMBRE": "AFGANISTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "2",
         "ID_REGION": "7",
         "DS_NOMBRE": "ALBANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "3",
         "ID_REGION": "4",
         "DS_NOMBRE": "ALEMANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "4",
         "ID_REGION": "7",
         "DS_NOMBRE": "AMERICAN SAMOA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "5",
         "ID_REGION": "4",
         "DS_NOMBRE": "ANDORRA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "6",
         "ID_REGION": "7",
         "DS_NOMBRE": "ANGOLA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "7",
         "ID_REGION": "3",
         "DS_NOMBRE": "ANGUILA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "10",
         "ID_REGION": "7",
         "DS_NOMBRE": "ANTARTIDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "8",
         "ID_REGION": "7",
         "DS_NOMBRE": "ANTIGUA AND BARBUDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "9",
         "ID_REGION": "3",
         "DS_NOMBRE": "ANTILLAS HOLANDESAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "11",
         "ID_REGION": "7",
         "DS_NOMBRE": "ARABIA SAUDITA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "12",
         "ID_REGION": "7",
         "DS_NOMBRE": "ARGELIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "13",
         "ID_REGION": "2",
         "DS_NOMBRE": "ARGENTINA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "14",
         "ID_REGION": "7",
         "DS_NOMBRE": "ARMENIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "15",
         "ID_REGION": "3",
         "DS_NOMBRE": "ARUBA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "17",
         "ID_REGION": "6",
         "DS_NOMBRE": "AUSTRALIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "18",
         "ID_REGION": "4",
         "DS_NOMBRE": "AUSTRIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "19",
         "ID_REGION": "7",
         "DS_NOMBRE": "AZERBAIJAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "20",
         "ID_REGION": "3",
         "DS_NOMBRE": "BAHAMAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "21",
         "ID_REGION": "7",
         "DS_NOMBRE": "BAHREIN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "22",
         "ID_REGION": "7",
         "DS_NOMBRE": "BANGLADESH",
         "estatus": "A"
      },
      {
         "ID_PAIS": "23",
         "ID_REGION": "3",
         "DS_NOMBRE": "BARBADOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "39",
         "ID_REGION": "4",
         "DS_NOMBRE": "BELGICA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "24",
         "ID_REGION": "2",
         "DS_NOMBRE": "BELICE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "25",
         "ID_REGION": "7",
         "DS_NOMBRE": "BENIN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "26",
         "ID_REGION": "3",
         "DS_NOMBRE": "BERMUDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "27",
         "ID_REGION": "7",
         "DS_NOMBRE": "BIELORRUSIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "28",
         "ID_REGION": "2",
         "DS_NOMBRE": "BOLIVIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "29",
         "ID_REGION": "7",
         "DS_NOMBRE": "BOSNIA Y HERZEGOVINA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "30",
         "ID_REGION": "7",
         "DS_NOMBRE": "BOTSUANA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "31",
         "ID_REGION": "7",
         "DS_NOMBRE": "BOUVET ISLAND",
         "estatus": "A"
      },
      {
         "ID_PAIS": "32",
         "ID_REGION": "2",
         "DS_NOMBRE": "BRASIL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "33",
         "ID_REGION": "7",
         "DS_NOMBRE": "BRITISH INDIAN OCEAN TERRITORY",
         "estatus": "A"
      },
      {
         "ID_PAIS": "34",
         "ID_REGION": "7",
         "DS_NOMBRE": "BRUNEI DARUSSALAM",
         "estatus": "A"
      },
      {
         "ID_PAIS": "35",
         "ID_REGION": "7",
         "DS_NOMBRE": "BULGARIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "36",
         "ID_REGION": "7",
         "DS_NOMBRE": "BURKINA FASO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "37",
         "ID_REGION": "7",
         "DS_NOMBRE": "BURUNDI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "38",
         "ID_REGION": "7",
         "DS_NOMBRE": "BUTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "40",
         "ID_REGION": "7",
         "DS_NOMBRE": "CABO VERDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "41",
         "ID_REGION": "7",
         "DS_NOMBRE": "CAMBOYA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "42",
         "ID_REGION": "7",
         "DS_NOMBRE": "CAMERUN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "44",
         "ID_REGION": "1",
         "DS_NOMBRE": "CANADA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "45",
         "ID_REGION": "7",
         "DS_NOMBRE": "CHAD",
         "estatus": "A"
      },
      {
         "ID_PAIS": "46",
         "ID_REGION": "2",
         "DS_NOMBRE": "CHILE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "47",
         "ID_REGION": "5",
         "DS_NOMBRE": "CHINA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "48",
         "ID_REGION": "7",
         "DS_NOMBRE": "CHIPRE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "49",
         "ID_REGION": "2",
         "DS_NOMBRE": "COLOMBIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "50",
         "ID_REGION": "7",
         "DS_NOMBRE": "COMORES",
         "estatus": "A"
      },
      {
         "ID_PAIS": "51",
         "ID_REGION": "7",
         "DS_NOMBRE": "CONGO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "52",
         "ID_REGION": "7",
         "DS_NOMBRE": "COREA DEL NORTE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "53",
         "ID_REGION": "6",
         "DS_NOMBRE": "COREA DEL SUR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "54",
         "ID_REGION": "2",
         "DS_NOMBRE": "COSTA RICA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "55",
         "ID_REGION": "7",
         "DS_NOMBRE": "COTE D IVOIRE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "56",
         "ID_REGION": "7",
         "DS_NOMBRE": "CROACIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "57",
         "ID_REGION": "3",
         "DS_NOMBRE": "CUBA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "58",
         "ID_REGION": "4",
         "DS_NOMBRE": "DINAMARCA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "59",
         "ID_REGION": "7",
         "DS_NOMBRE": "DJIBOUTI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "60",
         "ID_REGION": "3",
         "DS_NOMBRE": "DOMINICA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "61",
         "ID_REGION": "7",
         "DS_NOMBRE": "EAST TIMOR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "62",
         "ID_REGION": "2",
         "DS_NOMBRE": "ECUADOR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "63",
         "ID_REGION": "7",
         "DS_NOMBRE": "EGIPTO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "64",
         "ID_REGION": "2",
         "DS_NOMBRE": "EL SALVADOR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "65",
         "ID_REGION": "7",
         "DS_NOMBRE": "EL VATICANO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "66",
         "ID_REGION": "7",
         "DS_NOMBRE": "EMIRATOS ARABES UNIDOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "67",
         "ID_REGION": "7",
         "DS_NOMBRE": "ERITREA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "68",
         "ID_REGION": "7",
         "DS_NOMBRE": "ESLOVAQUIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "69",
         "ID_REGION": "7",
         "DS_NOMBRE": "ESLOVENIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "70",
         "ID_REGION": "4",
         "DS_NOMBRE": "ESPAÑA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "71",
         "ID_REGION": "1",
         "DS_NOMBRE": "ESTADOS UNIDOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "72",
         "ID_REGION": "7",
         "DS_NOMBRE": "ESTONIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "73",
         "ID_REGION": "7",
         "DS_NOMBRE": "ETIOPIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "74",
         "ID_REGION": "7",
         "DS_NOMBRE": "FIJI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "75",
         "ID_REGION": "6",
         "DS_NOMBRE": "FILIPINAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "76",
         "ID_REGION": "4",
         "DS_NOMBRE": "FINLANDIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "77",
         "ID_REGION": "4",
         "DS_NOMBRE": "FRANCIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "78",
         "ID_REGION": "7",
         "DS_NOMBRE": "FRENCH GUIANA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "79",
         "ID_REGION": "7",
         "DS_NOMBRE": "FRENCH POLYNESIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "80",
         "ID_REGION": "7",
         "DS_NOMBRE": "FRENCH SOUTHERN TERRITORIES",
         "estatus": "A"
      },
      {
         "ID_PAIS": "81",
         "ID_REGION": "7",
         "DS_NOMBRE": "GABON",
         "estatus": "A"
      },
      {
         "ID_PAIS": "82",
         "ID_REGION": "7",
         "DS_NOMBRE": "GAMBIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "83",
         "ID_REGION": "7",
         "DS_NOMBRE": "GEORGIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "84",
         "ID_REGION": "7",
         "DS_NOMBRE": "GHANA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "85",
         "ID_REGION": "7",
         "DS_NOMBRE": "GIBRALTAR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "86",
         "ID_REGION": "3",
         "DS_NOMBRE": "GRANADA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "87",
         "ID_REGION": "4",
         "DS_NOMBRE": "GRECIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "88",
         "ID_REGION": "7",
         "DS_NOMBRE": "GROENLANDIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "89",
         "ID_REGION": "3",
         "DS_NOMBRE": "GUADALUPE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "90",
         "ID_REGION": "7",
         "DS_NOMBRE": "GUAM",
         "estatus": "A"
      },
      {
         "ID_PAIS": "91",
         "ID_REGION": "2",
         "DS_NOMBRE": "GUATEMALA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "92",
         "ID_REGION": "7",
         "DS_NOMBRE": "GUINEA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "93",
         "ID_REGION": "7",
         "DS_NOMBRE": "GUINEA ECUATORIAL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "94",
         "ID_REGION": "7",
         "DS_NOMBRE": "GUINEA-BISSAU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "95",
         "ID_REGION": "3",
         "DS_NOMBRE": "GUYANA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "96",
         "ID_REGION": "3",
         "DS_NOMBRE": "HAITI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "97",
         "ID_REGION": "7",
         "DS_NOMBRE": "HEARD ISLAND AND MCDONALD ISLA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "98",
         "ID_REGION": "4",
         "DS_NOMBRE": "HOLANDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "99",
         "ID_REGION": "2",
         "DS_NOMBRE": "HONDURAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "100",
         "ID_REGION": "5",
         "DS_NOMBRE": "HONG KONG",
         "estatus": "A"
      },
      {
         "ID_PAIS": "101",
         "ID_REGION": "4",
         "DS_NOMBRE": "HUNGRIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "102",
         "ID_REGION": "6",
         "DS_NOMBRE": "INDIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "103",
         "ID_REGION": "6",
         "DS_NOMBRE": "INDONESIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "104",
         "ID_REGION": "7",
         "DS_NOMBRE": "IRAQ",
         "estatus": "A"
      },
      {
         "ID_PAIS": "105",
         "ID_REGION": "4",
         "DS_NOMBRE": "IRLANDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "106",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISALAS COCOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "107",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLA CHRISTMAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "108",
         "ID_REGION": "4",
         "DS_NOMBRE": "ISLANDIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "109",
         "ID_REGION": "3",
         "DS_NOMBRE": "ISLAS CAIMAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "110",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS COOK",
         "estatus": "A"
      },
      {
         "ID_PAIS": "111",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS FEROE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "112",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS MALVINAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "113",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS MARSHALL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "114",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS MAURICIO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "115",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS SALOMON",
         "estatus": "A"
      },
      {
         "ID_PAIS": "116",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS SANDWHICH",
         "estatus": "A"
      },
      {
         "ID_PAIS": "117",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS TURKS Y CAICOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "118",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISLAS WALLIS Y FUTUNA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "119",
         "ID_REGION": "7",
         "DS_NOMBRE": "ISRAEL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "120",
         "ID_REGION": "4",
         "DS_NOMBRE": "ITALIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "121",
         "ID_REGION": "3",
         "DS_NOMBRE": "JAMAICA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "122",
         "ID_REGION": "5",
         "DS_NOMBRE": "JAPON",
         "estatus": "A"
      },
      {
         "ID_PAIS": "123",
         "ID_REGION": "7",
         "DS_NOMBRE": "JORDANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "124",
         "ID_REGION": "7",
         "DS_NOMBRE": "KAZAKHSTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "125",
         "ID_REGION": "7",
         "DS_NOMBRE": "KENIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "126",
         "ID_REGION": "7",
         "DS_NOMBRE": "KIRIBATI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "127",
         "ID_REGION": "7",
         "DS_NOMBRE": "KUWAIT",
         "estatus": "A"
      },
      {
         "ID_PAIS": "128",
         "ID_REGION": "7",
         "DS_NOMBRE": "KYRGYZSTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "129",
         "ID_REGION": "7",
         "DS_NOMBRE": "LAOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "130",
         "ID_REGION": "7",
         "DS_NOMBRE": "LATVIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "131",
         "ID_REGION": "7",
         "DS_NOMBRE": "LESOTO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "137",
         "ID_REGION": "7",
         "DS_NOMBRE": "LIBANO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "132",
         "ID_REGION": "7",
         "DS_NOMBRE": "LIBERIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "133",
         "ID_REGION": "7",
         "DS_NOMBRE": "LIBIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "134",
         "ID_REGION": "4",
         "DS_NOMBRE": "LIECHTENSTEIN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "135",
         "ID_REGION": "7",
         "DS_NOMBRE": "LITUANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "136",
         "ID_REGION": "4",
         "DS_NOMBRE": "LUXEMBURGO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "138",
         "ID_REGION": "7",
         "DS_NOMBRE": "MACAO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "139",
         "ID_REGION": "7",
         "DS_NOMBRE": "MACEDONIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "140",
         "ID_REGION": "7",
         "DS_NOMBRE": "MADAGASCAR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "141",
         "ID_REGION": "6",
         "DS_NOMBRE": "MALASIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "142",
         "ID_REGION": "7",
         "DS_NOMBRE": "MALAUI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "143",
         "ID_REGION": "7",
         "DS_NOMBRE": "MALDIVAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "145",
         "ID_REGION": "7",
         "DS_NOMBRE": "MALI",
         "estatus": "A"
      },
      {
         "ID_PAIS": "144",
         "ID_REGION": "4",
         "DS_NOMBRE": "MALTA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "146",
         "ID_REGION": "7",
         "DS_NOMBRE": "MARRUECOS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "147",
         "ID_REGION": "7",
         "DS_NOMBRE": "MARTINIQUE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "148",
         "ID_REGION": "7",
         "DS_NOMBRE": "MAURITANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "149",
         "ID_REGION": "7",
         "DS_NOMBRE": "MAYOTTE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "156",
         "ID_REGION": "1",
         "DS_NOMBRE": "MÉXICO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "150",
         "ID_REGION": "7",
         "DS_NOMBRE": "MICRONESIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "151",
         "ID_REGION": "7",
         "DS_NOMBRE": "MOLDAVIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "157",
         "ID_REGION": "4",
         "DS_NOMBRE": "MONACO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "152",
         "ID_REGION": "7",
         "DS_NOMBRE": "MONGOLIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "153",
         "ID_REGION": "3",
         "DS_NOMBRE": "MONTSERRAT",
         "estatus": "A"
      },
      {
         "ID_PAIS": "154",
         "ID_REGION": "7",
         "DS_NOMBRE": "MOZAMBIQUE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "155",
         "ID_REGION": "7",
         "DS_NOMBRE": "MYANMAR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "158",
         "ID_REGION": "7",
         "DS_NOMBRE": "NAMIBIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "159",
         "ID_REGION": "7",
         "DS_NOMBRE": "NAURU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "160",
         "ID_REGION": "7",
         "DS_NOMBRE": "NEPAL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "161",
         "ID_REGION": "2",
         "DS_NOMBRE": "NICARAGUA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "169",
         "ID_REGION": "7",
         "DS_NOMBRE": "NIGER",
         "estatus": "A"
      },
      {
         "ID_PAIS": "162",
         "ID_REGION": "7",
         "DS_NOMBRE": "NIGERIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "163",
         "ID_REGION": "7",
         "DS_NOMBRE": "NIUE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "164",
         "ID_REGION": "7",
         "DS_NOMBRE": "NORFOLK ISLAND",
         "estatus": "A"
      },
      {
         "ID_PAIS": "165",
         "ID_REGION": "7",
         "DS_NOMBRE": "NORTHERN MARIANA ISLANDS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "166",
         "ID_REGION": "4",
         "DS_NOMBRE": "NORUEGA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "167",
         "ID_REGION": "7",
         "DS_NOMBRE": "NUEVA CALEDONIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "168",
         "ID_REGION": "6",
         "DS_NOMBRE": "NUEVA ZELANDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "170",
         "ID_REGION": "7",
         "DS_NOMBRE": "OMAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "171",
         "ID_REGION": "7",
         "DS_NOMBRE": "PAKISTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "172",
         "ID_REGION": "7",
         "DS_NOMBRE": "PALAU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "173",
         "ID_REGION": "7",
         "DS_NOMBRE": "PALESTINIAN TERRITORY",
         "estatus": "A"
      },
      {
         "ID_PAIS": "174",
         "ID_REGION": "2",
         "DS_NOMBRE": "PANAMA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "175",
         "ID_REGION": "7",
         "DS_NOMBRE": "PAPUA NUEVA GUINEA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "176",
         "ID_REGION": "2",
         "DS_NOMBRE": "PARAGUAY",
         "estatus": "A"
      },
      {
         "ID_PAIS": "177",
         "ID_REGION": "2",
         "DS_NOMBRE": "PERU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "178",
         "ID_REGION": "7",
         "DS_NOMBRE": "PITCAIRN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "179",
         "ID_REGION": "4",
         "DS_NOMBRE": "POLONIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "180",
         "ID_REGION": "4",
         "DS_NOMBRE": "PORTUGAL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "181",
         "ID_REGION": "1",
         "DS_NOMBRE": "PUERTO RICO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "182",
         "ID_REGION": "7",
         "DS_NOMBRE": "QATAR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "183",
         "ID_REGION": "4",
         "DS_NOMBRE": "REINO UNIDO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "184",
         "ID_REGION": "7",
         "DS_NOMBRE": "REPUBLICA CENTROAFRICANA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "185",
         "ID_REGION": "4",
         "DS_NOMBRE": "REPUBLICA CHECA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "186",
         "ID_REGION": "7",
         "DS_NOMBRE": "REPUBLICA DEMOCRATICA DEL CONG",
         "estatus": "A"
      },
      {
         "ID_PAIS": "187",
         "ID_REGION": "3",
         "DS_NOMBRE": "REPUBLICA DOMINICANA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "188",
         "ID_REGION": "7",
         "DS_NOMBRE": "REPUBLICA ISLAMICA DE IRAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "189",
         "ID_REGION": "7",
         "DS_NOMBRE": "RUANDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "190",
         "ID_REGION": "7",
         "DS_NOMBRE": "RUMANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "191",
         "ID_REGION": "7",
         "DS_NOMBRE": "RUSIAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "192",
         "ID_REGION": "7",
         "DS_NOMBRE": "SAINT KITTS AND NEVIS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "193",
         "ID_REGION": "7",
         "DS_NOMBRE": "SAINT PIERRE Y MIQUELON",
         "estatus": "A"
      },
      {
         "ID_PAIS": "194",
         "ID_REGION": "7",
         "DS_NOMBRE": "SAMOA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "195",
         "ID_REGION": "4",
         "DS_NOMBRE": "SAN MARINO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "196",
         "ID_REGION": "7",
         "DS_NOMBRE": "SAN VICENTE Y LAS GRANADINAS",
         "estatus": "A"
      },
      {
         "ID_PAIS": "197",
         "ID_REGION": "7",
         "DS_NOMBRE": "SANTA ELENA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "198",
         "ID_REGION": "3",
         "DS_NOMBRE": "SANTA LUCIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "199",
         "ID_REGION": "7",
         "DS_NOMBRE": "SAO TOME AND PRINCIPE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "200",
         "ID_REGION": "7",
         "DS_NOMBRE": "SENEGAL",
         "estatus": "A"
      },
      {
         "ID_PAIS": "201",
         "ID_REGION": "7",
         "DS_NOMBRE": "SERBIA Y MONTENEGRO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "202",
         "ID_REGION": "7",
         "DS_NOMBRE": "SEYCHELLES",
         "estatus": "A"
      },
      {
         "ID_PAIS": "203",
         "ID_REGION": "7",
         "DS_NOMBRE": "SIERRA LEONA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "204",
         "ID_REGION": "6",
         "DS_NOMBRE": "SINGAPUR",
         "estatus": "A"
      },
      {
         "ID_PAIS": "205",
         "ID_REGION": "7",
         "DS_NOMBRE": "SIRIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "206",
         "ID_REGION": "7",
         "DS_NOMBRE": "SOMALIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "207",
         "ID_REGION": "7",
         "DS_NOMBRE": "SRI LANKA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "208",
         "ID_REGION": "7",
         "DS_NOMBRE": "SUAZILANDIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "209",
         "ID_REGION": "7",
         "DS_NOMBRE": "SUDAFRICA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "210",
         "ID_REGION": "7",
         "DS_NOMBRE": "SUDAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "211",
         "ID_REGION": "4",
         "DS_NOMBRE": "SUECIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "212",
         "ID_REGION": "4",
         "DS_NOMBRE": "SUIZA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "213",
         "ID_REGION": "3",
         "DS_NOMBRE": "SURINAM",
         "estatus": "A"
      },
      {
         "ID_PAIS": "214",
         "ID_REGION": "7",
         "DS_NOMBRE": "SVALBARD AND JAN MAYEN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "215",
         "ID_REGION": "6",
         "DS_NOMBRE": "TAILANDIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "216",
         "ID_REGION": "5",
         "DS_NOMBRE": "TAIWAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "217",
         "ID_REGION": "7",
         "DS_NOMBRE": "TAJIKISTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "218",
         "ID_REGION": "7",
         "DS_NOMBRE": "TANZANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "219",
         "ID_REGION": "7",
         "DS_NOMBRE": "TOGO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "220",
         "ID_REGION": "7",
         "DS_NOMBRE": "TONGA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "221",
         "ID_REGION": "7",
         "DS_NOMBRE": "TOQUELAU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "222",
         "ID_REGION": "3",
         "DS_NOMBRE": "TRINIDAD Y TOBAGO",
         "estatus": "A"
      },
      {
         "ID_PAIS": "226",
         "ID_REGION": "7",
         "DS_NOMBRE": "TUNEZ",
         "estatus": "A"
      },
      {
         "ID_PAIS": "223",
         "ID_REGION": "7",
         "DS_NOMBRE": "TURKMENISTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "224",
         "ID_REGION": "7",
         "DS_NOMBRE": "TURQUIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "225",
         "ID_REGION": "7",
         "DS_NOMBRE": "TUVALU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "227",
         "ID_REGION": "7",
         "DS_NOMBRE": "UCRANIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "228",
         "ID_REGION": "7",
         "DS_NOMBRE": "UGANDA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "229",
         "ID_REGION": "7",
         "DS_NOMBRE": "UNITED STATES MINOR OUTLYING I",
         "estatus": "A"
      },
      {
         "ID_PAIS": "230",
         "ID_REGION": "2",
         "DS_NOMBRE": "URUGUAY",
         "estatus": "A"
      },
      {
         "ID_PAIS": "231",
         "ID_REGION": "7",
         "DS_NOMBRE": "UZBEKISTAN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "232",
         "ID_REGION": "7",
         "DS_NOMBRE": "VANUATU",
         "estatus": "A"
      },
      {
         "ID_PAIS": "233",
         "ID_REGION": "2",
         "DS_NOMBRE": "VENEZUELA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "234",
         "ID_REGION": "6",
         "DS_NOMBRE": "VIETNAM",
         "estatus": "A"
      },
      {
         "ID_PAIS": "235",
         "ID_REGION": "7",
         "DS_NOMBRE": "VIRGIN ISLANDS BRITISH",
         "estatus": "A"
      },
      {
         "ID_PAIS": "236",
         "ID_REGION": "7",
         "DS_NOMBRE": "VIRGIN ISLANDS U.S.",
         "estatus": "A"
      },
      {
         "ID_PAIS": "237",
         "ID_REGION": "7",
         "DS_NOMBRE": "WESTERN SAHARA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "238",
         "ID_REGION": "7",
         "DS_NOMBRE": "YEMEN",
         "estatus": "A"
      },
      {
         "ID_PAIS": "239",
         "ID_REGION": "7",
         "DS_NOMBRE": "ZAIRE",
         "estatus": "A"
      },
      {
         "ID_PAIS": "240",
         "ID_REGION": "7",
         "DS_NOMBRE": "ZAMBIA",
         "estatus": "A"
      },
      {
         "ID_PAIS": "241",
         "ID_REGION": "7",
         "DS_NOMBRE": "ZIMBABUE",
         "estatus": "A"
      }
   ];