(function(){
    angular.module('ERApp')
        .controller('ViajesFuturosController',ViajesFuturosController);

    function ViajesFuturosController($rootScope,$window,$http,commonModal,commonFn){
        $rootScope.curretOption = "VIAJES FUTUROS";

        var ViajesFuturosVm = this;
        ViajesFuturosVm.mostrarDetalle = mostrarDetalle;
        ViajesFuturosVm.cargarBoleto =  cargarBoleto;
        ViajesFuturosVm.editarBoleto = editarBoleto;
        ViajesFuturosVm.compartirBoleto = compartirBoleto;
        ViajesFuturosVm.duplicarBoleto = duplicarBoleto;
        ViajesFuturosVm.deleteBoleto = deleteBoleto;
        ViajesFuturosVm.getViajesFuturos = getViajesFuturos;
        ViajesFuturosVm.showFuturos = false;

        if ($rootScope.boleto === null){
          $window.location.href = "#/viajesFuturos";
        }
        ViajesFuturosVm.activoPadre = [];
        ViajesFuturosVm.activoHijo = [];

        ViajesFuturosVm.uniqueTicket ={};

        getViajesFuturos();






        function  mostrarDetalle(index,indexChildren,ventas,boletos){
          console.log("parent "+index);
          console.log("children "+indexChildren);
          console.log("ventas "+ ventas);
          console.log("boletos "+ boletos);
           for(var i =0;i < ventas;i++){
              ViajesFuturosVm.activoPadre[i] = false;
            }
           for(var r = 0; r < boletos;r++){
            ViajesFuturosVm.activoHijo[r] = false;
           }

          ViajesFuturosVm.activoPadre[index] = true;
          ViajesFuturosVm.activoHijo[indexChildren] = true;
        }


        // funcion que rediercciona a ver boleto y pasa el index del boleto a ver
        function cargarBoleto(id_venta,id_boleto){
            $rootScope.indexBoleto = id_boleto
            $rootScope.boleto = ViajesFuturosVm.result.result[id_venta].boletos[id_boleto];
            $window.location.href = "#/verBoleto";
        }


        // funcion que rediercciona a editar boleto y pasa el index del boleto a editar
        function editarBoleto(id_venta,id_boleto){
            console.log("id_venta "+id_venta+" id_boleto "+id_boleto);
            $rootScope.indexBoleto = id_boleto;
            console.log("==================");
            console.log(ViajesFuturosVm.result.result[id_venta].boletos[id_boleto]);
            $rootScope.boleto = ViajesFuturosVm.result.result[id_venta].boletos[id_boleto];
            $window.location.href = "#/editarViaje";

        }


        function compartirBoleto(id_venta,id_boleto){
            $rootScope.indexBoleto = id_boleto;
            $rootScope.boleto = ViajesFuturosVm.result.result[id_venta].boletos[id_boleto];
            $window.location.href = "#/compartirViajeFut";
        }


        function duplicarBoleto(id_venta,id_boleto){
                    $rootScope.indexBoleto = id_boleto;
                    $rootScope.switchSharedTrip = 0;
                    $rootScope.boleto = ViajesFuturosVm.result.result[id_venta].boletos[id_boleto];
                    $rootScope.page = 499;
                    $window.location.href = "#/";
                }


        function deleteBoleto(id_venta,id_boleto){
                    $rootScope.indexBoleto = id_boleto;
                    $rootScope.boleto = ViajesFuturosVm.result.result[id_venta].boletos[id_boleto];
                    $window.location.href = "#/eliminarViaje";
            }



            function getViajesFuturos(){
              commonModal.showWait();
            $http.post($rootScope.contextoServices+'/webresources/viajes/getMoreViajes',
            {
              inicio:1,
              fin:100,
              idCliente:$rootScope.userVo.idCliente,
              tipo: 'F'}
             )
              .success(function(data){
                console.log(data);
                commonModal.hideWait();
                ViajesFuturosVm.result = data;
                ViajesFuturosVm.showFuturos = true;

          })
          .error(commonFn.errorCommon);
        }



}
})();
