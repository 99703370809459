(function(){
    angular.module('ERApp')
        .factory('commonFn', ['$window','$rootScope', 'commonModal', 'showNotif', commonFn]);

    function commonFn(win,$rootScope, commonModal, showNotif){
          var servicio = {};
          servicio.errorCommon = function(code, status, error){
              commonModal.hideWait();
              showNotif(true,"alerta","modal-error","Los sentimos, no pudimos realizar tu consulta, de favor inténtalo mas tarde");
              return;
          }

          return servicio;
    }

})();
