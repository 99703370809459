(function(){
    angular.module('ERApp')
        .controller('HomeViajesController',HomeViajesController);

    function HomeViajesController($rootScope){
        $rootScope.curretOption = "MIS VIAJES";

        var HomeViajesVm = this;


        HomeViajesVm.switchTile = switchTile;


        function switchTile(opt){
          Console.log("llego : "+opt)
          switch(opt) {
            case 1:
            $rootScope.curretOption = "PR&Oacute;XIMO VIAJE";
            break;
            case 2:
              $rootScope.curretOption = "VIAJES FUTUROS";
            break;
        }
    }

  }

})();
