
(function(){
    angular.module('ERApp')
        .controller('DuplicarViajeAntController',DuplicarViajeAntController);

    function DuplicarViajeAntController($rootScope,$window,$compile){

      if($rootScope.switchSharedTrip === 1){
        $rootScope.curretOption = "VIAJES ANTERIORES";
      }else{
        $rootScope.curretOption = "VIAJES FUTUROS";
      }

        var DuplicarAnterioresVm = this;
        DuplicarAnterioresVm.loadDestinos = loadDestinos;
        DuplicarAnterioresVm.loadOrigen = loadOrigen;
        DuplicarAnterioresVm.inicio = inicio;
        DuplicarAnterioresVm.viaje ={};
        DuplicarAnterioresVm.loadOrigen();
        DuplicarAnterioresVm.uniqueTicket = $rootScope.boleto;
        DuplicarAnterioresVm.viaje.origen = DuplicarAnterioresVm.uniqueTicket.origen;
        DuplicarAnterioresVm.viaje.destino = DuplicarAnterioresVm.uniqueTicket.destino;


        function inicio(){
                    $rootScope.page = 499;
                    $window.location.href = "#/";
                }

        function loadDestinos(){
            console.log('loadDestinos ' + DuplicarAnterioresVm.viaje.origen);
            if(DuplicarAnterioresVm.viaje.origen === ''){return;}
            for(var i=0; i<DuplicarAnterioresVm.origenDestinoList.length; i++){
                var auxOrigen = DuplicarAnterioresVm.origenDestinoList[i];
                if(auxOrigen !== undefined && auxOrigen !== null){
                    console.log("Compare " + DuplicarAnterioresVm.viaje.origen +" === "+ auxOrigen.origen);
                    if(DuplicarAnterioresVm.viaje.origen === auxOrigen.origen){
                        DuplicarAnterioresVm.listaDestinos = auxOrigen.destinos;
                        DuplicarAnterioresVm.viaje.destino = auxOrigen.destinos[0].destino;
                    }
                }
            }
        }


        function loadOrigen(){
                   DuplicarAnterioresVm.tiposViajesList = [{
                       value : 'S',
                       label : 'Viaje sencillo'
                   },
                   {
                       value : 'R',
                       label : 'Viaje redondo'
                   }];
                   DuplicarAnterioresVm.peticion =
                           {"result":
                               {"origenes":
                                   [null,
                                       {"descripcion":"4PTE PUEBLA","destinos":
                                           [
                                               {"descripcion":"CONDESA D.F.","destino":"CONDESA"},
                                               {"descripcion":"EL ANGEL D.F.","destino":"ELANGEL"},
                                               {"descripcion":"ANTARA D.F.","destino":"PANTARA"},
                                               {"descripcion":"SANTA FE D.F.","destino":"STAFE"},
                                               {"descripcion":" WTC D.F.","destino":"WTC"}
                                           ],
                                           "origen":"4PTE"
                                       },
                                       {"descripcion":"CONDESA D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"CONDESA"
                                       },
                                       {"descripcion":"EL ANGEL D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"ELANGEL"
                                       },
                                       {"descripcion":"ANTARA D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"},
                                               {"descripcion":"PZ. SN. DIEGO-CHOLULA","destino":"PSNDIEGO"}
                                           ],
                                           "origen":"PANTARA"
                                       },
                                       {"descripcion":"SANTA FE D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"STAFE"
                                       },
                                       {"descripcion":" WTC D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"WTC"}
                                       ],
                                       "errorCode":"000","errorMsg":"La consulta se realizó satisfactoriamente",
                                       "operacionExitosa":true
                                   },
                                   "success":true
                               };
                               //LA VARIABLE DuplicarAnterioresVm.origenDestinoList ES LA QUE CARGA TODOS LOS ORIGENES
                                DuplicarAnterioresVm.origenDestinoList = DuplicarAnterioresVm.peticion.result.origenes;
                                DuplicarAnterioresVm.viaje.origen = DuplicarAnterioresVm.origenDestinoList[1].origen;
                               DuplicarAnterioresVm.loadDestinos();
               }

}


})();
