(function(){
	angular.module('ERApp')
	.controller('RecuperaContrasenaController',RecuperaContrasenaController);

	function RecuperaContrasenaController($rootScope,$http,showNotif,$window,commonModal,commonFn){
		$rootScope.curretOption = "";
    console.log("Entrando a la pantalla");
		var RecuperaVm = this;
    RecuperaVm.recuperar = recuperar;
    RecuperaVm.user;


    function recuperar(){
      commonModal.showWait();
        $http.post($rootScope.contextoServices+'/webresources/client/forgotPass',
        { mail:RecuperaVm.user
        })
        .success(function(data){
          commonModal.hideWait();
        if(data.status == "S"){
                showNotif(
                 true,
                 'informacion',
                 'modal-azul',
                 'Se le enviara un correo electronico con los nuevos datos de su cuenta');
                $window.location.href = "#/login"
        }else{
          showNotif(
           true,
           'alerta',
           'modal-azul',
           'La cuenta de correo de electronico no existe.');
        }

        })
        .error(commonFn.errorCommon);
    }








	}// fin funcion DireccionClienteController

})();
