(function(){
    angular.module('ERApp')
        .factory('seleccionOrigen', ['$http','$rootScope', '$routeParams','commonModal','commonFn','showNotif', seleccionOrigen]);

        function seleccionOrigen($http, $rootScope, $routeParams, commonModal, commonFn, showNotif){
          var message = {
              NO_PASAJERO : 'DEBE SELECCIONAR AL MENOS UN PASAJERO',
              FECHA_MAYOR : "LA FECHA DE REGRESO NO PUEDE SER MENOR A LA DE SALIDA.",
              SOLO_MENORES : "LOS MENORES DEBEN VIAJAR ACOMPAÑADOS DE UN ADULTO."
          }
            var service = {};

            service.origenValidacion = origenValidacion;

            service.iniciaSeleccionOrigenDestino = function(CompraVm){
                
                console.log("<===== iniciaSeleccionOrigenDestino 3.1 =====> " + $rootScope.contextoServices);
              
                
                CompraVm.tiposViajesList = [{
                    value : 'S',
                    label : 'Viaje sencillo'
                },
                {
                    value : 'R',
                    label : 'Viaje redondo'
                }];
                $http.get($rootScope.contextoServices+'/webresources/search/origen',{})
                    .success(function(data){
                        if(data.success){
                          // console.log("DAtosCorridas");
                          // console.log(data);
                            CompraVm.origenDestinoList = data.result.origenes;
                            var filter = $routeParams.filter;
                            if (filter != undefined && filter == 'airport'){
                            CompraVm.origenDestinoList = 
                              CompraVm.origenDestinoList.filter(function(value){
                                console.log("it")
                                console.log(value)
                                if(!value){return false}
                                return value.origen == "AERO" || value.origen == "AEROT2" || value.origen == "4PTE"
                              });
                            }
                            if($rootScope.page === 499){
                              CompraVm.viaje.cveOrigen = $rootScope.boleto.origen;
                            }else {
                              CompraVm.viaje.cveOrigen = CompraVm.origenDestinoList[1].origen;
                              CompraVm.viaje.origen = CompraVm.origenDestinoList[1].descripcion;
                            }
                            CompraVm.loadDestinos();
                        }else{
                            console.log("Ocurrio un error");
                        }
                        commonModal.hideWait();
                    }).error(commonFn.errorCommon);

            }

            function origenValidacion(CompraVm){
              if (CompraVm.boletos.ninos > 0) {
                if (CompraVm.boletos.inapam <= 0 && CompraVm.boletos.adultos <= 0) {
                  showNotif(true,"alerta","modal-azul", message.SOLO_MENORES);
                  return;
                }
              }
              if (CompraVm.boletos.inapam <= 0 && CompraVm.boletos.ninos <= 0 && CompraVm.boletos.adultos <= 0) {
                showNotif(true,"alerta","modal-azul", message.NO_PASAJERO);
                return;
              }
              if (CompraVm.dateReturn < CompraVm.dateDeparture && CompraVm.viaje.tipo == 'R') {
                showNotif(true,"alerta","modal-azul", message.FECHA_MAYOR);
                return;
              }
              CompraVm.boletosIda = angular.copy(CompraVm.boletos);
              CompraVm.boletosVuelta = angular.copy(CompraVm.boletos);
              CompraVm.flagBuy = true;
            }
            return service;

        }

})();
