 (function(){
    angular.module('ERApp', [
      'ngRoute',
      'mobile-angular-ui',
      'ui.bootstrap',
      'ngCookies'

    ])
.config(config)
.run(run)
.controller('DatepickerDemoCtrl', function ($scope) {
  $scope.today = function() {

    $scope.dt = new Date();  function compartirBoleto(id_boleto){
          $rootScope.indexBoleto = id_boleto;
          $rootScope.boleto = ViajesAnterioresVm.result.result[id_boleto];
          $window.location.href = "#/compartirViajeAnt";
      }
  };
  $scope.today();

  $scope.clear = function () {
    $scope.dt = null;
  };

  // Disable weekend selection
  $scope.disabled = function(date, mode) {
    return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
  };

  $scope.toggleMin = function() {
    $scope.minDate = $scope.minDate ? null : new Date();
  };
  $scope.toggleMin();
  $scope.maxDate = new Date(2020, 5, 22);

  $scope.open = function($event) {
    $scope.status.opened = true;
  };

  $scope.setDate = function(year, month, day) {

    $scope.dt = new Date(year, month, day);

  };

  $scope.dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.formats = ['MMMM dd yyyy', 'yyyy-MM-dd','MM.dd.yyyy', 'shortDate'];
  $scope.format = $scope.formats[1];

  $scope.status = {
    opened: false
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 2);
  $scope.events =
    [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

  $scope.getDayClass = function(date, mode) {
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i=0;i<$scope.events.length;i++){
        var currentDay = new Date($scope.events[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return $scope.events[i].status;
        }
      }
    }
    return '';
  };
});




function config($routeProvider,$provide,$httpProvider) {

  $routeProvider.when(
    '/',
       {
         templateUrl:'home.html',
        reloadOnSearch: false,
        templateUrl:'compra/compra-inicio.html'
        //controller : 'MainController as CompraVm'
    }
    ).when('/login',{
        templateUrl:'login.html',
        reloadOnSearch: false,
        controller : 'LoginController'
    }).when('/crea-cuenta',{
        templateUrl:'crea-cuenta.html',
        reloadOnSearch: false

      }).when('/recuperaContarsena',{
          templateUrl:'recupera-contrasena.html',
          reloadOnSearch: false,
          controller: 'RecuperaContrasenaController'
    }).when('/mis-viajes',{
        templateUrl:'viajes/mis-viajes.html',
        controller : 'MisViajesController',
        reloadOnSearch: false
    })
    .when('/informacion',{
        templateUrl:'informacion/informacion.html',
        controller : 'InformacionController',
        reloadOnSearch: false
    }).when('/informacion/rutas',{
        templateUrl:'informacion/rutas-tarifas.html',
        reloadOnSearch: false
    }).when('/informacion/politicas',{
        templateUrl:'informacion/politica-venta.html',
        reloadOnSearch: false
    }).when('/informacion/puntos',{
        templateUrl:'informacion/puntos-venta.html',
        reloadOnSearch: false
    }).when('/informacion/terminos',{
        templateUrl:'informacion/terminos-condiciones.html',
        reloadOnSearch: false
    }).when('/informacion/privacidad',{
        templateUrl:'informacion/privacidad-aviso.html',
        reloadOnSearch: false
    }).when('/datos-cliente/datos-cliente',{
        templateUrl:'datosCliente/datos-cliente.html',
        reloadOnSearch: false

    }).when('/datos-cliente/direccion',{
        templateUrl:'datosCliente/direccion-cliente.html',
        reloadOnSearch: false
    }).when('/duplicarViaje',{
          templateUrl:'viajes/duplicar-viaje.html',
          reloadOnSearch: false,
          controller: 'DuplicarViajeAntController'
      }).when('/felizViaje',{
              templateUrl:'compra/finalizar-pdf.html',
              reloadOnSearch: false,
              controller: 'FelizViajeController'
    }).when('/compartirViajeAnt',{
              templateUrl:'viajes/compartir-viaje.html',
              reloadOnSearch: false,
              controller: 'CompartirViajeAntController'
    }).when('/compartirViajeFut',{
          templateUrl:'viajes/compartir-viaje-futuro.html',
          reloadOnSearch: false,
          controller: 'CompartirViajeFutController'
    }).when('/enviarQueja',{
              templateUrl:'viajes/enviar-queja.html',
              reloadOnSearch: false,
              controller: 'EnviarQuejaController'
    }).when('/eliminarViaje',{
          templateUrl:'viajes/eliminar-viaje.html',
          reloadOnSearch: false,
          controller:'DeleteBoletoController'
    }).when('/verBoleto',{
              templateUrl:'viajes/ver-boleto.html',
              reloadOnSearch: false
                //controller : 'VerBoletoController'

    }).when('/editarViaje',{
              templateUrl:'viajes/editar-viaje.html',
              reloadOnSearch: false,
              controller : 'EditarViajeController'
    }).when('/home-viajes',{
          templateUrl:'viajes/home-viajes.html',
          reloadOnSearch: false,
          controller : 'HomeViajesController'
    }).when('/viajesAnteriores',{
          templateUrl:'viajes/viajes-anteriores.html',
          reloadOnSearch: false,
          controller : 'ViajesAnterioresController'
    }).when('/viajesFuturos',{
      templateUrl:'viajes/viajes-futuros.html',
      reloadOnSearch: false,
      controller : 'ViajesFuturosController'

    }).when('/datos-cliente/informacion',{
        templateUrl:'datosCliente/informacion-personal.html',
        reloadOnSearch: false,


    }).when('/datos-cliente/facturacion',{
        templateUrl:'datosCliente/info-facturacion.html',
        reloadOnSearch: false,
        controller : 'infoFacturcionController'

    }).when('/datos-cliente/eliminar-cuenta',{
        templateUrl:'datosCliente/eliminar-cuenta.html',
        reloadOnSearch: false
    }).when('/compartir-boleto',{
      templateUrl:'viajes/compartir-boleto.html',
      reloadOnSearch: false,
      controller : 'CompartirBoletoController'
    })
    // .when('/facturacion/facturacionNoRegistrado',{
    //   templateUrl:'facturacion/facturacion-noRegistrado/facturacion-noRegistrado.html',
    //   reloadOnSearch: false,
    //   controller : 'facturacionNoRegistradoController'
    //
    // })
    .when('/compra/seleccionarAsientos',{
        templateUrl:'compra/sel-asiento-bus.html',
        reloadOnSearch: false,
        controller : 'MainController'
    }).when('/asociar-boleto',{
      templateUrl:'facturacion/facturacion-registrados/asociar-boleto.html',
      reloadOnSearch: false,
      controller : 'AsociarBoletoController'
    }).when('/datos-cliente/listaDeTarjetas',{
      templateUrl:'datosCliente/listaDeTarjetas.html',
      reloadOnSearch: false,
      controller : 'ListaTarjetasController'
    })

 $httpProvider.defaults.useXDomain = true;


    $httpProvider.defaults.headers.post['Accept'] = 'application/json, text/javascript';
    $httpProvider.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
     $httpProvider.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      $httpProvider.defaults.headers.post['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';

    $httpProvider.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    $httpProvider.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    $httpProvider.defaults.headers.common['Access-Control-Allow-Origin'] = '*';



 console.log($httpProvider);
 delete $httpProvider.defaults.headers.common['X-Requested-With'];

}


function run($rootScope,$http
    , $cookies
){
    $rootScope.userVo ={
        //userName: 'mau@ebus.com',
        //userName: 'z030@gmail.com',
        userName: '',
        //userName: 'qa003@gmail.com',
        userFirmado: false,
        idCliente : ''//'3859'//'3966'
    }
    var userSession = $cookies.get('sessionUser');
    console.log("Cookie =>" + userSession);
    if(userSession === undefined){
        console.log("No session user");
    }else{
        console.log("Si session user");
        $rootScope.userVo = angular.fromJson(userSession);
        console.log($rootScope.userVo.userFirmado);
    }

    $rootScope.contextoServices="/esb";

    // $rootScope.contextoAdmin="/er-admin";
    // $rootScope.contextoServices="/esb/ER_ESB-1.0";
    $rootScope.contextoAdmin="/er-admin";
    $rootScope.curretOption = "";
    $rootScope.userFirmado = false;
    $rootScope.indexBoleto = "0";
    $rootScope.message = "";
    $rootScope.modalMessage = false;
    $rootScope.modalMessageDesbloqueo= false;
    $rootScope.isUserAgencia = false;
    $rootScope.claseModal =  "";
    $rootScope.classIcon = "";
    $rootScope.page = 0;
    $rootScope.relojView = "07:00";
    $rootScope.relojColor = 90;
    $rootScope.relojColorNum = "#A2ECFB";
    $rootScope.relojMenu = false;
    $rootScope.relojMenuOpc = false;
    $rootScope.cambioHorario = false;
    $rootScope.boleto= null;
    $rootScope.switchSharedTrip = -1;
    $rootScope.userName = "";
    $rootScope.waitModal = false;
    $rootScope.pagoAsyn = false;
    $rootScope.ticket={};
    console.log("run...");
    $rootScope.paisesLista = [{}];


}

})();
