(function(){

    angular.module('ERApp')
        .controller('CrearCuentaController',CrearCuentaController);

        function CrearCuentaController($rootScope,$window,$http,showNotif,commonModal,commonFn){
            $rootScope.curretOption = "";
            var creaCuentaVm = this;
            creaCuentaVm.cerrarSesion = cerrarSesion;

          // Variables globales
          creaCuentaVm.arrayImage = ["Maleta","Volante","Mapa","Ticket","Bus"];
          creaCuentaVm.arrayValidate = [false,false,false,false,false];
          creaCuentaVm.idImagenUno = -1;
          creaCuentaVm.idImagenDos = -1;
          creaCuentaVm.terminos = false;
          creaCuentaVm.focus = focus;
          creaCuentaVm.blur = blur;
          creaCuentaVm.wiewRegistrarse =true;




          // Interface  de funciones
          creaCuentaVm.randomImage =  randomImage;
          creaCuentaVm.changeImage =  changeImage;
          creaCuentaVm.cambioActivo =  cambioActivo;





          creaCuentaVm.validateDatos =  validateDatos;
          creaCuentaVm.validatePass =  validatePass;
          randomImage();

    // Function random para el cambio de imagenes Captcha

           function cerrarSesion(){
              $rootScope.userFirmado = false;
              console.log("Cerrando Session");
              $window.location.href = "#/login";

           }


           function focus(){
             console.log("en focus de usuario head")
             creaCuentaVm.wiewRegistrarse = false;
             $('html, body').animate(
             {
              scrollTop: $("#head").offset().top
             },500);
           }

           function blur(valor){
            console.log(valor);
            valor.formCuenta.$setPristine();
            creaCuentaVm.wiewRegistrarse = true;

           }


          function randomImage(){
          var aux;
           creaCuentaVm.idImagenDos = Math.floor(Math.random() * (5 - 1));
           aux = Math.floor(Math.random() * (5 - 1));

           if(creaCuentaVm.idImagenDos ===  aux)
              if (creaCuentaVm.idImagenDos === 4){
                aux = 1;
                }else {
                  aux = creaCuentaVm.idImagenDos + 1;
                }
           creaCuentaVm.idImagenUno = aux;




          }


          // Function activar o desactivar imagenes de Captcha
          function changeImage(pos){
            contadorActivos = 0;
            for(var i=0; i < 5;i++){
                if(creaCuentaVm.arrayValidate[i]){

                  contadorActivos ++;
                }
            }
            if(contadorActivos < 2){

              creaCuentaVm.arrayValidate[pos] = !creaCuentaVm.arrayValidate[pos];
              }else{
                creaCuentaVm.arrayValidate[pos] = false;
                }
          }

          function cambioActivo(){
            creaCuentaVm.terminos = !creaCuentaVm.terminos;
          }



          // Funcion para validar capos requeridos y Captcha;
          function validateDatos(){
           if (creaCuentaVm.pass.length >= 6 && creaCuentaVm.passC.length >= 6){
            if (validatePass()){
                if (creaCuentaVm.arrayValidate[creaCuentaVm.idImagenDos] && creaCuentaVm.arrayValidate[creaCuentaVm.idImagenUno]){
                  if (creaCuentaVm.terminos){
                      commonModal.showWait();
                      $http.post($rootScope.contextoServices+'/webresources/client/create',{mail:creaCuentaVm.mail,password: creaCuentaVm.pass})
                          .success(function(data){
                            commonModal.hideWait();
                          if(data.status === 'C'){
                              showNotif(
                              true,
                              'informacion',
                              'modal-azul',
                              'Su cuenta se creó de manera satisfactoria'
                              )
                              $rootScope.userVo.userName = creaCuentaVm.mail;
                              $rootScope.userVo.idCliente = data.idCliente;
                              $rootScope.userVo.userFirmado = true
                              creaCuentaVm.mail = '';
                              creaCuentaVm.pass = '';
                              creaCuentaVm.passC = '';
                                $window.location.href = "#/";
                            }else {
                                showNotif(
                                  true,
                                  'alerta',
                                  'modal-azul',
                                  'Credenciales Invalidas..'
                                )
                              }
                      })
                      .error(commonFn.errorCommon);
                  }else{
                    showNotif(
                      true,
                      'alerta',
                      'modal-azul',
                      'Debe aceptar los terminos y condiciones.'
                    )

                  }
              }else{
                showNotif(
                  true,
                  'alerta',
                  'modal-azul',
                  'Validacion de Captcha incorrecto'
                )

              }
            }else{
              showNotif(
                true,
                'alerta',
                'modal-azul',
                'Las contraseñas no coinciden.'
              )

            }
          }else{
            showNotif(
              true,
              'alerta',
              'modal-azul',
              'Las contraseñas deben tener una longitud minima de 6 digitos'
            )


          }
          }



        //funcion para validar que las contraseñas sean iguales

        function validatePass(){
          return angular.equals(creaCuentaVm.pass,creaCuentaVm.passC);

        }




}

})();
