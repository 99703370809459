(function(){
    angular.module('ERApp')
        .factory('paginacion', ['$window','$rootScope', paginacion]);

    function paginacion($window,$rootScope){
          var servicio = {};

          servicio.ORIGEN = 0;
          servicio.CORRIDAS_IDA = 1;
          servicio.CORRIDAS_REGRESO = 2;
          servicio.SELECCIONAR_ASIENTO_IDA = 3;
          servicio.SELECCIONAR_ASIENTO_REGRESO = 4;
          servicio.RESUMEN_IDA = 5;
          servicio.RESUMEN_REGRESO = 6;
          servicio.DATOS_USUARIO = 7;
          servicio.DATOS_TARJETA = 8;
          servicio.FELIZ_VIAJE = 9;
          servicio.LIMPIAR_ASIENTOS_IDA=0;

          servicio.paginarNext = function(Compra){
              switch (Compra.page) {
                  case 0:{
                    Compra.page = 1;
                    break;
              }
              case 1:{
                if(Compra.viaje.tipo == 'R'){
                    Compra.page = 2;
                    Compra.botonReturn = "btn-regreso";
                    Compra.titulo = "REGRESO";
                }else{
                    Compra.page = 3;
                }
                break;
              }
              case 2:{
                Compra.page = 3;
                Compra.titulo = "IDA";
                Compra.botonReturn = "btn-registro";

                break;
              }
              case 3:{
                if(Compra.viaje.tipo == 'R'){
                  Compra.botonReturn = "btn-regreso";
                  Compra.titulo = "REGRESO";
                    Compra.page = 4;
                }else{
                	//VAlidar aqui
                  Compra.page = 5;
                }
                break;
              }
              case 4:{
                Compra.page = 5;
                Compra.botonReturn = "btn-registro";
                break;
              }
              case 5:{
                if(Compra.viaje.tipo == 'R'){
                  Compra.botonReturn = "btn-regreso";
                    Compra.page = 6;
                }else{
                  Compra.page = 7;
                }
                break;
              }
              case 6:{
                Compra.botonReturn = "btn-registro";
                Compra.page = 7;
                break;
              }
              case 7:{
                Compra.page = 8;
                break;
              }
              case 8:{
                 Compra.page = 9;
                 $window.location.href = "#/felizViaje";
                break;
              }
          }
          $rootScope.page = Compra.page;
        }

          servicio.paginarBack = function(Compra){
              switch (Compra.page) {
              case 1:{
                if ($rootScope.cambioHorario) {
                  console.log("cambio Link");
                  Compra.loadOrigen();
                  Compra.dateDeparture = new Date();
                  Compra.boletos = {
                      adultos : 1,
                      ninos : 0,
                      inapam : 0
                  };
                  $rootScope.cambioHorario= false;
                }
                  Compra.page = 0;
                  Compra.corridaExt = 1;
                  $rootScope.relojMenuOpc = true;
                  break;
              }
              case 2:{
                var destino = Compra.viaje.cveDestino;
                Compra.viaje.cveDestino = Compra.viaje.cveOrigen;
                Compra.viaje.cveOrigen = destino;
                Compra.fechaElegida = Compra.dateDeparture;
                Compra.titulo = "IDA";
                Compra.botonReturn = "btn-registro";
                Compra.corridaExt = 1;
                // console.log("Entro Corrida IDA");
                Compra.CorridasBack = Compra.CorridaIDA;
                Compra.backCorrida(Compra);
                // Compra.Corridas = Compra.CorridaIDA;
                Compra.page = 1;
                break;
              }
              case 3:{
                if(Compra.viaje.tipo == 'R'){
                    Compra.page = 2;
                    Compra.titulo = "REGRESO";


                    // console.log("Entro Corrida Vuelta");
                    Compra.CorridasBack = Compra.CorridaVUELTA;
                    Compra.backCorrida(Compra);
                }else{
                  Compra.page = 1;
              }
              break;
              }
              case 4:{
                Compra.titulo = "IDA";
                Compra.botonReturn = "btn-registro";
                Compra.autobusCombinado.modules
                Compra.page = 3;
                break;
              }
              case 5:{
                if(Compra.viaje.tipo == 'R'){
                    Compra.page = 4;
                }else{
                  Compra.page = 3;
                }
                break;
              }
              case 6:{
                Compra.page = 5;
                break;
              }
              case 7:{
                if(Compra.viaje.tipo == 'R'){
                    Compra.page = 6;
                }else{
                  Compra.page = 5;
              }
              break;
            }
              case 8:{
                Compra.page = 7;
                break;
              }
              case 9:{
                Compra.page = 8;
                break;
              }
          }
          Compra.corridaSelected = "";
          $rootScope.page = Compra.page;
        }
          return servicio;
    }

})();
