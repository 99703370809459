(function(){
	angular.module('ERApp')
	.controller('InformacionController',InformacionController);

	function InformacionController($rootScope){
		$rootScope.curretOption = "";


}

})();
