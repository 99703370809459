  (function(){
        angular.module('ERApp')
            .controller('facturacionNoRegistradoController',facturacionNoRegistradoController);

        function facturacionNoRegistradoController($rootScope,$http, showNotif,commonModal,commonFn,$route,$location,
          $window){
            $rootScope.curretOption = "FACTURACI\u00d3N";



            var usuarioNR = this;
            var mexico=156;

            usuarioNR.ocultar= false;



        $rootScope.$on('$routeChangeStart',function(angularEvent,next,current) {
         //do you work here
           if (usuarioNR.ocultar){
            usuarioNR.ocultar = false;
             angularEvent.defaultPrevented = true;
           }

         });




            usuarioNR.noGenerada = "SU FACTURA NO PUEDE SER PROCESADA, FAVOR DE VERIFICAR LOS DATOS";
            usuarioNR.facturaGeneradaTxt = "SE HA GENERADO SU FACTURA Y SE ENVIARA A SU CORREO ELECTRONICO GRACIAS";
            $rootScope.clase="facturaGenerada";

            usuarioNR.contador = 0;
            usuarioNR.nuevoFolio =0;
            usuarioNR.viaje = {
                origen : '',
                destino : '',
                tipo : 'S'
            };
            usuarioNR.idPais=156;
            usuarioNR.idEdo=1;
            //BANDERAS PARA EL DESPLAZAMIENTO ENTRE PANTALLAS




            //Variables para el menu de Opciones
            usuarioNR.bndFrmMenu = true;
            usuarioNR.bndFrmOpAutobus = false;
            usuarioNR.bndFrmOpTaxiEst = false;


            //Variables para la opcion de Autobus
            usuarioNR.bndListaAutobus = true;
            usuarioNR.bndFrmBoletosAutobus = true;
            usuarioNR.bndAddFrmbtn=true;
            usuarioNR.bndCamposBoletoAutobus = false;

            usuarioNR.bndFrmListaAutobus=false;
            usuarioNR.bndFacturarBtn = false;
            usuarioNR.bndAddElementBtn= true;
            usuarioNR.bndSiguienteBtn = false;
    //Variables para la opcion de Estacionamiento
            usuarioNR.bndFrmListaTkt = false;
            usuarioNR.buscaPageOne = true;
            usuarioNR.selAsiento = false;

            usuarioNR.bndFrmDatosFac = false;
            usuarioNR.bndAutobus=false;
            usuarioNR.bndTaxi= false;
            usuarioNR.bndEst= false;
            usuarioNR.mostrarSelect = true;
            //usuarioNR.habilitarCampos = false;
            usuarioNR.habilitarCampos=true;
            usuarioNR.btnGuardarDireccion=false;
            usuarioNR.rfcIncorrecto=true;

            usuarioNR.mostrarCbCalle=true;
            usuarioNR.nuevo=false;
            usuarioNR.mostrarInputs=false;
            usuarioNR.getPaisWS = getPaisWS;
            usuarioNR.getEstadoWS = getEstadoWS;
            usuarioNR.llenaComboEstado = llenaComboEstado;
            usuarioNR.llenaComboMunicipio=llenaComboMunicipio;
           // usuarioNR.LimpiarCamposBoleto=LimpiarCamposBoleto;
            //usuarioNR.LimpiarCamposTkt=LimpiarCamposTkt;
            //usuarioNR.getMunicipiosWS = getMunicipiosWS;
            //usuarioNR.llenaFrm = llenaFrm;
            usuarioNR.estadoLista=[];
            usuarioNR.municipioLista=[];

            usuarioNR.listaBoletos=[];
            usuarioNR.listaTkt=[];

            //Se Asocian funciones a la vista
              usuarioNR.loadOrigen = loadOrigen;
            usuarioNR.loadDestinos = loadDestinos;
            usuarioNR.getOrigenes=getOrigenes;
            usuarioNR.addBoleto = addBoleto;
            usuarioNR.deleteItem = deleteItem;
            usuarioNR.mostrarCamposBoleto = mostrarCamposBoleto;
            usuarioNR.mostrarListaAutobus = mostrarListaAutobus;
            usuarioNR.mostrarCamposFac = mostrarCamposFac;
            usuarioNR.mostrarFrmAutobus = mostrarFrmAutobus;
            usuarioNR.mostrarFrmTax = mostrarFrmTax;
            usuarioNR.mostrarFrmEst = mostrarFrmEst;
            usuarioNR.addTkt = addTkt;
            usuarioNR.deleteTkt = deleteTkt;
            usuarioNR.sendRequest=sendRequest;
            usuarioNR.dataValid = dataValid;
           // usuarioNR.btnBackFacturacion=btnBackFacturacion;
            usuarioNR.btnBackBoletos=btnBackBoletos;
            usuarioNR.btnBackTkt=btnBackTkt;
            usuarioNR.btnBackMain=btnBackMain;
            usuarioNR.DatosPersona={};



            usuarioNR.request={
              "boletos":[],
              "datosPersonales":{
              }
            };
            usuarioNR.boleto = {
              "folio":"",
              "numAsiento": "",
              "referencia":"",
              "origen":""
            };

            usuarioNR.tkt = {
              "folio":"",
              "numAsiento": "",
              "referencia":"",
              "origen":""
          }


          function limpiaFormPersona(){

            usuarioNR.DatosPersona.rfc="";
            usuarioNR.DatosPersona.nombre="";
            usuarioNR.DatosPersona.calle="";
            usuarioNR.DatosPersona.colonia="";
            usuarioNR.DatosPersona.noExt="";
            usuarioNR.DatosPersona.noInt="";
            usuarioNR.DatosPersona.cp="";
            usuarioNR.DatosPersona.estadoInpt="";
            usuarioNR.DatosPersona.municipioInpt="";
            usuarioNR.DatosPersona.mail="";


          }

          function limpiaFormBoletos(){

            usuarioNR.boleto.folio="";
            usuarioNR.boleto.origen="";
            usuarioNR.boleto.asiento="";

          }


          function limpiaFormTkt(){

          usuarioNR.tkt.folio="";
          usuarioNR.tkt.referencia="";

          }

            function mostrarFrmAutobus() {
              console.log("******* Boleto de Autobus **********");

              getOrigenes();
              usuarioNR.bndFrmOpAutobus = true;
              usuarioNR.bndFrmOpTaxiEst = false;
              usuarioNR.bndFrmMenu = false;
              usuarioNR.bndAutobus=true;
              usuarioNR.boleto={};
              usuarioNR.request.datosPersonales = {};
              usuarioNR.tkt={};
              usuarioNR.estadoLista=[];
              usuarioNR.paisesLista=[];

            };

            function mostrarFrmTax() {
              console.log("******* Boleto de Taxi **********");
              usuarioNR.bndFrmOpAutobus = false;
              usuarioNR.bndFrmMenu = false;
              usuarioNR.bndEst= false;
              usuarioNR.bndTaxi= true;
              usuarioNR.bndAutobus=false;
              usuarioNR.bndFrmOpTaxiEst = true;
              usuarioNR.bndCamposBoletoAutobus = false;
              usuarioNR.tkt={};
              usuarioNR.boleto={};
              usuarioNR.request.datosPersonales = {};
              usuarioNR.estadoLista=[];
              usuarioNR.paisesLista=[];
              console.log(usuarioNR.bndFrmOpTaxiEst);
            };
            function mostrarFrmEst() {
              console.log("******* Boleto de Estacionamiento ******");
              usuarioNR.bndFrmOpAutobus = false;
              usuarioNR.bndFrmOpTaxiEst = true;
              usuarioNR.bndFrmMenu = false;
              usuarioNR.bndEst= true;
              usuarioNR.bndTaxi= false;
              usuarioNR.bndAutobus=false;
              usuarioNR.bndCamposBoletoAutobus = false;
              usuarioNR.boleto={};
              usuarioNR.request.datosPersonales = {};
              usuarioNR.tkt={};
              usuarioNR.estadoLista=[];
              usuarioNR.paisesLista=[];
            };



            function getOrigenes() {
              console.log("***** getOrigen ****");
                commonModal.showWait();
              $http.get($rootScope.contextoServices+'/webresources/search/origen',{})
                .success(function(data, status, headers, config) {

                  commonModal.hideWait();
                usuarioNR.peticion = data;
                usuarioNR.origenDestinoList = usuarioNR.peticion.result.origenes;
                usuarioNR.boleto.origen = usuarioNR.origenDestinoList[1].origen;
              usuarioNR.loadDestinos();
              }).error(commonFn.errorCommon)
              console.log("fin");
            };

            function loadDestinos(){
                console.log('loadDestinos ' + usuarioNR.boleto.origen);
                if(usuarioNR.boleto.origen === ''){return;}
                for(var i=0; i<usuarioNR.origenDestinoList.length; i++){
                    var auxOrigen = usuarioNR.origenDestinoList[i];
                    if(auxOrigen !== undefined && auxOrigen !== null){
                        console.log("Compare " + usuarioNR.boleto.origen +" === "+ auxOrigen.origen);
                        if(usuarioNR.boleto.origen === auxOrigen.origen){
                            usuarioNR.listaDestinos = auxOrigen.destinos;
                            usuarioNR.viaje.destino = auxOrigen.destinos[0].destino;
                        }
                    }
                }
            }

            function sendRequest() {
              console.log("***** sendRequest Genera ****");
              commonModal.showWait();

              if(usuarioNR.bndFrmOpAutobus){
              usuarioNR.request.boletos=usuarioNR.listaBoletos;

               }else{

                 usuarioNR.request.boletos=usuarioNR.listaTkt;
               }
               var concepto ="";
               var unidadNegocio="";
               if(usuarioNR.bndAutobus){
                 concepto="Boleto";
                 unidadNegocio="TMS"
               }else if (usuarioNR.bndTaxi) {
                 concepto="TICKET TAXI";
                 unidadNegocio="TOPDRIVER"
               }else if (usuarioNR.bndEst) {
                 concepto="TICKET";
                 unidadNegocio="ESTACIONAMIENTO"
               }
               usuarioNR.bndTaxi= false;
               usuarioNR.bndEst= false;
               var paisDesc=  "";
               var edoDesc="";
               var munDesc="";
               for (var x= 0; x < usuarioNR.paisesLista.length; x++) {
                console.log(usuarioNR.idPais+"=="+ usuarioNR.paisesLista[x].ID_PAIS);
                if(usuarioNR.paisesLista[x].ID_PAIS == usuarioNR.idPais){
                    console.log("municipio add"+usuarioNR.paisesLista[x].DS_NOMBRE);
                    paisDesc=usuarioNR.paisesLista[x].DS_NOMBRE;

                    console.log(" PAIS SELECCIONADO ");
                    console.log(paisDesc);
                }
              };
               if (paisDesc=="MEXICO") {



                    edoDesc=usuarioNR.estadoSelect.DS_NOMBRE;
                     munDesc=usuarioNR.municipioSelect.DS_NOMBRE;

                     console.log(" ESTADO SELECCIONADO  ");
                    console.log(edoDesc);


                     console.log(" MUNICIPIO SELECCIONADO  ");
                    console.log(munDesc);


             }else {

                edoDesc=usuarioNR.DatosPersona.estadoInpt;
                munDesc=usuarioNR.DatosPersona.municipioInpt;
             }

             console.log("Estado"+edoDesc);
             console.log("Pais"+paisDesc);


               usuarioNR.request.datosPersonales = {
                   "nombre": usuarioNR.DatosPersona.nombre.toUpperCase(),
                   "numero": usuarioNR.DatosPersona.noExt,
                   "estado": edoDesc,
                   "pais": paisDesc,
                   "colonia": usuarioNR.DatosPersona.colonia.toUpperCase(),
                   "mail": usuarioNR.DatosPersona.mail.toLowerCase(),
                   "calle": usuarioNR.DatosPersona.calle.toUpperCase(),
                   "delegacion": munDesc,
                   "cp": usuarioNR.DatosPersona.cp,
                   "concepto":concepto,
                   "unidadNegocio":unidadNegocio,
                   "producto":concepto,
                   "rfc": usuarioNR.DatosPersona.rfc.toUpperCase()

               };

               console.log(usuarioNR.request);
               console.log("DATOS PERSONALES :::::>>>>"+usuarioNR.request.datosPersonales);

               console.log(usuarioNR.request.datosPersonales);



               $http.post($rootScope.contextoServices+'/webresources/facturacion/genera',usuarioNR.request)
                        .success(function(data, status, headers, config) {
                          //---  commonModal.hideWait();
                          commonModal.hideWait();
                          console.log('Respuesta > ');
                          console.log(data);
                          if (data.result.operacionExitosa == false) {


                              showNotif(true,"alerta","modal-azul",data.result.errorMsg.toUpperCase());

                          }else {

                              limpiaFormPersona();
                              limpiaFormBoletos();
                              limpiaFormTkt();


                              usuarioNR.listaBoletos=[];
                              usuarioNR.listaTkt=[];


                            btnBackMain();
                            showNotif(true,"facturaGenerada","modal-azul",usuarioNR.facturaGeneradaTxt);


                          }





                        }).error(commonFn.errorCommon)

            };

            //Funcion para la carga de origenes
            function loadOrigen(){
                usuarioNR.tiposViajesList = [{
                    value : 'S',
                    label : 'Viaje sencillo'
                },
                {
                    value : 'R',
                    label : 'Viaje redondo'
                }];
            };




            function addBoleto(){
               console.log("****ADD BOLETO****");

               console.log(usuarioNR.listaBoletos);
                usuarioNR.listaBoletos.push(usuarioNR.boleto);
                console.log(usuarioNR.listaBoletos);
                usuarioNR.contador ++;
                usuarioNR.mostrarListaAutobus();
                usuarioNR.bndAddElementBtn = true;
                usuarioNR.boleto={};
              };

            function deleteItem(id) {
                  console.log(usuarioNR.listaBoletos.length);
                   usuarioNR.listaBoletos.splice(id,1);
                 };


        function addTkt(){
            console.log("valor de usuarioNR.tkt:::: "+usuarioNR.tkt);
            var date = new Date(usuarioNR.tkt.referencia);
            var options = {
                weekday: "long", year: "numeric", month: "short",
                day: "numeric", hour: "2-digit", minute: "2-digit"
            };



            var fecha = date.toLocaleDateString("en-US")


            var day=date.getDate();
            var month=date.getMonth()+1;
            var year=date.getFullYear();



            if(day < 10){

               day="0"+day;
            }


            if(month < 10){

               month="0"+month;
            }


            console.log("dia "+day);
            console.log("mes "+month);
            console.log("año "+year);


            var fullDateFormat=day+"/"+month+"/"+year;


                  console.log("fecha>>>>"+fullDateFormat);
                   usuarioNR.tkt.numAsiento= "";
                   usuarioNR.tkt.origen= "";

            usuarioNR.tkt.referencia=fullDateFormat;
            usuarioNR.listaTkt.push(usuarioNR.tkt);
            console.log(usuarioNR.listaTkt);
            usuarioNR.contador ++;
            usuarioNR.mostrarListaAutobus();
            usuarioNR.bndAddElementBtn = true;
            usuarioNR.tkt={};

            };
            function deleteTkt(id) {
              console.log(usuarioNR.listaTkt.length);
               usuarioNR.listaTkt.splice(id,1);
            };



            function mostrarListaAutobus(){
              usuarioNR.bndListaAutobus = true;
              usuarioNR.bndAddFrmbtn=true;
              usuarioNR.bndSiguienteBtn = true;
              usuarioNR.bndCamposBoletoAutobus = false;
              usuarioNR.bndFrmBoletosAutobus = false;
              usuarioNR.bndFrmDatosFac = false;
              usuarioNR.bndFrmListaAutobus=false;
              usuarioNR.bndFacturarBtn = false;
              usuarioNR.bndAddElementBtn= false;
              usuarioNR.bndFrmMenu=false;
        };
            function mostrarCamposBoleto()
              {
                console.log('mostrarCamposBoleto');
                console.log(usuarioNR.bndCamposBoletoAutobus);
                usuarioNR.bndCamposBoletoAutobus = true;
                usuarioNR.bndFrmBoletosAutobus = true;
                usuarioNR.bndFrmDatosFac = false;
                usuarioNR.bndFrmListaAutobus=false;
                usuarioNR.bndListaAutobus=false;
                usuarioNR.bndAddElementBtn = false;
                usuarioNR.bndFrmMenu=false;

                //


                console.log(usuarioNR.bndCamposBoletoAutobus);

              };

        function mostrarCamposFac(){



          console.log("**MOSTRAR CAMPOS FACTURACION ...**");
        //  commonModal.showWait();


              // var h = window.innerHeight;

              // console.log("*** mostrarCamposFac ****");
              // console.log(h);


              // var h1=h-252;

              // console.log(h1);
              // document.getElementById("rfcSeccion").style.height = h1+"px";


          usuarioNR.bndFrmDatosFac = true;
          usuarioNR.bndListaAutobus = false;
          usuarioNR.bndAddFrmbtn=false;
          usuarioNR.bndCamposBoletoAutobus = false;
          usuarioNR.bndFrmBoletosAutobus = false;
          usuarioNR.bndFrmListaAutobus=false;
          usuarioNR.bndFacturarBtn = true;
          usuarioNR.bndAddElementBtn= false;
          usuarioNR.bndSiguienteBtn = false;
          usuarioNR.bndFrmMenu=false;
          usuarioNR.getPaisWS();

        };



         function getPaisWS() {
           console.log("********GET PAISES WS******");
           commonModal.showWait();
           $http.get($rootScope.contextoAdmin+'/REST/ERBUS/paises',{})
           .success(function(data){
               console.log("*** success *** ");
               commonModal.hideWait();
               if(data.estatus){

                   console.log(data);
                    usuarioNR.paisesLista = data.result;
                   usuarioNR.DatosPersona.pais = "156";
                   llenaComboEstado("156");
                   llenaComboMunicipio("1");
                  //usuarioNR.DatosPersona.estado = "1";
                  usuarioNR.mostrarSelect=true;
                  usuarioNR.mostrarInputs=false;
               }
           }).error(commonFn.errorCommon)
         };//fin getPais




          var estadosList = [];
          function llenaComboEstado (idPais){
            usuarioNR.idPais=idPais;
             console.log("***** llenaComboEstado ****");
             console.log(idPais);
             usuarioNR.estadoLista=[];
             // valida que se va a mostrar  inputs o combos
            if(idPais != 156){
                 usuarioNR.mostrarInputs=true;
                 usuarioNR.mostrarSelect=false;
                 usuarioNR.DatosPersona.estado="";
                 usuarioNR.DatosPersona.ciudad="";
                 usuarioNR.DatosPersona.estadoInpt="";


             }else if(idPais == mexico){
                 usuarioNR.mostrarInputs=false;
                 usuarioNR.mostrarSelect=true;
                 commonModal.showWait();
                 $http.get($rootScope.contextoAdmin+'/REST/ERBUS/estados',{})
                 .success(function(data){
                     console.log("*** success *** ");
                     commonModal.hideWait();
                     if(data.estatus){
                         console.log("*** data *** "+data);
                         usuarioNR.estadoLista=data.result;
                         usuarioNR.DatosPersona.estadoLista = usuarioNR.estadoLista;
                         console.log("usuarioNR.estadoLista"+ usuarioNR.estadoLista);



                        usuarioNR.estadoSelect=usuarioNR.estadoLista[0];
                        usuarioNR.llenaComboMunicipio(usuarioNR.estadoLista[0])


                     }else{
                         console.log("*** error getPaises *** ");
                     }
                 }).error(commonFn.errorCommon)


             }

           };
           // fin funcion llenaComboEstado


           function getEstadoWS(){
             commonModal.showWait();
             $http.get($rootScope.contextoAdmin+'/REST/ERBUS/estados',{})
             .success(function(data){
                 console.log("*** success *** ");
                 commonModal.hideWait();
                 if(data.estatus){
                     console.log("*** data *** "+data);
                     usuarioNR.estadoLista=data.result;
                     usuarioNR.DatosPersona.estadoLista = usuarioNR.estadoLista;
                     console.log("usuarioNR.estadoLista"+ usuarioNR.estadoLista);
                 }else{
                     console.log("*** error getPaises *** ");
                 }
             }).error(commonFn.errorCommon)

           };//fin getEstadoWS



          var municipiosLista=[];
          function llenaComboMunicipio (idEdo){
             console.log("***** llenaComboMunicipio ****");
             console.log(idEdo);
             usuarioNR.idEdo=idEdo;
               commonModal.showWait();
             $http.get($rootScope.contextoAdmin+'/REST/ERBUS/municipios/'+idEdo.ID_ESTADO,{})
             .success(function(data){
                 console.log("*** success *** ");
                 commonModal.hideWait();

                     console.log("*** data *** "+data);
                     console.log(data);
                     usuarioNR.municipioLista=[];
                      if(data != null){

                        municipiosLista=[];
                        console.log("idEdo"+idEdo.ID_ESTADO);
                        for (var x= 0; x < data.result.length; x++) {
                         console.log(idEdo+"=="+ data.result[x].ID_ESTADO);
                         if(data.result[x].ID_ESTADO == idEdo.ID_ESTADO){
                             console.log("municipio add"+data.result[x].DS_NOMBRE);
                             municipiosLista.push(data.result[x]);
                         }
                     };
                     usuarioNR.DatosPersona.municipioLista=municipiosLista;


                     usuarioNR.municipioSelect=municipiosLista[0];
                   }// fin if idEdo != null



             }).error(commonFn.errorCommon)



             };//fin funcion llenaComboMunicipio

           function dataValid(idTest){
             console.log("*******VALIDAR RFC********");
             var rfc = idTest;
             var strCorrecta;
             console.log(idTest);
             if(rfc != null){
               if (rfc.length >= 12){
                 if (rfc.length == 12){
                   var valid = '^(([A-Z]|[a-z]|&){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
                 }else if(rfc.length == 13){
                   var valid = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
                 }
                 var validRfc = new RegExp(valid);
                 var matchArray = rfc.match(validRfc);
                 if (matchArray==null){
                   console.log("RFC INCORRECTO");
                   usuarioNR.rfcIncorrecto=true;
                 }
                 else{
                   console.log("RFC CORRECTO");
                   usuarioNR.rfcIncorrecto=false;
                 }
               }
               else{
                 console.log("RFC INCORRECTA");
                 usuarioNR.rfcIncorrecto=true;
               }
             }else{
               usuarioNR.rfcIncorrecto=false;
             }
           };

           //regresa pantalla boleto de autobus
           function btnBackBoletos(){


             limpiaFormPersona();
             limpiaFormBoletos();
             limpiaFormTkt();

             usuarioNR.listaBoletos=[];


             usuarioNR.bndFrmDatosFac = false;
             usuarioNR.bndListaAutobus = true;
             usuarioNR.bndAddFrmbtn=true;
             usuarioNR.bndCamposBoletoAutobus = false;
             usuarioNR.bndFrmOpAutobus = true;
             usuarioNR.bndFrmListaAutobus=true;
             usuarioNR.bndFacturarBtn = false;
             usuarioNR.bndAddElementBtn= true;
             usuarioNR.bndSiguienteBtn = true;

           };//finbackBoletos
           //regresa pantalla ticket
           function btnBackTkt(){


             limpiaFormPersona();
             limpiaFormBoletos();
             limpiaFormTkt();



            usuarioNR.listaTkt=[];

             usuarioNR.bndFrmDatosFac = false;
             usuarioNR.bndListaAutobus = true;
             usuarioNR.bndAddFrmbtn=true;
             usuarioNR.bndCamposBoletoAutobus = false;
             usuarioNR.bndFrmOpTaxiEst = true;
             usuarioNR.bndFrmListaAutobus=true;
             usuarioNR.bndFacturarBtn = false;
             usuarioNR.bndAddElementBtn= true;
             usuarioNR.bndSiguienteBtn = true;
             usuarioNR.bndFrmMenu=false;
           };//finbacktkt
           //regresar pantalla principal
           function btnBackMain(){


             limpiaFormPersona();
             limpiaFormBoletos();
             limpiaFormTkt();

               usuarioNR.listaBoletos=[];
            usuarioNR.listaTkt=[];



             usuarioNR.bndFrmMenu=true;
             usuarioNR.bndFrmDatosFac = false;
             usuarioNR.bndListaAutobus = false;
             usuarioNR.bndAddFrmbtn=false;
             usuarioNR.bndCamposBoletoAutobus = false;
             usuarioNR.bndFrmOpTaxiEst = false;
             usuarioNR.bndFrmListaAutobus=false;
             usuarioNR.bndFacturarBtn = false;
             usuarioNR.bndAddElementBtn= true;
             usuarioNR.bndSiguienteBtn = false;
             usuarioNR.bndFrmOpAutobus =false;
             usuarioNR.bndFrmOpTaxiEst = false;





           };//fin back main



    /*funcion que oculta el boton del footer cuando el usuario
     empieza a capturar datos con el movil*/

      angular.element($window).bind('resize', function(e) {
            console.log("Inicial :" + usuarioNR.large);
            console.log("Cambios  :" + $window.innerHeight);
          if(parseInt(usuarioNR.large) > parseInt($window.innerHeight)){

               console.log("--- SE OCULTA BOTON ---" );
              angular.element(document.getElementById('validate')).removeClass( "mostrarBtn" ).addClass( "ocultarBtn" );

          }else{

            console.log("--- SE MUESTRA BOTON ---" );
            angular.element(document.getElementById('validate')).removeClass( "ocultarBtn" ).addClass( "mostrarBtn" );

          }

         })






  }//fin function Controller

    })();
