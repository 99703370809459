 (function(){
 	angular.module('ERApp')
 	.controller('InfoPersonalController',InfoPersonalController);

 	function InfoPersonalController($rootScope, $http, showNotif, commonModal, commonFn,$window){
 		$rootScope.curretOption = "MIS DATOS";

 		var InfoPersonalVm = this;

 		InfoPersonalVm.habilitarCampos=true;
		InfoPersonalVm.editarIcon=true;
 		InfoPersonalVm.btnGuardar=false;
 		InfoPersonalVm.large=$window.innerHeight;

 		InfoPersonalVm.frmDatosCliente={
 			clienteId:"",
 			nombre: "",
 			apaterno: "",
 			amaterno: "",
 			email: "",
 			telefono: "",
 			edad: "",
 			genero: "",
 			tarjetaSocio: ""
 		}



 		var infoCliente ;
 		getInfoCliente();
		


		
		/* metodo que llena el formulario con 
		la informacion correspondiente del cliente */
		function getInfoCliente() {


			console.log("*****  Entra getInfoCliente ***");
			console.log("*****  obtiene la informacion del cliente  ***");

			commonModal.showWait();
			$http.post($rootScope.contextoServices+'/webresources/client/getClient',{ "mail": $rootScope.userVo.userName})
			.success(function(data){

				if(data.success){

					console.log("data "+data);

					console.log(data)
					infoCliente=data;

					console.log("*****  infoCliente ***");
					console.log(infoCliente)

					console.log(infoCliente.result.operacionExitosa)
					if(infoCliente.result.operacionExitosa){
						/*lista de clientes*/
						InfoPersonalVm.datosCliente=infoCliente;

						/* se llenan los campos del formulario */
						InfoPersonalVm.frmDatosCliente={
							clienteId:infoCliente.result.clientes[0].clienteId,
							nombre: infoCliente.result.clientes[0].nombre,
							apaterno: infoCliente.result.clientes[0].apaterno,
							amaterno: infoCliente.result.clientes[0].amaterno,
							email: infoCliente.result.clientes[0].email,
							telefono: infoCliente.result.clientes[0].telefono,
							edad: infoCliente.result.clientes[0].edad.trim(),
							genero: infoCliente.result.clientes[0].genero,
							tarjetaSocio: ""
						}
					}
				}else{
					console.log("Ocurrio un error");

				}
				commonModal.hideWait();
			}).error(commonFn.errorCommon);


		}// fin metodo getInfoCliente

		/* evento para habilitar o deshabilitar el radio genero hombre*/
		InfoPersonalVm.checkGeneroHombre = function(){

			if(!InfoPersonalVm.habilitarCampos ){
				InfoPersonalVm.frmDatosCliente.genero = 'H';
			}
			
		}// fin checkGeneroHombre


		/* evento para habilitar o deshabilitar el radio genero mujer*/
		InfoPersonalVm.checkGeneroMujer = function(){
			if(!InfoPersonalVm.habilitarCampos ){
				InfoPersonalVm.frmDatosCliente.genero = 'M';
			}
		}// fin checkGeneroMujer


		InfoPersonalVm.editar = function(){
			InfoPersonalVm.btnGuardar=true;
			InfoPersonalVm.habilitarCampos=false;
			InfoPersonalVm.editarIcon=false;

			$rootScope.isDisabled = false;
		}

		InfoPersonalVm.cancelar = function(){
			getInfoCliente();
			InfoPersonalVm.btnGuardar=false;
			InfoPersonalVm.habilitarCampos=true;
			InfoPersonalVm.editarIcon=true;
		}


		InfoPersonalVm.guardar = function(){
			
			var objUpdateCliente={ 
										"operacion": "M", 
										"cliente": { 
													"nombre": InfoPersonalVm.frmDatosCliente.nombre , 
													"amaterno": InfoPersonalVm.frmDatosCliente.amaterno, 
													"genero": InfoPersonalVm.frmDatosCliente.genero, 
													"factuarcionAutomatica": "N", 
													"email": $rootScope.userVo.userName, 
													"apaterno": InfoPersonalVm.frmDatosCliente.apaterno, 
													"edad": InfoPersonalVm.frmDatosCliente.edad, 
													"clienteId": $rootScope.userVo.idCliente,
													"telefono": InfoPersonalVm.frmDatosCliente.telefono

													}
										};

				console.log("*****  Entra GUARDAR ***");
				console.log("*****   UPDATE INFORMACION CLIENTE ***");

				commonModal.showWait();


				console.log(objUpdateCliente);

				$http.post($rootScope.contextoServices+'/webresources/client/operacionesCliente',objUpdateCliente )
				.success(function(data){

					if(data.success){

						console.log("data "+data);
						console.log(data)


						console.log(data.result.operacionExitosa)
						if(data.result.operacionExitosa){

							showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());

							InfoPersonalVm.btnGuardar=false;
							InfoPersonalVm.habilitarCampos=true;
							InfoPersonalVm.editarIcon=true;

							getInfoCliente();
						}else{


						showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());
						console.log("Ocurrio un error");

						}
					}else{


						showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());
						console.log("Ocurrio un error");

					}
					commonModal.hideWait();
				}).error(commonFn.errorCommon);


			}


		/*funcion que oculta el boton del footer cuando el usuario 
		 empieza a capturar datos con el movil*/

		  angular.element($window).bind('resize', function(e) {
            console.log("Inicial :" + InfoPersonalVm.large);
            console.log("Cambios  :" + $window.innerHeight);
          if(parseInt(InfoPersonalVm.large) > parseInt($window.innerHeight)){
            
               console.log("--- SE OCULTA BOTON ---" );
              angular.element(document.getElementById('validate')).removeClass( "mostrarBtn" ).addClass( "ocultarBtn" );
              
          }else{

          	console.log("--- SE MUESTRA BOTON ---" );
            angular.element(document.getElementById('validate')).removeClass( "ocultarBtn" ).addClass( "mostrarBtn" );
            
          }

         })

	}// fin funcion DireccionClienteController

})();