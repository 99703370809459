(function(){
	angular.module('ERApp')
	.controller('EliminarCuentaController',EliminarCuentaController);

	function EliminarCuentaController($rootScope, $http, showNotif, commonModal, commonFn){
		$rootScope.curretOption = "ELIMINAR CUENTA";

		var EliminarCuentaVm = this;


		EliminarCuentaVm.frmDatosCliente={
			clienteId:"",
			nombre: "",
			apaterno: "",
			amaterno: "",
			email: "",
			telefonoCelular: "",
			edad: "",
			genero: "",
			tarjetaSocio: ""
		}


// routeChangeStart



		getDatosCliente();



	/* metodo que llena el formulario con
	la informacion correspondiente del cliente */
	function getDatosCliente() {


		console.log("*****  Entra getDatosCliente ***");
		console.log("*****  obtiene la informacion del cliente  ***");



		commonModal.showWait();
		$http.post($rootScope.contextoServices+'/webresources/client/getClient',{ "mail": $rootScope.userVo.userName})
		.success(function(data){

			if(data.success){

				console.log("data "+data);

				console.log(data);


				console.log("*****  EliminarCuentaVm ***");


				console.log(data.result.operacionExitosa)
				if(data.result.operacionExitosa){


					/* se llenan los campos del formulario */
					EliminarCuentaVm.frmDatosCliente={
						clienteId:data.result.clientes[0].clienteId,
						nombre: data.result.clientes[0].nombre.toUpperCase(),
						apaterno: data.result.clientes[0].apaterno.toUpperCase(),
						amaterno: data.result.clientes[0].amaterno.toUpperCase(),
						email: data.result.clientes[0].email,
						telefonoCelular: data.result.clientes[0].telefonoCelular,
						edad: data.result.clientes[0].edad,
						genero: data.result.clientes[0].genero,
						tarjetaSocio: ""
					}
				}
			}else{
				console.log("Ocurrio un error");

			}
			commonModal.hideWait();
		}).error(commonFn.errorCommon);


	}// fin metodo get Cliente



	EliminarCuentaVm.eliminar =function () {


		console.log("*****  Entra eliminarCuenta ***");




		commonModal.showWait();
		$http.post($rootScope.contextoServices+'/webresources/client/eliminaClient',{
			"mail": $rootScope.userVo.userName,
			"idCliente" :$rootScope.userVo.idCliente})
		.success(function(data){



			console.log("data "+data);
			console.log(data);
			console.log("*****  eliminarCuenta ***");





    		if(success && status ){


    			showNotif(true,"informacion","modal-azul","TU CUENTA SE ELIMINÒ CON EXITÒ");

				window.location.href = "#/login";




			$rootScope.userVo ={
        			userName: '',
        			userFirmado: false,
        			idCliente : ''//'3859'//'3966'

    			}
    		}

    commonModal.hideWait();
}).error(commonFn.errorCommon);


	}// fin metodo get Cliente



}// fin funcion DireccionClienteController

})();
