(function(){
    angular.module('ERApp')
        .controller('DeleteBoletoController',DeleteBoletoController);

    function DeleteBoletoController($rootScope,$window,$filter,$http,commonModal,commonFn,showNotif){
        $rootScope.curretOption = "VIAJES FUTUROS";

        var DeleteBoletoVm = this;
        DeleteBoletoVm.loadDestinos = loadDestinos;
        DeleteBoletoVm.loadOrigen = loadOrigen;
        DeleteBoletoVm.mostrar = mostrar;
        DeleteBoletoVm.ocultarModal = ocultarModal;
        DeleteBoletoVm.validarDelete = validarDelete;
        DeleteBoletoVm.showModal = showModal;
        DeleteBoletoVm.eliminar = eliminar;
        DeleteBoletoVm.back = back;
        DeleteBoletoVm.show = false;

        if ($rootScope.boleto === null){
          $window.location.href = "#/viajesFuturos";
        }

        DeleteBoletoVm.viaje ={};
        DeleteBoletoVm.showError = false;
        DeleteBoletoVm.loadOrigen();
        DeleteBoletoVm.uniqueTicket = $rootScope.boleto;
        DeleteBoletoVm.viaje.origen = DeleteBoletoVm.uniqueTicket.origen;
        DeleteBoletoVm.viaje.destino = DeleteBoletoVm.uniqueTicket.destino;

     		// $scope.vigencia = $estatusRol[0];

  //      DeleteBoletoVm.fechaHora = DeleteBoletoVm.uniqueTicket.boletos[0].fechaViaje +" "+ DeleteBoletoVm.uniqueTicket.boletos[0].horaViaje;


  function mostrar(){
            $rootScope.classIcon = "informacion";
            $rootScope.claseModal = "modal-azul";
            DeleteBoletoVm.show = true;

  }


  function ocultarModal(){
            DeleteBoletoVm.show = false;
  }

  function showModal(){
    $rootScope.classIcon = "alerta";
    $rootScope.claseModal = "modal-azul";
    $rootScope.modalMessage = true;
    $rootScope.message = DeleteBoletoVm.validEdit.cause;
  }

         function validarDelete(){
             commonModal.showWait();
           $http.post($rootScope.contextoServices+'/webresources/viajes/validaCambioHorario',
           {asiento:DeleteBoletoVm.uniqueTicket.noAsiento,
             folio: DeleteBoletoVm.uniqueTicket.folio,
             tipoValidacion: "C",
             origen: DeleteBoletoVm.uniqueTicket.origen,

           })
             .success(function(data){
               commonModal.hideWait();
                DeleteBoletoVm.validEdit = data;
                if (!DeleteBoletoVm.validEdit.result.validCancel){
                   showNotif(
                    true,
                    'alerta',
                    'modal-azul',
                    DeleteBoletoVm.validEdit.result.msj
                  )

                    DeleteBoletoVm.showError = false;
                  }else {
                    DeleteBoletoVm.message =  'El Boleto es valido para Cancelar, Al aceptar la cancelación se hara un cargo de : $' +
                    DeleteBoletoVm.validEdit.result.importeCargo+".00";
                    mostrar();
                    DeleteBoletoVm.showError = true;
                  }


         })
         .error(function(status, error){

         })

         }

        function back(){
             $window.location.href = "#/viajesFuturos";
        }

        DeleteBoletoVm.validEdit =
                {
            "status": false,
            "cause": "El Boleto no es valido para Cancelar o ya fué cancelado",
            "success": true
          };



        function eliminar(){
          commonModal.showWait();
          $http.post($rootScope.contextoServices+'/webresources/viajes/cancelarBoleto',
          {asiento:DeleteBoletoVm.uniqueTicket.noAsiento,
            folio: DeleteBoletoVm.uniqueTicket.folio,
            origen: DeleteBoletoVm.uniqueTicket.origen,
          })
            .success(function(data){
              commonModal.hideWait();
              if(data.success){
                  if (data.status){

                    showNotif(
                     true,
                     'informacion',
                     'modal-azul',
                     data.cause);
                    $window.location.href = "#/viajesFuturos"
                  }else{
                    showNotif(
                     true,
                     'informacion',
                     'modal-azul',
                     'Los sentimos, no pudimos realizar tu consulta, de favor inténtalo mas tarde');

                  }
              }

        })
        .error(function(status, error){

        })





        }







        function loadDestinos(){
            console.log('loadDestinos ' + DeleteBoletoVm.viaje.origen);
            if(DeleteBoletoVm.viaje.origen === ''){return;}
            for(var i=0; i<DeleteBoletoVm.origenDestinoList.length; i++){
                var auxOrigen = DeleteBoletoVm.origenDestinoList[i];
                if(auxOrigen !== undefined && auxOrigen !== null){
                    console.log("Compare " + DeleteBoletoVm.viaje.origen +" === "+ auxOrigen.origen);
                    if(DeleteBoletoVm.viaje.origen === auxOrigen.origen){
                        DeleteBoletoVm.listaDestinos = auxOrigen.destinos;
                        DeleteBoletoVm.viaje.destino = auxOrigen.destinos[0].destino;
                    }
                }
            }
        }


        function loadOrigen(){
                   DeleteBoletoVm.tiposViajesList = [{
                       value : 'S',
                       label : 'Viaje sencillo'
                   },
                   {
                       value : 'R',
                       label : 'Viaje redondo'
                   }];
                   DeleteBoletoVm.peticion =
                           {"result":
                               {"origenes":
                                   [null,
                                       {"descripcion":"4PTE PUEBLA","destinos":
                                           [
                                               {"descripcion":"CONDESA D.F.","destino":"CONDESA"},
                                               {"descripcion":"EL ANGEL D.F.","destino":"ELANGEL"},
                                               {"descripcion":"ANTARA D.F.","destino":"PANTARA"},
                                               {"descripcion":"SANTA FE D.F.","destino":"STAFE"},
                                               {"descripcion":" WTC D.F.","destino":"WTC"}
                                           ],
                                           "origen":"4PTE"
                                       },
                                       {"descripcion":"CONDESA D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"CONDESA"
                                       },
                                       {"descripcion":"EL ANGEL D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"ELANGEL"
                                       },
                                       {"descripcion":"ANTARA D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"},
                                               {"descripcion":"PZ. SN. DIEGO-CHOLULA","destino":"PSNDIEGO"}
                                           ],
                                           "origen":"PANTARA"
                                       },
                                       {"descripcion":"SANTA FE D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"STAFE"
                                       },
                                       {"descripcion":" WTC D.F.","destinos":
                                           [
                                               {"descripcion":"4PTE PUEBLA","destino":"4PTE"}
                                           ],
                                           "origen":"WTC"}
                                       ],
                                       "errorCode":"000","errorMsg":"La consulta se realizó satisfactoriamente",
                                       "operacionExitosa":true
                                   },
                                   "success":true
                               };
                               //LA VARIABLE DeleteBoletoVm.origenDestinoList ES LA QUE CARGA TODOS LOS ORIGENES
                                DeleteBoletoVm.origenDestinoList = DeleteBoletoVm.peticion.result.origenes;
                                DeleteBoletoVm.viaje.origen = DeleteBoletoVm.origenDestinoList[1].origen;
                                DeleteBoletoVm.loadDestinos();
               }







}
})();
