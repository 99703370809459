(function(){
    angular.module('ERApp')
        .controller('CompartirBoletoController',CompartirBoletoController);

    function CompartirBoletoController($rootScope, $http, showNotif){
        $rootScope.curretOption = "COMPARTIR BOLETO";
        var CompartirBoletoVm = this;
        CompartirBoletoVm.titulo = "COMPARTIR BOLETO";
        CompartirBoletoVm.subtitulo = "INTRODUCE EL MAIL A QUIEN DESEAS COMPARTIR TU BOLETO";

        //Asociacion funciones
        CompartirBoletoVm.agregarCorreo = agregarCorreo;
        CompartirBoletoVm.compartirBoleto = compartirBoleto;
        //CompartirBoletoVm.compartirBoleto = compartir;
        console.log("$rootScope.boleto");
        console.log($rootScope.boleto);
        //Definimos el arreglo de Inputs
        CompartirBoletoVm.correos = [{id: 'correo1'}];

        //Funcion que agrega casillas para captura
        function agregarCorreo() {
          console.log(CompartirBoletoVm.correos.length);
          if(CompartirBoletoVm.correos.length <= 4){
            var newItemNo = CompartirBoletoVm.correos.length+1;
            CompartirBoletoVm.correos.push({'id':'correo'+newItemNo});
          }else{
            console.log("Numero de elementos Excedido");
          }
        };//Fin funcion que agrega casillas para captura

        CompartirBoletoVm.datos = {
          "noAsiento": $rootScope.boleto.noAsiento,
          "horaViaje": $rootScope.boleto.horaViaje,
          "tipoServicio": $rootScope.boleto.servicioNombre,
          "toMail": "",
          "userName": $rootScope.boleto.nombrePasajero,
          "precioBoleto": $rootScope.boleto.importe,
          "origen": $rootScope.boleto.origen,
          "destinoViaje": $rootScope.boleto.destino,
          "fechaViaje": $rootScope.boleto.fechaViaje
        }

        function compartirBoleto(){
          console.log("::::::Lista de Correos:::::::");
          console.log(CompartirBoletoVm.correos.length);
          console.log(CompartirBoletoVm.correos);
          console.log("::::::$rootScope.boleto:::::::");
          console.log($rootScope.boleto);
          for(var i=0; i<CompartirBoletoVm.correos.length; i++){
            //CompartirBoletoVm.boletoFactura = CompartirBoletoVm.datos;
            CompartirBoletoVm.datos.toMail = CompartirBoletoVm.correos[i].email;
            console.log("CompartirBoletoVm.boletoFactura");
            console.log(CompartirBoletoVm.datos);
            console.log("::::::::::::::::::::::");
            $http.post($rootScope.contextoServices+'/webresources/viajes/comparteViajeMail',
              CompartirBoletoVm.datos).success(function(data){
                 console.log(data);
                 showNotif(true,"alerta","modal-azul","TU BOLETO SE HA ENVIADO CON EXITO");

                 CompartirBoletoVm.correos = [{id: 'correo1'}];
            }).error(function(data){
              console.log("Error data");
              console.log(data);
            });
          }
        }
    } //FIN DEL FUNCTIONCONTROLLER
})();
