(function(){
  angular.module('ERApp')
  .factory('catalogosService', ['$http','$rootScope','commonModal','$q', catalogosService]);

  function catalogosService($http, $rootScope, commonModal,$q){
    var service = {};

    service.loadCatalogs = loadCatalogs;

        function loadCatalogs(contextObj){

            var paisesQ = $http.get($rootScope.contextoAdmin+'/REST/ERBUS/paises',{});
            var municipiosQ = $http.get($rootScope.contextoAdmin+'/REST/ERBUS/estados',{});

            commonModal.showWait();
            $q.all([paisesQ, municipiosQ])
                .then(function(data){
                    commonModal.hideWait();
                    console.log("Then All");
                    console.log(data);
                    contextObj.paisesLista = data[0].data.result;
                    contextObj.estadoLista = data[1].data.result;
                }, function(){
                    console.log("Error...");
                })

        }
      service.getPaises = function(listaPaises) {
                  console.log("*** getPaises get *** ");
                  commonModal.showWait();
                  /*$http.get('/esb/er-admin/REST/ERBUS/paises',{})
                        .success(function(data){

                            console.log("*** success *** ");
                            commonModal.hideWait();

                            if(data.estatus){
                              console.log(listaPaises);
                              console.log("========*** data *** "+data);
                              listaPaises = data.result;
                            }else{
                              console.log("*** error getPaises *** ");

                            }
                  }).error(function(status, error){
                        console.log("*** getPaises  ERROR *** ");
                    })*/
                  listaPaises = [{},{},{}]
      }

    return service;

  }

})();
