(function(){

angular.module('ERApp')
.controller('LoginController', LoginController)



function LoginController($rootScope, $location,$http,$window,showNotif,commonModal,commonFn
    ,$cookies
){
    $rootScope.curretOption = "";

    var LoginVm = this;
     LoginVm.recordarDatos= true;
     LoginVm.focus = focus;
     LoginVm.large = $window.innerHeight;

     LoginVm.blur = blur;
     LoginVm.recuperarContrasena = recuperarContrasena;
     LoginVm.user= {
     	usuario: "",
     	pass: ""
     }
     console.log($rootScope.userVo.userFirmado);


     console.log($rootScope.userVo.userFirmado);
     LoginVm.wiewRegistrarse = true;
     $rootScope.userVo = {};
     $rootScope.userVo.userFirmado = false;
     console.log($rootScope.userVo.userFirmado);
     $cookies.remove('sessionUser');

 	   LoginVm.chanceRecodar = chanceRecodar;
 	   LoginVm.validateIngreso = validateIngreso;


  function focus(){
    console.log("en focus de usuario head")
    LoginVm.wiewRegistrarse = false;
  }

  function blur(valor){
   valor.formLogin.$setPristine();
   LoginVm.wiewRegistrarse = true;

  }







    angular.element($window).bind('resize', function(e) {
            console.log("Inicial :" + LoginVm.large);
            console.log("Cambios  :" + $window.innerHeight);
          if(parseInt(LoginVm.large) > parseInt($window.innerHeight)){
              LoginVm.wiewRegistrarse = true;
              angular.element(document.getElementById('validate')).removeClass( "mostrarBtn" ).addClass( "ocultarBtn" );
              console.log(LoginVm.wiewRegistrarse);
          }else{
            angular.element(document.getElementById('validate')).removeClass( "ocultarBtn" ).addClass( "mostrarBtn" );
            LoginVm.wiewRegistrarse = false;
            console.log(LoginVm.wiewRegistrarse);
          }

            })



  function recuperarContrasena(){
    $window.location.href = "#/recuperaContarsena";
  }

 	// Validación del acceso a la aplicación;
 	function validateIngreso (){

    if (LoginVm.user.usuario.length > 0 && LoginVm.user.pass.length > 0){
      commonModal.showWait();
      $http.post($rootScope.contextoServices+'/webresources/client/login',{user:LoginVm.user.usuario,password: LoginVm.user.pass})
        .success(function(data){
          commonModal.hideWait();
          console.log(data);
          if (data.status === 'C'){
            commonModal.hideWait();
            $rootScope.userVo = data.result.clientes[0];
            $rootScope.userVo.userName = data.result.clientes[0].email;
            $rootScope.userVo.idCliente = data.result.clientes[0].clienteId;
            $rootScope.userVo.userFirmado = true;

            $cookies.putObject('sessionUser',data.result.clientes[0],{});
            console.log($rootScope.userVo);
            $window.location.href = "#/";
          }else{
            showNotif(
              true,
              'alerta',
              'modal-azul',
              'Credenciales Inválidas'
            )


          }
    })
    .error(commonFn.errorCommon);
  }else {
    showNotif(
      true,
      'alerta',
      'modal-azul',
      'Todos los cammpos son requeridos'
    )

  }




 	}



    // Cambio de status del radio de recordar datos;
    function chanceRecodar(){
    	if (LoginVm.recordarDatos ===  true){
    		console.log("TRUE " + LoginVm.recordarDatos);
    		LoginVm.recordarDatos = false;

    	}else{
    		console.log("FALSE " + LoginVm.recordarDatos);
    		LoginVm.recordarDatos = true;

    		}

    }
}
})();
