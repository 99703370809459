(function(){
    angular.module('ERApp')
        .factory('MenuService', ['$rootScope', "$window", "$route",MenuService]);

        function MenuService($rootScope,$window, $route){
            var service = {};
            service.paginacion =[
              {url:"#/"},
              {url:"#/login"},
              {url:"#/crea-cuenta"},
              {url:"#/facturacion/facturacionNoRegistrado"},
              {url:"#/informacion"},
              {url:"#/home-viajes"},
              {url:"#/datos-cliente/datos-cliente"},
              {url:"#/home-facturas"},
              {url:"#/facturacion"},
              {url:"#/login"}
            ];
            service.pagina = 0;
            service.changePage = changePage;

            function changePage() {
              // body...
              // console.log("Numero de pagina: "+service.pagina);
              $rootScope.page = 0;
              $rootScope.cambioHorario = false;
              if ($rootScope.relojMenu) {
                $rootScope.relojMenuOpc = true;
              }
              $window.location.href = service.paginacion[service.pagina].url;
              if (service.pagina===0) {
                $route.reload();
              }
            }
            return service;
        }

})();
