(function(){
    angular.module('ERApp')
        .controller('ViajesAnterioresController',ViajesAnterioresController);

    function ViajesAnterioresController($rootScope,$window,$http,commonModal,commonFn){
        $rootScope.curretOption = "VIAJES ANTERIORES";

        var ViajesAnterioresVm = this;
        ViajesAnterioresVm.mostrarDetalle = mostrarDetalle;
        ViajesAnterioresVm.compartirBoleto = compartirBoleto;
        ViajesAnterioresVm.duplicarBoleto = duplicarBoleto;
        ViajesAnterioresVm.getViajesAnteriores = getViajesAnteriores;
        ViajesAnterioresVm.enviarQueja = enviarQueja;
        ViajesAnterioresVm.verBoleto = verBoleto;


        if ($rootScope.boleto === null){
          $window.location.href = "#/viajesAnteriores";
        }

        ViajesAnterioresVm.showFuturos=false

        getViajesAnteriores();

        ViajesAnterioresVm.activoPadre = [];
        ViajesAnterioresVm.activoHijo = [];

        function  mostrarDetalle(index,indexChildren,ventas,boletos){

           for(var i =0;i < ventas;i++){
              ViajesAnterioresVm.activoPadre[i] = false;
            }
           for(var r = 0; r < boletos;r++){
            ViajesAnterioresVm.activoHijo[r] = false;
           }

          ViajesAnterioresVm.activoPadre[index] = true;
          ViajesAnterioresVm.activoHijo[indexChildren] = true;


        }


        function compartirBoleto(id_venta,id_boleto){
            $rootScope.indexBoleto = id_boleto;
            $rootScope.boleto = ViajesAnterioresVm.result.result[id_venta].boletos[id_boleto];
            $window.location.href = "#/compartirViajeAnt";
        }


        function verBoleto(id_venta,id_boleto){
            $rootScope.indexBoleto = id_boleto;
            $rootScope.boleto = ViajesAnterioresVm.result.result[id_venta].boletos[id_boleto];
            $window.location.href = "#/verBoleto";

        }


        function duplicarBoleto(id_venta,id_boleto){
                    $rootScope.indexBoleto = id_boleto;
                    $rootScope.switchSharedTrip = 1;
                    $rootScope.boleto = ViajesAnterioresVm.result.result[id_venta].boletos[id_boleto];
                    $rootScope.page = 499;
                    $window.location.href = "#/";
                }

            function enviarQueja(id_venta,id_boleto){
                            $rootScope.indexBoleto = id_boleto;
                            $rootScope.boleto = ViajesAnterioresVm.result.result[id_venta].boletos[id_boleto];
                            $window.location.href = "#/enviarQueja";
              }


                function getViajesAnteriores(){
                commonModal.showWait();
                console.log($rootScope.userVo.idCliente);
                $http.post($rootScope.contextoServices+'/webresources/viajes/getMoreViajes',
                {
                  inicio:1,
                  fin:100,
                  idCliente:$rootScope.userVo.idCliente,
                  tipo: 'A'}
                 )
                  .success(function(data){
                   commonModal.hideWait();
                   console.log(data);
                    ViajesAnterioresVm.result = data;
                    ViajesAnterioresVm.showFuturos = true;

              })
              .error(commonFn.errorCommon);
            }

}
})();
