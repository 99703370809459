  (function(){
      angular.module('ERApp')
          .controller('ListaTarjetasController',ListaTarjetasController);

      function ListaTarjetasController($rootScope,$http, showNotif, commonModal,commonFn){
          $rootScope.curretOption = "LISTA DE TARJETAS";
          commonModal.showWait();

          var lsTarjetas = this;

          lsTarjetas.deleteItem = deleteItem;
          lsTarjetas.confirmDelete = confirmDelete;
          lsTarjetas.cerrarModal = cerrarModal;

          lsTarjetas.botonCofirm=false;
          lsTarjetas.editar = editar;
          lsTarjetas.editarIcon = true;
          lsTarjetas.bndLista = true;
          lsTarjetas.bndAddBtn = false;
          lsTarjetas.idCliente =  $rootScope.userVo.clienteId;
          lsTarjetas.email= $rootScope.userVo.userName;
          lsTarjetas.listaFinal=[];
/*Object {mail: "mau@ebus.com", userId: "3966", descToken: "VISA  ************4731"}*/
          lsTarjetas.request =
            {

            "mail":"",
            "userId" : "",
            "descToken" : ""

          };

          getCardsClient();


      function getCardsClient() {

        console.log("***** getCardsClient ****");
        console.log("USUARIO>>>"+ lsTarjetas.email);
        console.log("ID CLIENTE>>>"+ lsTarjetas.idCliente);
        console.log("ID CLIENTE>>>"+ $rootScope.userVo.noCuenta);
        var dataObj = {
          "idCliente":  lsTarjetas.idCliente
        };
        console.log("Inicio");
        $http.post($rootScope.contextoServices+'/webresources/client/getCardsClient',dataObj)
          .success(function(data, status, headers, config) {
          console.log(data);
          lsTarjetas.listaFinal = data.result;
          console.log("Ejecuta....");
          commonModal.hideWait();
  			});
        console.log("fin");


  };


    function deleteItem(idLs) {
      console.log("DELETE ITEM>>>"+idLs);
      commonModal.showWait();

            lsTarjetas.request.mail=lsTarjetas.email;
            lsTarjetas.request.userId=lsTarjetas.idCliente;
            lsTarjetas.request.descToken=lsTarjetas.listaFinal[idLs].value;

              console.log(lsTarjetas.request);
            $http.post($rootScope.contextoServices+'/webresources/tokenService/deleteTokenCard',lsTarjetas.request)
              .success(function(data, status, headers, config){
              console.log(data);
              console.log("Ejecuta....");
                if(data.success){
                  showNotif(true,"alerta","modal-azul","TARJETA SE ELIMIN\u00d3 CORRECTAMENTE");
                  commonModal.hideWait();
                  lsTarjetas.botonCofirm=false;
                  getCardsClient();
                }
                lsTarjetas.botonCofirm=false;
                lsTarjetas.bndLista = true;
                console.log("fin");
                commonModal.hideWait();

            }).error(commonFn.errorCommon)


  };
    function editar()
    {
      console.log(lsTarjetas.bndLista);
      lsTarjetas.editarIcon= false;
      lsTarjetas.bndLista = false;
      lsTarjetas.bndAddBtn=false;
      console.log(lsTarjetas.bndLista);
    };

    function confirmDelete(idLs){
      console.log("CONFIRM DELETE>>>>>"+idLs);
      lsTarjetas.elementoEliminar=idLs;
      lsTarjetas.botonCofirm=true;
      lsTarjetas.bndLista = false;
    };
    function cerrarModal(){
      lsTarjetas.botonCofirm=false;
      lsTarjetas.bndLista = true;
    };

  }//FIN CONTROLLER
  })();
