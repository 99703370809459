  (function(){
      angular.module('ERApp')
      .controller('MainController', MainController)

      function MainController(
          $rootScope, $http, showNotif,
          commonModal, commonFn, asientosService,
          resumenService,
          seleccionOrigen,
          corridasService,
          paginacion,
          $window,
          pagoViaje,
          datosPasajeroService,
          MenuService,
          $q,
          $location,
          $window,
          $timeout,
          $route
        ){
          $rootScope.curretOption = "";
          $rootScope.headCompra = "";

          $rootScope.classCompra = "seccionCompra";
          var CompraVm = this;
              CompraVm.ocultar = false;
              CompraVm.ocultarRegreso = false;
          CompraVm.datosP = {
              tipoPago : "",
              terminos : ""
          };
          var message = {
              TIME_OVER : 'TIEMPO DE COMPRA EXCEDIDO.',
              MAX_LUGARES_INAPAM : "EL MÁXIMO DE LUGARES INAPAM DISPONIBLES EN EL BUS ES DE 4",
              MAX_PASAJERO: "EL NÚMERO MÁXIMO DE PASAJEROS PERMITIDO POR COMPRA ES DE 8.",
              PAYPALMESSAGE: "No se aplican cancelaciones y/o devoluciones mediante esta forma de pago."
          }

          commonModal.showWait();
          if($rootScope.page === 499){
            console.log("Entrando de pantalla duplicar boleto..");
            console.log($rootScope.boleto);
          }
          if ($rootScope.boleto) {
            console.log("Entrando de pantalla cambio de horario");
            console.log($rootScope.boleto);
          }

          CompraVm.boletos = {
              adultos : 1,
              ninos : 0,
              inapam : 0
          };

          CompraVm.boletosIda = new Object();

          CompraVm.boletosVuelta = new Object();

          //Bandera para desabilitar los botones cuando a sobrepasado el numero
          //total de boletos comprados por el pendejo cliente
          //Variable global de número de boletos que como tal , determina el total de asientos seleccionados.
          CompraVm.totalBoletos=0;
          CompraVm.totalBoletos+=parseInt(CompraVm.boletos.adultos);
          CompraVm.totalBoletos+=parseInt(CompraVm.boletos.ninos);
          CompraVm.totalBoletos+=parseInt(CompraVm.boletos.inapam);
          CompraVm.totalBanderaBoletosDelimitante=0;
          CompraVm.totalSeleccionados=0;
          CompraVm.resize=resize;
          CompraVm.limit = 2;

          CompraVm.numCap = 0;
          CompraVm.captchaSelect1 = 0;
          CompraVm.captchaSelect2 = 0;
          CompraVm.captchaText = "";
          CompraVm.captchasValor = [];
          // CompraVm.numTarjetaSave = [];
          CompraVm.datosPasajeros = [];
          CompraVm.REGRESO=false;
          CompraVm.IDA=true;

          CompraVm.userActivityInterval = 1000;
          CompraVm.resetActivity = resetActivity;
          var countAs = 0;
          CompraVm.arrayAsientosRegreso=[];
          //Tiempo retroceso
          CompraVm.timeLimit = 8; //tiempo en minutos
          CompraVm.conteo = new Date(CompraVm.timeLimit * 60000);
          CompraVm.tiempoExcedido = false;

          CompraVm.objCompra = {
              cardObj : {nombre : "", number : "", secCode : "", mailCliente : "", year : "", month : ""},
              regresoObj : {},
              idaObj : {pasajeros : []},
              mailCliente : $rootScope.userVo.userName,
              idCliente : $rootScope.userVo.idCliente
          }


          CompraVm.captchasValor.push({id:"maleta", checar:false, valor:0});
          CompraVm.captchasValor.push({id:"volante", checar:false, valor:1});
          CompraVm.captchasValor.push({id:"mapa", checar:false, valor:2});
          CompraVm.captchasValor.push({id:"boleto", checar:false, valor:3});
          CompraVm.captchasValor.push({id:"bus", checar:false, valor:4});

          // CompraVm.numTarjetaSave.push({id:"card", checar:false, descripcion:"Guardar Tarjeta", valor:0});

          CompraVm.numTarjetaSave = {
            checar:false,
            id:"card",
            descripcion:"Guardar Tarjeta",
            valor:0
          };

          CompraVm.listaAsientosSeleccionados=[];

        	CompraVm.asientoSeleccionados=[];
        	CompraVm.asientos = {};
          // CompraVm.idAgencias = [3,4,6];
          CompraVm.idAgencias = [0,1];
        	CompraVm.activeItem = null;
        	CompraVm.classe = "";

          CompraVm.viaje = {
              origen : '',
              destino : '',
              tipo : 'S'
          };

            CompraVm.dynamicPopover = {
               content: 'Hello, World!',
              templateUrl: 'myPopoverTemplate.html',
              title: 'Title'
              };

          //PAGINAS EN EL FLUJO
          CompraVm.paginacion =[
            {url:"compra/compra-inicio.html",bntDescripcion:"BUSCAR"},
            {url:"compra/sel-asiento.html",bntDescripcion:"SIGUIENTE"},
            {url:"compra/sel-asiento.html",bntDescripcion:"SIGUIENTE"},
            {url:"compra/sel-asiento-bus.html",bntDescripcion:"SIGUIENTE"},
            {url:"compra/sel-asiento-bus.html",bntDescripcion:"SIGUIENTE"},
            {url:"compra/resumen-compra.html",bntDescripcion:"SIGUIENTE"},
            {url:"compra/resumen-compra.html",bntDescripcion:"SIGUIENTE"},
            {url:"compra/datos-pasajero.html",bntDescripcion:"CONFIRMAR"},
            {url:"compra/datos-bancarios.html",bntDescripcion:"PAGAR"},
            {url:"compra/finalizar-pdf.html",bntDescripcion:""}
          ];

          CompraVm.buscaPageOne = true;

          CompraVm.selCorrida = false;
          CompraVm.corridaExt = 100;
          CompraVm.pageURL = "compra/sel-asiento.html";
          CompraVm.page = $rootScope.page;
          CompraVm.botonDescripcion = "BUSCAR";
          CompraVm.mensajeValidacion = "";
          CompraVm.headAdulto = "";
          CompraVm.headNino = "";
          CompraVm.dateDeparture = new Date();
          CompraVm.dateReturn = new Date();
          CompraVm.CorridaIDA  = new Array();
          CompraVm.CorridaSelectIDA  = new Object();
          CompraVm.CorridaSelectResumen= {
            Corrida: "",
            boletos: "",
            fecha: "",
            totalPago : 0
          };

          CompraVm.CorridaVUELTA  = new Array();
          CompraVm.CorridaSelectVUELTA  = new Object();

          //Se Asocian funciones a la vista
          CompraVm.loadOrigen = loadOrigen;
          CompraVm.changeNumBoletos = changeNumBoletos;
          CompraVm.loadDestinos = loadDestinos;
          CompraVm.checkChanged = checkChanged;

          CompraVm.loadChangeCard = loadChangeCard;

          CompraVm.selectDefaulEdad = selectDefaulEdad;
          CompraVm.cambiaBloqueo = cambiaBloqueo;
          CompraVm.isAgencia = isAgencia;
          // CompraVm.buscaAsientos = buscaAsientos;
          CompraVm.backPage = backPage;
          CompraVm.setSelected = setSelected;
          CompraVm.accionBoton = accionBoton;
          CompraVm.getEdad = getEdad;

          CompraVm.pagoPaypalMesagge = pagoPaypalMesagge;
          // CompraVm.nextCorrida = nextCorrida;
          CompraVm.formatPrecio = formatPrecio;
          CompraVm.backCorrida = backCorrida;
          CompraVm.convertMayusculas = convertMayusculas;
          CompraVm.desbloqueaAsientos = desbloqueaAsientos;

          CompraVm.showModalTerminoTiempo = showModalTerminoTiempo;
          //Seteo de variables para inicio de Compra

          CompraVm.maxCompra = 1;
          CompraVm.corridaSelected = "";
          CompraVm.corridaIdaSelected = "";
          CompraVm.corridaVueltaSelected = "";
          CompraVm.titulo = "IDA";
          CompraVm.botonReturn = "btn-registro";
          CompraVm.validacionFlag = false;


          CompraVm.tiposViaje = [{
              text : "Viaje sencillo",
              value : "S"
          },

          {
              text : "Viaje redondo",
              value : "R"
          }];

          CompraVm.padDinamic = ((($window.innerWidth) * 0.83333) - (CompraVm.tiposViaje[0].text.length * 10))  / 2;
          CompraVm.styleTipoCorrida= CompraVm.padDinamic+'px';

          CompraVm.fechaElegida = CompraVm.dateDeparture;
          //FUNCTION INICIAL

          if ($rootScope.cambioHorario) {
            CompraVm.page = 1;
            // console.log("=====================");
            // console.log("valores cambio de boleto");
            // console.log($rootScope.boleto.newDestino);
            // console.log($rootScope.boleto.newOrigen);
            // console.log($rootScope.boleto.newFecha);
            // console.log("=====================");
            CompraVm.viaje.cveDestino = $rootScope.boleto.newDestino;
            CompraVm.viaje.cveOrigen = $rootScope.boleto.newOrigen;
            CompraVm.dateDeparture = $rootScope.boleto.newFecha;
            // CompraVm.viaje.fecha = "2016-01-07";
            CompraVm.viaje.tipo = "S";
            if ($rootScope.boleto.tipoPasajero === "A") {
              CompraVm.boletos.adultos = 1;
              CompraVm.boletos.ninos = 0;
              CompraVm.boletos.inapam = 0;
            }else if ($rootScope.boleto.tipoPasajero === "S"){
              CompraVm.boletos.adultos = 0;
              CompraVm.boletos.ninos = 0;
              CompraVm.boletos.inapam = 1;
            }else{
              CompraVm.boletos.adultos = 0;
              CompraVm.boletos.ninos = 1;
              CompraVm.boletos.inapam = 0;
            }
            CompraVm.boletosIda = angular.copy(CompraVm.boletos);
            corridasService.accionCorrida(CompraVm);
          }else{
            CompraVm.loadOrigen();
          }
          CompraVm.isAgencia();


          function resize(destino){
            //  console.log("resize");
            //  console.log(destino);
            var tamanoCadena = 0;
            for(var i=0; i< CompraVm.listaDestinos.length; i++){
              if(CompraVm.listaDestinos[i] !== undefined && CompraVm.listaDestinos[i] !== null){
                if(CompraVm.listaDestinos[i].destino === destino.cveDestino){
                  // console.log(CompraVm.listaDestinos[i]);
                  tamanoCadena = CompraVm.listaDestinos[i].descripcion.length;
                  i = CompraVm.listaDestinos.length;
                }
              }
            }
          CompraVm.padDinamicDestino = ((($window.innerWidth) * 0.83333) - (tamanoCadena * 10))  / 2;
          CompraVm.styleDestino= CompraVm.padDinamicDestino+'px';
          }


          function changeNumBoletos(tipoBoleto,accion){

            if (angular.equals(1,accion)){
              if (CompraVm.maxCompra  === 8) {
                showNotif(true,"alerta","modal-azul", message.MAX_PASAJERO);
                CompraVm.mensajeValidacion = "";
              }
            }
              if (angular.equals(1,tipoBoleto)){
                  if (angular.equals(0,accion)  && CompraVm.boletos.adultos > 0){
                     CompraVm.boletos.adultos --;
                  }
                  if (angular.equals(1,accion)){
                      if (CompraVm.maxCompra  < 8) {
                          CompraVm.boletos.adultos ++;
                      }
                  }
              }

               if (angular.equals(2,tipoBoleto)){
                  if (angular.equals(0,accion) && CompraVm.boletos.ninos > 0){
                     CompraVm.boletos.ninos --;
                  }
                  if (angular.equals(1,accion)){
                      if (CompraVm.maxCompra  < 8) {
                          CompraVm.boletos.ninos ++;
                          }
                  }
              }

              if (angular.equals(3,tipoBoleto)){
                  if (angular.equals(0,accion) && CompraVm.boletos.inapam > 0){
                     CompraVm.boletos.inapam --;
                  }
                  if (angular.equals(1,accion)){
                      if (CompraVm.maxCompra  < 8) {
                          if (CompraVm.boletos.inapam === 4) {
                            showNotif(true,"alerta","modal-azul", message.MAX_LUGARES_INAPAM);
                          }else{
                            CompraVm.boletos.inapam ++;
                          }
                      }
                  }
              }
              CompraVm.maxCompra  =  CompraVm.boletos.adultos + CompraVm.boletos.ninos + CompraVm.boletos.inapam;
          }

          function loadDestinos(){
              CompraVm.listaDestinos = [];
              if(CompraVm.viaje.cveOrigen === ''){return;}
              for(var i=0; i<CompraVm.origenDestinoList.length; i++){
                  var auxOrigen = CompraVm.origenDestinoList[i];
                  if(auxOrigen !== undefined && auxOrigen !== null){
                      if(CompraVm.viaje.cveOrigen === auxOrigen.origen){
                          CompraVm.listaDestinos = auxOrigen.destinos;
                          if($rootScope.page === 499){
                            CompraVm.viaje.cveDestino = $rootScope.boleto.destino;
                            CompraVm.padDinamicOrigin = ((($window.innerWidth) * 0.83333) - (auxOrigen.descripcion.length * 10))  / 2;
                            CompraVm.styleOrigenes= CompraVm.padDinamicOrigin+'px';
                            resize(CompraVm.viaje);
                            CompraVm.page = 0;
                            $rootScope.page = 0;
                          }else{
                            CompraVm.padDinamicOrigin = ((($window.innerWidth) * 0.83333) - (auxOrigen.descripcion.length * 10))  / 2;
                            CompraVm.styleOrigenes= CompraVm.padDinamicOrigin+'px';
                            CompraVm.viaje.cveDestino = auxOrigen.destinos[0].destino;
                            CompraVm.viaje.destino = auxOrigen.destinos[0].descripcion;
                            // CompraVm.viaje.origen = CompraVm.auxOrigen.descripcion;
                            CompraVm.padDinamicDestino = ((($window.innerWidth) * 0.83333) - (CompraVm.listaDestinos[0].descripcion.length * 10))  / 2;
                            CompraVm.styleDestino= CompraVm.padDinamicDestino+'px';
                          }
                      }
                  }
              }


          }
          //Funcion para la carga de origenes
          function loadOrigen(){
              seleccionOrigen.iniciaSeleccionOrigenDestino(CompraVm);
          }


          function accionBoton() {
            CompraVm.flagBuy = false;
            CompraVm.flagValidaPersonas = false;
              switch (CompraVm.page) {
                  case (paginacion.ORIGEN):
                  //Estado validacion
                  seleccionOrigen.origenValidacion(CompraVm);
                  if (CompraVm.flagBuy) {
                    //estado Siguiente
                    CompraVm.fechaElegida = CompraVm.dateDeparture;
                    paginacion.paginarNext(CompraVm);
                    //Estado inicial
//                    console.log("....Antes....");
//                    console.log(CompraVm.CorridaSelectIDA);
                    corridasService.accionCorrida(CompraVm);
                    CompraVm.conteo = new Date(CompraVm.timeLimit * 60000);
                    $rootScope.relojMenu = true;
                    vistaReloj();
                    CompraVm.isAgencia();
                    CompraVm.activityTimer = $timeout(CompraVm.resetActivity, CompraVm.userActivityInterval);
                  }
                  break;
                  case (paginacion.CORRIDAS_IDA):
                  corridasService.corridaValidacion(CompraVm);
                  if (CompraVm.flagBuy) {

                    paginacion.paginarNext(CompraVm);
                    corridasService.fillObjCompra(CompraVm.objCompra.idaObj, CompraVm.CorridaSelectIDA);
                    corridasService.accionCorrida(CompraVm);//Carga corrida de regreso
                    if(!(CompraVm.page == paginacion.CORRIDAS_REGRESO)){
                        asientosService.accionAsientos(CompraVm, "CorridaSelectIDA");
                    }                    // CompraVm.corridaIdaSelected = CompraVm.corridaSelected;
                    CompraVm.corridaSelected = "";
                    // else {
                    //   corridasService.fillObjCompra(CompraVm.objCompra.idaObj, CompraVm.CorridaSelectIDA);
                    // }
                  }
                  break;
                  case (paginacion.CORRIDAS_REGRESO):
                    //regreso
                    corridasService.corridaValidacion(CompraVm);
                    if (CompraVm.flagBuy) {
                        asientosService.accionAsientos(CompraVm, "CorridaSelectIDA");
                        CompraVm.fechaElegida = CompraVm.dateDeparture;
                        paginacion.paginarNext(CompraVm);
                        corridasService.fillObjCompra(CompraVm.objCompra.regresoObj, CompraVm.CorridaSelectVUELTA);
                    }
                    // CompraVm.corridaVueltaSelected = CompraVm.corridaSelected;
                    CompraVm.corridaSelected = "";
                    // console.log("objeto Compra Objeto");
                    // console.log(CompraVm.objCompra);
                  break;
                  case (paginacion.SELECCIONAR_ASIENTO_IDA) :
                	  // console.log("<------OBJETO COMPRA VM---->");
                  	//   console.log(CompraVm);
                  asientosService.asientoValidacion(CompraVm);
                  if (CompraVm.flagBuy) {
                    paginacion.paginarNext(CompraVm);
//                    alert("entro a la selecciòn");
                    commonModal.showWait();
                    asientosService.lockAsientosIDA(CompraVm,paginacion,resumenService,CompraVm.CorridaSelectIDA.claveCorrida, "O",  CompraVm.numeroDeAsiento.asientosIDA, CompraVm.numeroDeAsientoPer, CompraVm.CorridaSelectIDA.origenCorrida);
                    CompraVm.fechaElegida = CompraVm.dateReturn;
                    CompraVm.totalSeleccionados=0;
                  }
                  break;
                  case (paginacion.SELECCIONAR_ASIENTO_REGRESO) :
                    //SELECCIÓN DE ASIENTOS regreso
                    asientosService.asientoValidacion(CompraVm);
                    if (CompraVm.flagBuy) {
                      commonModal.showWait();
                      asientosService.lockAsientosREGRESO(CompraVm,paginacion,resumenService,CompraVm.CorridaSelectVUELTA.claveCorrida, "O",  CompraVm.numeroDeAsiento.AsientosVuelta, CompraVm.numeroDeAsientoPerRegreso, CompraVm.CorridaSelectVUELTA.origenCorrida);
                      paginacion.paginarNext(CompraVm);
                    }
                  break;
                  case (paginacion.RESUMEN_IDA) :
                    paginacion.paginarNext(CompraVm);
                    if (paginacion.RESUMEN_REGRESO === CompraVm.page) {
                      CompraVm.CorridaSelectResumen.Corrida = CompraVm.CorridaSelectVUELTA;
                      CompraVm.CorridaSelectResumen.boletos =  CompraVm.boletosVuelta;
                      CompraVm.CorridaSelectResumen.fecha = CompraVm.dateReturn;
                      CompraVm.CorridaSelectResumen.selectAsiento = CompraVm.numeroDeAsiento.AsientosVuelta;
                      resumenService.accionResumen(CompraVm, "R");
                    }else{
                        if(false){

                        }else{
                            datosPasajeroService.inicioDatosPasajero(CompraVm);
                        }

                    }
                  break;
                  case (paginacion.RESUMEN_REGRESO) :
                    //Regreso
                    paginacion.paginarNext(CompraVm);
                    datosPasajeroService.inicioDatosPasajero(CompraVm);
                  break;
                  case (paginacion.DATOS_USUARIO) :
//                        console.log("Validando datos personales de los pasajeros");
                        if(datosPasajeroService.validaPasajeros(CompraVm)){

                          if ($rootScope.isUserAgencia) {
                            pagoViaje.emitePago(CompraVm);
                          }else if ($rootScope.cambioHorario && CompraVm.CorridaSelectResumen.totalPago <= 0) {
                            pagoViaje.emitePago(CompraVm);
                          }else{
                            paginacion.paginarNext(CompraVm);
                            pagoViaje.inicioPagoPage(CompraVm);
                          }
                        }
                  break;

                  case (paginacion.DATOS_TARJETA) :
//                    console.log(CompraVm.objCompra);
                     if(pagoViaje.emitePago(CompraVm)){
                         paginacion.paginarNext(CompraVm);
                     }

                  break;

                  case (paginacion.FELIZ_VIAJE) :
                  pagoViaje.emitePago(CompraVm);
                      ///codigo envio boleto
                      paginacion.paginarNext(CompraVm);
                  break;

          }
          $rootScope.page = CompraVm.page;


          //Estado Siguiente
}


$rootScope.$on('$routeChangeStart',function(angularEvent,next,current) {
//do you work here
  if (CompraVm.ocultar || CompraVm.ocultarRegreso){
    CompraVm.ocultar = false;
    CompraVm.ocultarRegreso = false;
    angularEvent.defaultPrevented = true;
  }

});

		 function backCorrida(CompraVm){
		   corridasService.formarBackCorrida(CompraVm);
		 }

     function backPage(){
       if(CompraVm.page === 3){
         if(CompraVm.viaje.tipo == 'R'){
           CompraVm.page = 2;
           CompraVm.titulo = "REGRESO";
          //  console.log("Entro Corrida Vuelta");
           CompraVm.CorridasBack = CompraVm.CorridaVUELTA;
           CompraVm.backCorrida(CompraVm);
           CompraVm.fechaElegida = CompraVm.dateReturn;
           CompraVm.botonReturn = "btn-regreso";
         }else{
           CompraVm.fechaElegida = CompraVm.dateDeparture;
           CompraVm.titulo = "IDA";
           CompraVm.botonReturn = "btn-registro";
           CompraVm.corridaExt = 1;
          //  console.log("Entro Corrida IDA");
           CompraVm.CorridasBack = CompraVm.CorridaIDA;
           CompraVm.backCorrida(CompraVm);
           // Compra.Corridas = Compra.CorridaIDA;
           CompraVm.page = 2;
           paginacion.paginarBack(CompraVm);
         }
       }else if (CompraVm.page === 4) {
         //Implementación para desbloquear los asientos seleccionados durante el flujo de selección de asientos de regreso
         //                alert("Entro al Regreso: "+ CompraVm.numeroDeAsiento.asientosIDA);
         //                showNotif(true,"alerta","modal-azul", "SE BORRARÁN LOS ASIENTOS SELECCIONADOS.");
         asientosService.lockAsientosClean(CompraVm,paginacion,resumenService,CompraVm.CorridaSelectIDA.claveCorrida, "L",  CompraVm.numeroDeAsiento.asientosIDA, CompraVm.numeroDeAsientoPer, CompraVm.CorridaSelectIDA.origenCorrida);
         CompraVm.fechaElegida = CompraVm.dateDeparture;
       }else{
         paginacion.paginarBack(CompraVm);
       }
       //            paginacion.paginarBack(CompraVm);
     }

          function setSelected(valor){
//              console.log("Selected");
//              console.log(valor);
              if (corridasService.validaCorrida(CompraVm, valor)) {
                CompraVm.corridaSelected = valor;
              }
          }
          //
          //
          //
          // function clearValores() {
          //   if (CompraVm.page == 0) {
          //     CompraVm.corridaSelected = "";
          //     CompraVm.corridaIdaSelected = "";
          //     CompraVm.corridaVueltaSelected = "";
          //     $rootScope.headCompra = "";
          //     CompraVm.headAdulto = "";
          //     CompraVm.headNino = "";
          //     CompraVm.corridaExt = 100;
          //     CompraVm.botonDescripcion = "BUSCAR";
          //   }else if (CompraVm.page == 1){
          //     CompraVm.corridaSelected = CompraVm.corridaIdaSelected;
          //     CompraVm.titulo = "IDA";
          //     CompraVm.botonReturn = "btn-registro"
          //     CompraVm.corridaVueltaSelected = "";
          //     CompraVm.corridaExt = 1;
          //   }else if(CompraVm.page == 2){
          //     ///#/compra/seleccionarAsientos
          //     CompraVm.Corridas = CompraVm.CorridaIDA;
          //     CompraVm.titulo = "REGRESO";
          //     CompraVm.botonReturn = "btn-regreso";
          //     CompraVm.corridaSelected  = CompraVm.corridaVueltaSelected;
          //     CompraVm.corridaVueltaSelected = "";
          //   }else if(CompraVm.page == 3){
          //     // $window.location.href ="#/compra/seleccionarAsientos";
          //   }
          // }
          function formatPrecio(){
              return "12.";
          }

          /**********************************************************
          *Implemenación selección de asientos ER
          *
          ***********************************************************/
	          CompraVm.numeroDeAsiento= {
	                  asientosIDA : "",
	                  AsientosVuelta : ""
	          }

              CompraVm.numeroDeAsientoPer="";
          	  CompraVm.seleccionarAsientoIDA = function (x1){

                console.log(x1);
                if (x1.l==="0") {
                  return;
                }
          		  	if(x1.title!=undefined){
          		  		//Reducción de totalvariable
          		  		var splitAsientos=CompraVm.numeroDeAsiento.asientosIDA.split(",");
          		  		for(var y=0;y<splitAsientos.length;y++){
          		  			var e0=parseInt(splitAsientos[y]);
          		  			var e1=parseInt(x1.title);
          		  			 if(e0==e1){
              		  			 CompraVm.totalSeleccionados-=1;
          		  			 }
          		  		}

	              		var total = 0;

	                    for(var i=0;i <=  CompraVm.arrayAsientos.length;i++)
	                    {
	              			if (CompraVm.arrayAsientos[i])
	              			{
	              				total ++;
	              			}
	              		}

	                    //Valdacion cantidad boletos
	              		if (total < CompraVm.maxCompra)
	              		{
	              			//Activar el asinto seleccionado
	              			CompraVm.arrayAsientos[x1.title] =! CompraVm.arrayAsientos[x1.title];
	              		}else{
	              			//No marcamos el asiento cuando el total de asientos seleccionado es igual al de boletos comprados.
	              			CompraVm.arrayAsientos[x1.title] = false;
	              		}
	                  // console.log("Asientos array");
	                  // console.log(CompraVm.arrayAsientos);
	                  CompraVm.numeroDeAsiento.asientosIDA="";
	                  CompraVm.numeroDeAsientoPer="";
	                  for(var propt in CompraVm.arrayAsientos)
	                  {
	                	  if(CompraVm.arrayAsientos[propt]){

	                    	  if(CompraVm.maxCompra==1){
		                          if(countAs>0)
		                          {
		    	                	  if(propt!=undefined){
		    	                		 CompraVm.numeroDeAsiento.asientosIDA += propt;
		    	                	  }
		                          }else{
		                        	  if(propt!=undefined){
		                        		  CompraVm.numeroDeAsiento.asientosIDA += propt;
		                        	  }
		                          }
		                          countAs++;
	                    	  }else{
		                          if(countAs>0)
		                          {
		                              CompraVm.numeroDeAsiento.asientosIDA += propt;
		                              CompraVm.numeroDeAsiento.asientosIDA += ",";
		                          }else{
		                              CompraVm.numeroDeAsiento.asientosIDA += propt;
		                          }
		                          countAs++;
	                    	  }
	                      }
	                  }

	                  if(CompraVm.totalSeleccionados<CompraVm.maxCompra)
	                  {
	                          if(CompraVm.arrayAsientos[x1.title]){
	                              CompraVm.totalSeleccionados+=1;
	                              console.log("total seleccionadosT:"+CompraVm.totalSeleccionados
	                                            +"Max compra:"+ CompraVm.maxCompra+
	                                            " Asiento:"+ CompraVm.arrayAsientos[x1.title]
	                                          );
	                          }
	                   }

           	 		//Limpiamos
              	 	 CompraVm.numeroDeAsientoPer="";
             	    	 //Adultos
	                 	 	 for(var adultos=0;adultos<CompraVm.boletosIda.adultos;adultos++){
	                 	 		 CompraVm.numeroDeAsientoPer+="A,";
	                 	 	 }
	                 	 	 //Inapam
	                 	 	 for(var inapam=0;inapam<CompraVm.boletosIda.inapam;inapam++){
	                 	 		 CompraVm.numeroDeAsientoPer+="M,";
	                 	 	 }
	                 	 	 //Inapam
	                 	 	 for(var ninos=0;ninos<CompraVm.boletosIda.ninos;ninos++){
	                 	 		 CompraVm.numeroDeAsientoPer+="S,";
	                 	 	 }

	             	 	 temporal=CompraVm.numeroDeAsientoPer.substring(0,CompraVm.numeroDeAsientoPer.length-1);
	             	 	 CompraVm.numeroDeAsientoPer=temporal;

	                  if(CompraVm.totalSeleccionados==CompraVm.maxCompra)
	                  {
	                 	 var temporal=0;
	                 	 if(CompraVm.numeroDeAsiento.asientosIDA.length>2){
	                    	 	 var temporal=CompraVm.numeroDeAsiento.asientosIDA.substring(0,CompraVm.numeroDeAsiento.asientosIDA.length-1);
	                    	     CompraVm.numeroDeAsiento.asientosIDA=temporal;
	                 	 }
	                  }
	                  //alert("Entro selecciòn Regreso: "+ CompraVm.numeroDeAsientoPer);
          	  		}//Valdiar undefined
          	  }//Método selección de asientos IDA

              CompraVm.numeroDeAsientoPerRegreso="";
          	  CompraVm.seleccionarAsientoRegreso = function (x1){
       		  	if(x1.title!=undefined){
    		  		//Reducción de totalvariable
    		  		var splitAsientos=CompraVm.numeroDeAsiento.AsientosVuelta.split(",");
    		  		for(var y=0;y<splitAsientos.length;y++){
    		  			var e0=parseInt(splitAsientos[y]);
    		  			var e1=parseInt(x1.title);
    		  			 if(e0==e1){
        		  			 CompraVm.totalSeleccionados-=1;
    		  			 }
    		  		}

              		var total = 0;
                    for(var i=0;i <=  CompraVm.arrayAsientosRegreso.length;i++)
                    {
              			if (CompraVm.arrayAsientosRegreso[i]){
              				total ++;
              			}
              		}
                    //Valdacion cantidad boletos
              		if (total < CompraVm.maxCompra)
              		{
              			CompraVm.arrayAsientosRegreso[x1.title] =! CompraVm.arrayAsientosRegreso[x1.title];
              		}else{
              			CompraVm.arrayAsientosRegreso[x1.title] = false;
              		}

                    // console.log("Asientos array");
                    // console.log(CompraVm.arrayAsientosRegreso);
                    CompraVm.numeroDeAsiento.AsientosVuelta="";
                    CompraVm.numeroDeAsientoPerRegreso="";
                    for(var propt in CompraVm.arrayAsientosRegreso)
                    {
	                	if(propt!=undefined){
                        if(CompraVm.arrayAsientosRegreso[propt]){
	                    	  if(CompraVm.maxCompra==1){
		                          if(countAs>0)
		                          {
		                              CompraVm.numeroDeAsiento.AsientosVuelta += propt;
		                          }else{
		                              CompraVm.numeroDeAsiento.AsientosVuelta += propt;
		                          }
		                          countAs++;
	                    	  }else{
		                          if(countAs>0)
		                          {
		                              CompraVm.numeroDeAsiento.AsientosVuelta += propt;
		                              CompraVm.numeroDeAsiento.AsientosVuelta += ",";
		                          }else{
		                              CompraVm.numeroDeAsiento.AsientosVuelta += propt;
		                          }
		                          countAs++;
	                    	  }
	                      }
                        }
                    }

                    if(CompraVm.totalSeleccionados<CompraVm.maxCompra)
                    {
                          if(CompraVm.arrayAsientosRegreso[x1.title]){
                              CompraVm.totalSeleccionados+=1;
                              console.log("REGRESO total seleccionadosT:"+CompraVm.totalSeleccionados
                                            +"Max compra:"+ CompraVm.maxCompra+
                                            " Asiento:"+ CompraVm.arrayAsientosRegreso[x1.title]
                                          );
                          }
                      }

	               		//Limpiamos
	           	 	 	CompraVm.numeroDeAsientoPerRegreso='';
	          	    	 //Adultos
	                	 	 for(var adultos=0;adultos<CompraVm.boletosIda.adultos;adultos++){
	                	 		 CompraVm.numeroDeAsientoPerRegreso+="A,";
	                	 	 }
	                	 	 //Inapam
	                	 	 for(var inapam=0;inapam<CompraVm.boletosIda.inapam;inapam++){
	                	 		 CompraVm.numeroDeAsientoPerRegreso+="M,";
	                	 	 }
	                	 	 //Inapam
	                	 	 for(var ninos=0;ninos<CompraVm.boletosIda.ninos;ninos++){
	                	 		 CompraVm.numeroDeAsientoPerRegreso+="S,";
	                	 	 }


	          	 	 temporal=CompraVm.numeroDeAsientoPerRegreso.substring(0,CompraVm.numeroDeAsientoPerRegreso.length-1);
	          	 	 CompraVm.numeroDeAsientoPerRegreso=temporal;

                    if(CompraVm.totalSeleccionados==CompraVm.maxCompra)
                    {
                    	var temporal=0;
                   	 	if(CompraVm.numeroDeAsiento.AsientosVuelta.length>2){
                   	 		var temporal=CompraVm.numeroDeAsiento.AsientosVuelta.substring(0,CompraVm.numeroDeAsiento.AsientosVuelta.length-1);
                   	 		CompraVm.numeroDeAsiento.AsientosVuelta=temporal;
                   	 	}
                     }
          	  		}//Validar undefined
                  }//Método selección de asientos REGRESO

                  function checkChanged(valor_check){
                    if(valor_check.checar) CompraVm.numCap++;
                      else CompraVm.numCap--;
                  }

                  function convertMayusculas(CompraVm){
                    CompraVm.objCompra.cardObj.nombre = angular.uppercase(CompraVm.objCompra.cardObj.nombre);
                  }
                  function cambiaBloqueo(){
                    $rootScope.modalMessageDesbloqueo = false;
                  }
                  function desbloqueaAsientos(){
                    if (CompraVm.tiempoExcedido) {
                      $rootScope.page = 0;
                      $window.location.href = "#/";
                      $route.reload();
                      $rootScope.modalMessageDesbloqueo = false;
                    }else {
                      cambiaBloqueo();
                      if (CompraVm.page === 4) {
                        asientosService.desLockAsientos(CompraVm, CompraVm.CorridaSelectIDA.claveCorrida, "L",  CompraVm.numeroDeAsiento.asientosIDA, CompraVm.numeroDeAsientoPer, CompraVm.CorridaSelectIDA.origenCorrida, "R");
                      }else{
                        asientosService.desLockAsientos(CompraVm, CompraVm.CorridaSelectIDA.claveCorrida, "L",  CompraVm.numeroDeAsiento.asientosIDA, CompraVm.numeroDeAsientoPer, CompraVm.CorridaSelectIDA.origenCorrida, "I");
                      }
                      if (CompraVm.viaje.tipo == 'R' && CompraVm.page > 4) {
                        asientosService.desLockAsientos(CompraVm, CompraVm.CorridaSelectVUELTA.claveCorrida, "L",  CompraVm.numeroDeAsiento.AsientosVuelta, CompraVm.numeroDeAsientoPerRegreso, CompraVm.CorridaSelectVUELTA.origenCorrida, "R");
                      }
                    }
                  }
                  function isAgencia(){
                    if ($rootScope.userVo.tipoClienteId === undefined) {
                      $rootScope.isUserAgencia = false;
                    }else{
                      if (CompraVm.idAgencias.indexOf($rootScope.userVo.tipoClienteId) === -1) {
                        $rootScope.isUserAgencia = true;
                      }else{
                        $rootScope.isUserAgencia = false;
                      }
                    }
                    // console.log("Es Agencia: "+$rootScope.isUserAgencia);
                  }

                  function pagoPaypalMesagge(){
                    showNotif(true,"alerta","modal-azul", message.PAYPALMESSAGE);
                  }
                  function resetActivity (){
                    if(CompraVm.conteo.getTime() > 0 && !$rootScope.relojMenuOpc){
                      CompraVm.conteo.setTime(CompraVm.conteo.getTime() - 1000);
                      // console.log(CompraVm.conteo);
                      CompraVm.activityTimer = $timeout(CompraVm.resetActivity, CompraVm.userActivityInterval);
                    }else{
                      $rootScope.relojMenu = false;
                      if($rootScope.relojMenuOpc) {
                        $rootScope.relojMenuOpc = false;
                      }else if(CompraVm.conteo.getTime()===0){
                        CompraVm.tiempoExcedido = true;
                        if (!$rootScope.pagoAsyn) {
                            showModalTerminoTiempo();
                        }
                      }
                      clearTimeout(CompraVm.activityTimer);
                    }
                    vistaReloj();
                  }
                  function vistaReloj() {
                    var minuto = CompraVm.conteo.getMinutes();
                    var segundo = CompraVm.conteo.getSeconds();
                    $rootScope.relojView=((minuto < 10) ? "0" + minuto : minuto) + ":" + ((segundo < 10) ? "0" + segundo : segundo);
                    var valor = 100-((((minuto*100)+segundo)/(CompraVm.timeLimit*100))*100);
                    // console.log("Total: "+valor);
                    CompraVm.porcentaje = valor;
                    // console.log(CompraVm.porcentaje);
                    actualizaReloj();
                  }
                  function showModalTerminoTiempo(){
                    $rootScope.classIcon = "informacion";
                    $rootScope.claseModal = "modal-azul";
                    $rootScope.modalMessageDesbloqueo = true;
                    $rootScope.message = message.TIME_OVER;
                  }
                  function actualizaReloj(){
                    if (CompraVm.porcentaje > 100)
                    CompraVm.porcentaje = 100;
                    var deg = CompraVm.porcentaje*3.6;
                    if (deg <= 180){
                      //alert(prec+""+deg+""+(90+deg));
                      $rootScope.relojColor = 90+deg;
                      $rootScope.relojColorNum="#A2ECFB";
                      // activeBorder.css('background-image','linear-gradient(' + (90+deg) + 'deg, transparent 50%, #A2ECFB 50%),linear-gradient(90deg, #A2ECFB 50%, transparent 50%)');
                    }
                    else{
                      $rootScope.relojColorNum="#39B4CC";
                      $rootScope.relojColor = deg-90;
                      // activeBorder.css('background-image','linear-gradient(' + (deg-90) + 'deg, transparent 50%, #39B4CC 50%),linear-gradient(90deg, #A2ECFB 50%, transparent 50%)');
                    }
                  }
                  function loadChangeCard() {
                    // body...
                    CompraVm.objCompra.cardObj.secCode = "";
                    CompraVm.numTarjetaSave.checar = false;
                    if (CompraVm.card.lastName==='quita') {
                      CompraVm.tarjetaShow = true;
                    }else{
                      CompraVm.tarjetaShow = false;
                    }
                    pagoViaje.changeCardData(CompraVm);
                    // for (var tarjeta in CompraVm.tarjetas) {
                    //   tarjeta = CompraVm.tarjetas[tarjeta];
                    //   if (tarjeta.descTkn === CompraVm.card.descTkn) {
                    //     // console.log("Tarjeta selected Complete");
                    //     // console.log(tarjeta, CompraVm);
                    //     pagoViaje.changeCardData(tarjeta, CompraVm);
                    //   }
                    // }
                  }
              };

              function getEdad(pasajero){
                setEdades = [];
                for (var i = pasajero.edadMin; i < pasajero.edadMax; i++) {
                  setEdades.push(i);
                }
                return setEdades;
              }

              function selectDefaulEdad(pasajero, edad){
                var selectedEdad="";
//                console.log(pasajero.tipo+ " "+edad);
                if (pasajero.tipo==="menor" && edad === 4) {
                  selectedEdad="selected";
                }else if (pasajero.tipo==="adulto" && edad === 30) {
                  selectedEdad="selected";
                }else if (pasajero.tipo==="inapam" && edad === 60) {
                  selectedEdad="selected";
                }
                return selectedEdad;
              }
          /************************************************************
          *
          *
          ***********************************************************/
      })();
