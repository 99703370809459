(function(){
    angular.module('ERApp')
        .controller('AsociarBoletoController',AsociarBoletoController);

    function AsociarBoletoController($rootScope, $http, showNotif){
        $rootScope.curretOption = "AGREGAR BOLETOS";
        var AsociarBoletoVm = this;
        //Asociar Funciones
        AsociarBoletoVm.getOrigenes = getOrigenes;
        AsociarBoletoVm.agregarFolios = agregarFolios;
        AsociarBoletoVm.removeFolios = removeFolios;
        AsociarBoletoVm.regresarAddFolio = regresarAddFolio;
        AsociarBoletoVm.asociarFolios = asociarFolios;

        //Funciones Init
        getOrigenes();

        //Objects
        AsociarBoletoVm.boletos = {
          folio: "",
          numAsiento: "",
          idCliente : "",
          origen: ""
        }

        function regresarAddFolio(){
          console.log("Regresando Agregar Folios");
          AsociarBoletoVm.addFolio = true;
          AsociarBoletoVm.listFolios = false;
        }

        AsociarBoletoVm.rowFolio = [];
        function agregarFolios(){
          AsociarBoletoVm.boletos.idCliente = $rootScope.userVo.idCliente;
          console.log("::::::Data Peticion:::::")
          console.log(AsociarBoletoVm.boletos);
          $http.post($rootScope.contextoServices+'/webresources/facturacion/addTicket',
            AsociarBoletoVm.boletos).success(function(data){
                console.log(":::::data::::::");
                console.log(data.result);
                if(data.result.operacionExitosa === false){
                  showNotif(true,"alerta","modal-azul",data.result.errorMsg);
                }else{
                  AsociarBoletoVm.boletos = {};
                }
             });
        }


        function asociarFolios(){
          console.log("AsociarBoletoVm.boletos");
          console.log(AsociarBoletoVm.rowFolio);
          for(var i =0; i< AsociarBoletoVm.rowFolio.length;i++){
            AsociarBoletoVm.boletos.folio = AsociarBoletoVm.rowFolio[i].folio
            AsociarBoletoVm.boletos.numAsiento = AsociarBoletoVm.rowFolio[i].numAsiento
            AsociarBoletoVm.boletos.idCliente = AsociarBoletoVm.rowFolio[i].idCliente
            AsociarBoletoVm.boletos.origen = AsociarBoletoVm.rowFolio[i].origen


            AsociarBoletoVm.boletos = {};
          }
        }

        function removeFolios(folio){
          var index = -1;
          var comArr = eval (AsociarBoletoVm.rowFolio);
          for(var i=0; i< comArr.length; i++){
            if(comArr[i].folio === folio){
              index = i;
              break;
            }
          }
          if(index==-1){
            console.log("Error");
          }
          AsociarBoletoVm.rowFolio.splice(index,1);
        }

        // //Inicio obtiene los boletos no facturados $rootScope.userVo.idCliente
        // function getNoFacturados(){
        //   AsociarBoletoVm.buscarDatas = {
        //     "inicio":"1",
        //     "fin" :"10000",
        //     "idCliente" : "3932",
        //     "tipo" : "NF"
        //   }
        // }//Fin obtiene Boletos

        function getOrigenes(){
          console.log("Get Origenes");
          $http.get($rootScope.contextoServices+'/webresources/search/origen').success(function(data){
              console.log(data);
               AsociarBoletoVm.peticion = data;
               var origenes = [];
               for(var i=0; i< AsociarBoletoVm.peticion.result.origenes.length;i++){
                 var data = AsociarBoletoVm.peticion.result.origenes[i];
                 if(data!=null){
                   origenes.push(data);
                 }
               }
               AsociarBoletoVm.datosOrigenes = origenes;
          });
          AsociarBoletoVm.addFolio = true;
        }
}
})();
