(function(){
    angular.module('ERApp')
    .controller('infoFacturcionController',infoFacturcionController);

    function infoFacturcionController($rootScope,  $http, showNotif, commonModal,$q,commonFn,$window){


        var infoFacturacionVm = this;

        $rootScope.curretOption = "INFORMACIÒN FACTURACIÒN";
        var contexto=$rootScope.contextoServices;


        var mexico=156;
        var aguascalientes=1;


        infoFacturacionVm.llenaFrm = llenaFrm;
         infoFacturacionVm.large = $window.innerHeight;


        infoFacturacionVm.estadoLista=[];
        infoFacturacionVm.municipioLista=[];




        /* se inicializan controladores para habilitar campos
        o mostrar y ocultar campos */
        infoFacturacionVm.habilitarCampos=true;
        infoFacturacionVm.btnGuardarDireccion=false;
        infoFacturacionVm.mostrarCbRfcs=true;
        infoFacturacionVm.mostrarTituloNuevoRfcs=false;
        infoFacturacionVm.nuevo=false;
        infoFacturacionVm.mostrarInputs=false;

        infoFacturacionVm.rfcInfo={
            ciudad: "",
            clienteId: 0,
            clientesDirFiscalesId: 0,
            codigopostal: "",
            colonia: "",
            direccionFiscalPredeterminada: "N",
            domicilio: "",
            email: "",
            estado: "",
            noExt: "",
            noInt: "",
            pais: "",
            razonSocial: "",
            rfc: "",
            telefono: "",
            telefonoCelular: "",
            telefonoFax: "",
            telefonoOficina: ""
        }

        function limpiaVarRfcInfo(){
         infoFacturacionVm.rfcInfo={
            ciudad: "",
            clienteId: 0,
            clientesDirFiscalesId: 0,
            codigopostal: "",
            colonia: "",
            direccionFiscalPredeterminada: "N",
            domicilio: "",
            email: "",
            estado: "",
            noExt: "",
            noInt: "",
            pais: "",
            razonSocial: "",
            rfc: "",
            telefono: "",
            telefonoCelular: "",
            telefonoFax: "",
            telefonoOficina: ""

        }
    }

    var dirFiscales;


    getPaises();
    getEstados();
    getRfcs();




    /*metodo para obtener la direccion del cliente */
    function getRfcs() {

       infoFacturacionVm.listaRfcs=null;
       infoFacturacionVm.listaRfcsCombo=null;

       console.log("***** getRfcs ****");
       $http.post(contexto+'/webresources/client/getDatosFactura', {"idCliente": $rootScope.userVo.idCliente})
       .success(function(data){

        console.log("*** success *** ");
        console.log("*** data *** "+data);
        console.log(data);
        console.log(data.success);

        if(data.success && (data.result.direccionesFiscales !== undefined) ){



           commonModal.hideWait();
           infoFacturacionVm.listaRfcs=data;
           infoFacturacionVm.listaRfcsCombo=data.result.direccionesFiscales;
           seleccionaCbSelected();


           infoFacturacionVm.llenaFrm();


       }else if(data.result.direccionesFiscales == undefined){
                    console.log(" NO HAY REGISTROS ");


                    for (var indx = 0; indx < infoFacturacionVm.paisesLista.length; indx++) {
                
                        if(infoFacturacionVm.paisesLista[indx].ID_PAIS == mexico){
                                 infoFacturacionVm.paisSelect=infoFacturacionVm.paisesLista[indx]
                                infoFacturacionVm.llenaComboEstado(mexico);
                       } 
                    };


       }



   }).error(commonFn.errorCommon)



}// fin metodo getDireccionCliente

function getPaises(){

    console.log("*** getPaises get *** ");
    commonModal.showWait();
    $http.get( $rootScope.contextoAdmin+'/REST/ERBUS/paises',{})
    .success(function(data){

        console.log("*** success *** ");
        commonModal.hideWait();

        if(data.estatus){
            console.log("*** data *** "+data);
            infoFacturacionVm.paisesLista=data.result;

        }else{
            console.log("*** error getPaises *** ");

        }



    }).error(commonFn.errorCommon)

        }// fin getPaises


        function getEstados(){

            console.log("*** getEstados get *** ");
            commonModal.showWait();
            $http.get( $rootScope.contextoAdmin+'/REST/ERBUS/estados',{})
            .success(function(data){

                console.log("*** success *** ");
                commonModal.hideWait();

                if(data.estatus){   
                    console.log("*** data *** "+data);
                    infoFacturacionVm.estadoLista=data.result;
                }else{
                    console.log("*** error getEstados *** ");

                }



            }).error(commonFn.errorCommon)

        }// fin getEstados

        function getMunicipios(ID_ESTADO){
            var defered = $q.defer();
            var promise = defered.promise;

            try {
                console.log("*** getMunicipios get *** "+ID_ESTADO);
                commonModal.showWait();
                $http.get( $rootScope.contextoAdmin+'/REST/ERBUS/municipios/'+ID_ESTADO,{})
                .success(function(data){

                    console.log("*** success *** ");
                    commonModal.hideWait();

                    if(data.estatus){   
                        console.log("*** data *** "+data);
                        infoFacturacionVm.municipioLista=data.result;

                        $rootScope.permission = data.result;
                        defered.resolve($rootScope.permission);
                        console.log($rootScope.permission);


                    }else{
                        console.log("*** error getMunicipios *** ");

                    }
                }).error(commonFn.errorCommon)

            }catch (e) {
                defered.reject(e);
            }
            return promise;

        }// fin getEstados
       /*   metodo que llena el resto del formulario dependiendo 
       lo que se selecciones en el combo infoFacturacionVm.cbCalle */
       function llenaFrm(){
        console.log("***** llenaFrm infoFacturacion****");

        for (var i = infoFacturacionVm.listaRfcsCombo.length - 1; i >= 0; i--) {
            console.log("Busca direccion seleccionada...");

            console.log(infoFacturacionVm.listaRfcsCombo[i].rfc+"=== "+infoFacturacionVm.selected.rfc)
            if(infoFacturacionVm.listaRfcsCombo[i].rfc == infoFacturacionVm.selected.rfc){
                console.log("SE ENCONTRO LA DIRECCON ")
                console.log(infoFacturacionVm.listaRfcsCombo[i]);


                infoFacturacionVm.rfcInfo=infoFacturacionVm.listaRfcsCombo[i];



                //valida si el check box chkDireccionPredeterminada se debe seleccionar
                console.log(infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada )
                if(infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada == 'S'){
                    infoFacturacionVm.chkDireccionFiscalPredeterminada=true;
                }else{
                    infoFacturacionVm.chkDireccionFiscalPredeterminada=false;
                }



                for (var j = infoFacturacionVm.paisesLista.length - 1; j >= 0; j--) {
                    console.log("Busca y selecciona pais...");


                    if(infoFacturacionVm.paisesLista[j].DS_NOMBRE == infoFacturacionVm.rfcInfo.pais){
                        infoFacturacionVm.paisSelect=infoFacturacionVm.paisesLista[j];

                        console.log("id Pais..."+infoFacturacionVm.paisesLista[j].ID_PAIS);
                        if(infoFacturacionVm.paisesLista[j].ID_PAIS != mexico ){
                            infoFacturacionVm.mostrarInputs=true;
                        }else{
                            infoFacturacionVm.mostrarInputs=false;
                        }

                        for (var k = infoFacturacionVm.estadoLista.length - 1; k >= 0; k--) {
                            console.log("Busca y selecciona estado...");
                            console.log(infoFacturacionVm.estadoLista[k].DS_NOMBRE+" == ?"+infoFacturacionVm.rfcInfo.estado);
                            if(infoFacturacionVm.estadoLista[k].DS_NOMBRE == infoFacturacionVm.rfcInfo.estado){

                                infoFacturacionVm.estadoSelect=infoFacturacionVm.estadoLista[k];
                                


                                var promise = getMunicipios(infoFacturacionVm.estadoLista[k].ID_ESTADO);
                                promise.then(function(resultado) {

                                    if ( resultado != null  ){
                                       for (var x = 0; x < infoFacturacionVm.municipioLista.length; x++) {
                                        console.log("Busca y selecciona municipio...");
                                        if(infoFacturacionVm.municipioLista[x].DS_NOMBRE == infoFacturacionVm.rfcInfo.ciudad){
                                            infoFacturacionVm.municipioSelect=infoFacturacionVm.municipioLista[x]
                                        }
                                        
                                                };//for x

                                            }
                                        }, function(error) { });
                            }

                            };//for k
                        }
                        
                    };// for j
                    
                }
            };// for i

        }// fin llenaFrm



        /* metodo para seleccionar al inicio el option indicado en el combobox selected*/
        function seleccionaCbSelected() {

            console.log("***** seleccionaCbSelected ****");

            for (var i = 0; i < infoFacturacionVm.listaRfcsCombo.length; i++) {

                console.log(infoFacturacionVm.listaRfcsCombo[i].direccionFiscalPredeterminada)
                if(infoFacturacionVm.listaRfcsCombo[i].direccionFiscalPredeterminada == 'S'){
                    infoFacturacionVm.selected=infoFacturacionVm.listaRfcsCombo[i];
                    break;
                }else{
                    infoFacturacionVm.selected=infoFacturacionVm.listaRfcsCombo[0];
                }
            };

            
        }//fin seleccionaCbSelected



        /* funcion al dar clic en el icono editar , habilita inputs , 
            muestra botones guardar y cancelar 
            y esconde icono y combo calle  */
            infoFacturacionVm.editar = function(){

                console.log("***** editarDireccion ****");

                console.log(infoFacturacionVm.listaRfcs);

            //se verifica si hay un regustro seleccionado en el combo calle
            if( infoFacturacionVm.listaRfcs == null){

                console.log("***** NO SE PUEDE EDITAR  ****");
                infoFacturacionVm.habilitarCampos=true;
            }else{
                //se habilitan inputs y se muestran botones de guardar cancelar
                infoFacturacionVm.habilitarCampos=false;
                infoFacturacionVm.btnGuardarDireccion=true;
                infoFacturacionVm.mostrarCbRfcs=false;
                infoFacturacionVm.mostrarTituloNuevoRfcs=false;
            }
            
         }// fin editarDireccion

         /* funcion boton cancelar */
         infoFacturacionVm.cancelar = function (){
            console.log("***** cancelar ****");
            //se des-habilitan inputs y se  ocultan botones de guardar cancelar

            getRfcs();

            infoFacturacionVm.habilitarCampos=true;
            infoFacturacionVm.btnGuardarDireccion=false;
            infoFacturacionVm.mostrarCbRfcs=true;
            infoFacturacionVm.mostrarTituloNuevoRfcs=false;




         }// fin funcion boton cancelar

         



         /* funcion boton nuvaDireccion */
         infoFacturacionVm.nueva= function (){


            console.log("***** nuevaDireccion ****");

            /* se inicializa los parametros a actualizar */
            limpiaVarRfcInfo();


            for (var indx = 0; indx < infoFacturacionVm.paisesLista.length; indx++) {
                
                if(infoFacturacionVm.paisesLista[indx].ID_PAIS == mexico){
                        infoFacturacionVm.paisSelect=infoFacturacionVm.paisesLista[indx]
                        infoFacturacionVm.llenaComboEstado(mexico);
                } 
            };

            infoFacturacionVm.chkDireccionFiscalPredeterminada=false;

                //se des-habilitan inputs y se  ocultan botones de guardar cancelar
                infoFacturacionVm.nuevo=true;
                infoFacturacionVm.habilitarCampos=false;
                infoFacturacionVm.btnGuardarDireccion=true;
                infoFacturacionVm.mostrarCbRfcs=false;
                infoFacturacionVm.mostrarTituloNuevoRfcs=true;
                infoFacturacionVm.mostrarInputs=false;

                

         }// fin funcion boton nuvaDireccion

  /* funcion boton actualizar */
            infoFacturacionVm.actualizar = function (){

                console.log("***** actualizar ****");
                if(infoFacturacionVm.nuevo){

                    console.log("***** actualizar nuego registro ****");

                    infoFacturacionVm.rfcInfo.calle=infoFacturacionVm.rfcInfo.calle;

                    infoFacturacionVm.rfcInfo.clienteId = infoFacturacionVm.rfcInfo.clienteId;
                    infoFacturacionVm.rfcInfo.clientesDirFiscalesId = infoFacturacionVm.rfcInfo.clientesDirFiscalesId + 1;
                    infoFacturacionVm.rfcInfo.codigopostal = infoFacturacionVm.rfcInfo.codigopostal;
                    infoFacturacionVm.rfcInfo.colonia = infoFacturacionVm.rfcInfo.colonia;


                    //* validadndo el checkbox Direccion predeterminada */
                    if(infoFacturacionVm.chkDireccionFiscalPredeterminada){
                     
                        infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada='S'

                    }else{
                        
                        infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada='N'
                    }



                    infoFacturacionVm.rfcInfo.noExt = infoFacturacionVm.rfcInfo.noExt;
                    infoFacturacionVm.rfcInfo.noInt = infoFacturacionVm.rfcInfo.noExt;
                    infoFacturacionVm.rfcInfo.pais = infoFacturacionVm.paisSelect.DS_NOMBRE;

                    if(infoFacturacionVm.paisSelect.ID_PAIS == mexico){

                        infoFacturacionVm.rfcInfo.estado = infoFacturacionVm.estadoSelect.DS_NOMBRE;
                        infoFacturacionVm.rfcInfo.ciudad = infoFacturacionVm.municipioSelect.DS_NOMBRE;


                    }else{
                        infoFacturacionVm.rfcInfo.estado = infoFacturacionVm.rfcInfo.estado;
                        infoFacturacionVm.rfcInfo.ciudad = infoFacturacionVm.rfcInfo.ciudad;
                    }




                    var objDirFiscal= {

                        "tipoOper": "N", 

                        "direccionFiscal": { 

                            "ciudad": infoFacturacionVm.rfcInfo.ciudad, 
                            "pais": infoFacturacionVm.rfcInfo.pais, 
                            "domicilio": infoFacturacionVm.rfcInfo.domicilio, 
                            "noInt": infoFacturacionVm.rfcInfo.noInt, 
                            "razonSocial": infoFacturacionVm.rfcInfo.razonSocial, 
                            "telefonoOficina": infoFacturacionVm.rfcInfo.telefonoOficina, 
                            "clientesDirFiscalesId": 0, 
                            "telefono": infoFacturacionVm.rfcInfo.telefono, 
                            "telefonoCelular": infoFacturacionVm.rfcInfo.telefonoCelular, 
                            "rfc": infoFacturacionVm.rfcInfo.rfc, 
                            "colonia": infoFacturacionVm.rfcInfo.colonia, 
                            "direccionFiscalPredeterminada": infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada, 
                            "estado": infoFacturacionVm.rfcInfo.estado, 
                            "email": infoFacturacionVm.rfcInfo.email.toLowerCase(), 
                            "clienteId": $rootScope.userVo.idCliente, 
                            "telefonoFax": infoFacturacionVm.rfcInfo.telefonoFax, 
                            "noExt": infoFacturacionVm.rfcInfo.noExt,
                            "codigopostal": infoFacturacionVm.rfcInfo.codigopostal 

                        }, 

                        "idCliente": $rootScope.userVo.idCliente
                    };

                    actualizarDireccionFiscal(objDirFiscal);


                }else{


                    console.log("***** actualizar update ****");

                    /* se busca la direccion a editar 
                    y se actualizan los datos 
                    dentro infoFacturacionVm.listaRfcsCombo */

                    
                    /* validadndo el checkbox Direccion predeterminada */
                    if(infoFacturacionVm.chkDireccionFiscalPredeterminada){
                        
                        infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada='S'

                    }else{
                        
                        infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada='N'
                    }

                    infoFacturacionVm.rfcInfo.pais = infoFacturacionVm.paisSelect.DS_NOMBRE;

                    if(infoFacturacionVm.paisSelect.ID_PAIS == mexico){

                        infoFacturacionVm.rfcInfo.estado = infoFacturacionVm.estadoSelect.DS_NOMBRE;
                        infoFacturacionVm.rfcInfo.ciudad = infoFacturacionVm.municipioSelect.DS_NOMBRE;


                    }else{
                        infoFacturacionVm.rfcInfo.estado = infoFacturacionVm.rfcInfo.estado;
                        infoFacturacionVm.rfcInfo.ciudad = infoFacturacionVm.rfcInfo.ciudad;
                    }

                    
                    

                    var objDirFiscal= {

                        "tipoOper": "E", 

                        "direccionFiscal": { 

                            "ciudad": infoFacturacionVm.rfcInfo.ciudad, 
                            "pais": infoFacturacionVm.rfcInfo.pais, 
                            "domicilio": infoFacturacionVm.rfcInfo.domicilio, 
                            "noInt": infoFacturacionVm.rfcInfo.noInt, 
                            "razonSocial": infoFacturacionVm.rfcInfo.razonSocial, 
                            "telefonoOficina": infoFacturacionVm.rfcInfo.telefonoOficina, 
                            "clientesDirFiscalesId": infoFacturacionVm.rfcInfo.clientesDirFiscalesId, 
                            "telefono": infoFacturacionVm.rfcInfo.telefono, 
                            "telefonoCelular": infoFacturacionVm.rfcInfo.telefonoCelular, 
                            "rfc": infoFacturacionVm.rfcInfo.rfc, 
                            "colonia": infoFacturacionVm.rfcInfo.colonia, 
                            "direccionFiscalPredeterminada": infoFacturacionVm.rfcInfo.direccionFiscalPredeterminada, 
                            "estado": infoFacturacionVm.rfcInfo.estado, 
                            "email": infoFacturacionVm.rfcInfo.email.toLowerCase(), 
                            "clienteId": $rootScope.userVo.idCliente, 
                            "telefonoFax": infoFacturacionVm.rfcInfo.telefonoFax, 
                            "noExt": infoFacturacionVm.rfcInfo.noExt,
                            "codigopostal": infoFacturacionVm.rfcInfo.codigopostal 

                        }, 

                        "idCliente": $rootScope.userVo.idCliente
                    };

                    actualizarDireccionFiscal(objDirFiscal);


                    
                }// end else


                seleccionaCbSelected() 
            }// fin funcion boton actualizar





            function actualizarDireccionFiscal(obj){

                console.log("*** ACTUALIZAR DIRECCION  FISCALPOST *** ");

                console.log(obj);


                commonModal.showWait();
                $http.post(contexto+'/webresources/client/modificaDireccionFiscal',obj)
                .success(function(data){
                    console.log("*** success *** ");
                    commonModal.hideWait();

                    console.log("*** data *** "+data);
                    console.log(data);
                    if(data.success){


                        msje="El registro modificó satisfactoriamente";
                        showNotif(true,"informacion","modal-azul",msje.toUpperCase());
                        infoFacturacionVm.habilitarCampos=true;
                        infoFacturacionVm.btnGuardarDireccion=false;
                        infoFacturacionVm.mostrarCbRfcs=true;
                        infoFacturacionVm.mostrarTituloNuevoRfcs=false;
                        infoFacturacionVm.nuevo=false;
                        getRfcs();

                    }else{
                       showNotif(true,"informacion","modal-error","ERROR EN LA ACTUALIZACIÒN");
                       console.log(" ACTUALIZAR DIRECCION FISCAL postO currio un error");
                   }
               }).error(commonFn.errorCommon)

         }// fin actualizarDireccion 




         /* funcion para llenar el combo estado
         dependiendo el pais que se selecciones */
         infoFacturacionVm.llenaComboEstado = function (ID_PAIS){

            console.log("***** llenaComboEstado ****");

            infoFacturacionVm.estadoLista=[];


            // valida que se va a mostrar  inputs o combos
            if(ID_PAIS != mexico){
                infoFacturacionVm.mostrarInputs=true;
                infoFacturacionVm.rfcInfo.estado="";
                infoFacturacionVm.rfcInfo.ciudad="";
            }else if(ID_PAIS == mexico){
                infoFacturacionVm.mostrarInputs=false;
            }


            for (var i = 0; i < estados.length; i++) {

                console.log(estados[i].ID_PAIS +"=="+ ID_PAIS);
                if(estados[i].ID_PAIS == ID_PAIS){
                    infoFacturacionVm.estadoLista.push(estados[i]);
                }
            };


            infoFacturacionVm.estadoSelect=estados[0];
            infoFacturacionVm.llenaComboMunicipio(estados[0].ID_ESTADO);

         }// fin funcion llenaComboEstado



          /* funcion para llenar el combo estado
          dependiendo el pais que se selecciones */
          infoFacturacionVm.llenaComboMunicipio = function  (ID_ESTADO) {
            getMunicipios(ID_ESTADO);



            var promise = getMunicipios(ID_ESTADO);
            promise.then(function(resultado) {

                if(resultado != null ){
                    infoFacturacionVm.municipioSelect=infoFacturacionVm.municipioLista[0];
                }


            }, function(error) { });


        }



        infoFacturacionVm.rfcValid =function (idTest){
          var rfc = idTest;
          var strCorrecta;
          console.log(idTest);
          if(rfc != null){
            if (rfc.length >= 12){
              if (rfc.length == 12){
                var valid = '^(([A-Z]|[a-z]|&){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
            }else if(rfc.length == 13){
                var valid = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
            }
            var validRfc = new RegExp(valid);
            var matchArray = rfc.match(validRfc);
            if (matchArray==null){
                console.log("RFC INCORRECTO");
                infoFacturacionVm.rfcIncorrecto=true;
            }
            else{
                console.log("RFC CORRECTO");
                infoFacturacionVm.rfcIncorrecto=false;
            }
        }
        else{
          console.log("RFC INCORRECTA");
          infoFacturacionVm.rfcIncorrecto=true;
      }
  }else{
    infoFacturacionVm.rfcIncorrecto=false;
}
}

    /*funcion que oculta el boton del footer cuando el usuario 
         empieza a capturar datos con el movil*/

          angular.element($window).bind('resize', function(e) {
            console.log("Inicial :" + infoFacturacionVm.large);
            console.log("Cambios  :" + $window.innerHeight);
          if(parseInt(infoFacturacionVm.large) > parseInt($window.innerHeight)){
            
               console.log("--- SE OCULTA BOTON ---" );
              angular.element(document.getElementById('validate')).removeClass( "mostrarBtn" ).addClass( "ocultarBtn" );
              
          }else{

            console.log("--- SE MUESTRA BOTON ---" );
            angular.element(document.getElementById('validate')).removeClass( "ocultarBtn" ).addClass( "mostrarBtn" );
            
          }

         })




}// fin funcion DireccionClienteController



    

})();








