(function(){
    angular.module('ERApp')
        .factory('commonModal', ['$window','$rootScope', commonModal]);

    function commonModal(win,$rootScope){
          var servicio = {};

          servicio.showWait = function(){
              console.log('show wait...');
              $rootScope.waitModal = true;
          }

          servicio.hideWait = function(){
              console.log("hideWait");
              $rootScope.waitModal = false;
          }

          servicio.confirmMessage = function(){

          }

          return servicio;
    }

})();
