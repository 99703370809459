(function(){
    angular.module('ERApp')
        .factory('resumenService', ['$http','$rootScope','commonModal','commonFn', resumenService]);

        function resumenService($http, $rootScope, commonModal, commonFn){
            var service = {};

            Number.prototype.format = function(n, x) {
                    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
                    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
                };

            service.calculaTotal = function(ninos, adultos, inapam, precios, tipo, CompraVm){
              console.log(precios);
                var arrPrice = precios.split(",");
                var priceAdulto = arrPrice[0];
                var priceNino = arrPrice[1];
                var priceInapam = arrPrice[2];

                var niInt = parseInt(ninos);
                var adInt = parseInt(adultos);
                var inaInt = parseInt(inapam);

                console.log("Ninos(" + niInt + ") precio: " + priceNino);
                console.log("Adultos(" + adInt + ") precio: " + priceAdulto);
                console.log("INAPAM(" + inaInt + ") precio: " + priceInapam);

                var total = (adInt * priceAdulto) +
                            (niInt * priceNino) +
                            (inaInt * priceInapam);

              if ($rootScope.cambioHorario) {
                  CompraVm.CorridaSelectResumen.totalPago = ($rootScope.boleto.cargo+((total)- $rootScope.boleto.importe));
              }else{
                if ("I" === tipo) {
                  CompraVm.CorridaSelectResumen.totalPago = total;
                }else{
                  CompraVm.CorridaSelectResumen.totalPago = CompraVm.CorridaSelectResumen.totalPago + total;
                }
              }
              console.log("Total Pago: "+ CompraVm.CorridaSelectResumen.totalPago);
                var totalFormat = service.currencyFormat(total);

                return totalFormat;
            }

            service.getResumen = function(tipo, origen, destino,fecha, hora, asientos, adultos, inapam, menores, tarifaSencillo, CompraVm){
                // console.log("getResumenIda => " + tarifaSencillo);
            var resumenObj = {};
            var color = "";
            commonModal.showWait();
            resumenObj.titulo = ("I" === tipo)?"IDA" : "REGRESO";
            resumenObj.color = ("I" === tipo)?"#009CB1":"#9EC54D";
            var listResumen = [
                {label : "Origen: ", value : origen},
                {label : "Destino: ", value : destino},
                {label : "Fecha: ", value : fecha},
                {label : "Hora: ", value : hora},
                {label : "Asientos: ", value : asientos},
                {label : "Adultos: ", value : adultos},
                {label : "INPAM: ", value : inapam},
                {label : "Menores: ", value : menores}
                ]
                resumenObj.listResumen = listResumen;
                resumenObj.precio = service.calculaTotal(menores, adultos, inapam, tarifaSencillo, tipo, CompraVm);
                resumenObj.total = service.currencyFormat(CompraVm.CorridaSelectResumen.totalPago);
                resumenObj.color = ("I" === tipo)?"#009CB1" : "#87C455";
                resumenObj.etiquetaTotal = "Total:";
            commonModal.hideWait();
                return resumenObj;
            }

            service.currencyFormat = function(value){
                var totalNum = parseInt(value);
                return "$ " + totalNum.format(2);
            }

            service.accionResumen = function (CompraVm, tipo){
              // console.log("Total"+CompraVm.CorridaSelectResumen.totalPago);
              CompraVm.resumenObj = this.getResumen(tipo,
              CompraVm.CorridaSelectResumen.Corrida.descripcionOrigeSolicitado,
              CompraVm.CorridaSelectResumen.Corrida.descripcionDestinoSolicitado,
              CompraVm.CorridaSelectResumen.fecha,
              CompraVm.CorridaSelectResumen.Corrida.hora,
              CompraVm.CorridaSelectResumen.selectAsiento,
              CompraVm.CorridaSelectResumen.boletos.adultos,
              CompraVm.CorridaSelectResumen.boletos.inapam,
              CompraVm.CorridaSelectResumen.boletos.ninos,
              CompraVm.CorridaSelectResumen.Corrida.tarifaSencillo,
              CompraVm);
              CompraVm.objCompra.deviceFingerPrintId = cybs_dfprofiler("banorteixe_estrellaroja","live");
              // console.log("Resumen ida");
              // console.log(CompraVm.resumenObj);
            }
            function cybs_dfprofiler(merchantID,environment)
            {

             if (environment == 'live') {
               var org_id = 'k8vif92e';
             } else {
                 var org_id = '1snn5n9w';
             }

             var sessionID = new Date().getTime();

             var paragraphTM = document.createElement("p");
             str = "background:url(https://h.online-metrix.net/fp/clear.png?org_id=" + org_id + "&session_id=" + merchantID + sessionID + "&m=1)";

             paragraphTM.styleSheets = str;
             paragraphTM.height = "0";
             paragraphTM.width = "0";
             paragraphTM.hidden = "true";

             document.body.appendChild(paragraphTM);

             var img = document.createElement("img");

             str = "https://h.online-metrix.net/fp/clear.png?org_id=" + org_id + "&session_id=" + merchantID + sessionID + "&m=2";
             img.src = str;

             document.body.appendChild(img);

             var tmscript = document.createElement("script");
             tmscript.src = "https://h.online-metrix.net/fp/check.js?org_id=" + org_id + "&session_id=" + merchantID + sessionID;
             tmscript.type = "text/javascript";

             document.body.appendChild(tmscript);

             var objectTM = document.createElement("object");
             /*objectTM.type = "application/x-shockwave-flash";*/
             /*objectTM.classid = "clsid:D27CDB6E-AE6D-11cf-96B8-444553540000";*/
             objectTM.data = "https://h.online-metrix.net/fp/fp.swf?org_id=" + org_id + "&session_id=" + merchantID + sessionID;
             objectTM.width = "1";
             objectTM.height = "1";
             objectTM.id = "thm_fp";

             var param = document.createElement("param");
             param.name = "movie";
             param.value = "https://h.online-metrix.net/fp/fp.swf?org_id=" + org_id + "&session_id=" + merchantID + sessionID;
             objectTM.appendChild(param);

             document.body.appendChild(objectTM);

             return sessionID;
            }
            return service;
        }

})();
