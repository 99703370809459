
(function(){
    angular.module('ERApp')
        .controller('CompartirViajeAntController',CompartirViajeAntController);

    function CompartirViajeAntController($rootScope,$window,$compile){
        $rootScope.curretOption = "VIAJES ANTERIORES";

        var CompartirAnteriorVm = this;
        CompartirAnteriorVm.compartirTwitter = compartirTwitter;
        CompartirAnteriorVm.compartirFacebook = compartirFacebook;
        CompartirAnteriorVm.compartirGoogle  = compartirGoogle;
        CompartirAnteriorVm.compartirCorreo = compartirCorreo;

        console.log("controller compartir viajes anteriores..");
        CompartirAnteriorVm.uniqueTicket = $rootScope.boleto;
        console.log($rootScope.boleto);

        if ($rootScope.boleto == null){
          $window.location.href = "#/home-viajes";
        }


                function compartirTwitter(){
                  var $h = 500;
                  var $w = 600;
                  var $left = (screen.width / 2) - ($w / 2);
                  var origen = 'Viajando desde '+CompartirAnteriorVm.uniqueTicket.origen+' hacia '+CompartirAnteriorVm.uniqueTicket.destino;
                  var via = 'estrellarojapue';
                  var url = 'https://twitter.com/share?url=';
                  url += encodeURIComponent("https://www.estrellaroja.com.mx/");
                  url += '&hashtags=' + encodeURIComponent(origen);
                  url += '&via=' + encodeURIComponent(via);
                  window.open(url, 'tcompartir', 'menubar=no,toolbar=no,height=' + $h + ',width=' + $w + ',left=' + $left);
                  return false;
                  console.log("Compartiendo twitter ////");
                }

                function compartirFacebook(){
                  var $h = 500;
                     var $w = 600;
                     var $left = (screen.width / 2) - ($w / 2);
                     var url = 'https://www.facebook.com/dialog/feed?app_id=934259909956907';
                     url += '&display=popup';
                     url += '&caption=' + encodeURIComponent('Estrella Roja Puebla');
                     url += '&redirect_uri=' + encodeURIComponent('https://www.facebook.com');
                     url += '&link=' + encodeURIComponent("https://www.estrellaroja.com.mx");
                     url += '&picture=' + encodeURIComponent('http://www.plazasandiego.com.mx/wp-content/uploads/2015/10/logo-estrellaroja-nuevo-300x300.jpg?resize=100%2C330');
                     url += '&description=' + encodeURIComponent('#Viajando desde..... '+CompartirAnteriorVm.uniqueTicket.origen+' hacia '+CompartirAnteriorVm.uniqueTicket.destino);
                     url += '&actions={name:"EstrellaRojaPuebla",link:"https://www.facebook.com/estrellarojapuebla"}';
                     window.open(url, 'fcompartir', 'menubar=no,toolbar=no,height=' + $h + ',width=' + $w + ',left=' + $left);
                     return false;
                    console.log("Compartiendo face... ////");
                }


                function compartirGoogle(){
                    var options = {
                        contenturl: 'http://8d90997e.ngrok.io',
                        contentdeeplinkid: '/pages',
                        clientid: '585114757265-gtdo4k66p35ne4i07djcms9rsbbgj41l.apps.googleusercontent.com',
                        cookiepolicy: 'single_host_origin',
                        prefilltext: '#viajando de '+CompartirAnteriorVm.uniqueTicket.origen+' hacia '+CompartirAnteriorVm.uniqueTicket.destino ,
                        calltoactionlabel: 'INVITE',
                        calltoactionurl: 'http://plus.google.com/pages/create',
                        };
                        gapi.interactivepost.render('sharePost', options);

                        console.log("invocando google +");
                }


                        function compartirCorreo(){
                          $window.location.href = "#/compartir-boleto";
                        }



}
})();
