(function(){

    angular.module("ERApp")
        .factory('datosPasajeroService', ['commonModal','showNotif','$rootScope','$http','commonFn', '$location', '$window','pagoViaje', datosPasajeroService]);

        function datosPasajeroService(commonModal,showNotif,$rootScope, $http, commonFn, $location, $window, pagoViaje){
            var message = {
                NO_USERS : 'ES NECESARIO CAPTURAR LOS DATOS DE TODOS LOS PASAJEROS',
                NO_TERM : "ES NECESARIO QUE ACEPTES LOS TERMINOS Y CONDICIONES DE USO",
                NO_PAY_FORM : "SELECCIONA UNA FORMA DE PAGO"
            }
            var service = {};

            service.inicioDatosPasajero = inicioDatosPasajero;
            service.validaPasajeros = validaPasajeros;

            function inicioDatosPasajero(CompraVm){
                CompraVm.objCompra.idaObj.pasajeros = [];
                console.log("inicioDatosPasajero.....");
              var descripcionLabel = "";
              var consecutivo = 0;
              for (var i = 0; i < CompraVm.boletos.adultos; i++) {
                if (consecutivo === 0) {
                  descripcionLabel = "Invitado";
                  consecutivo +=1;
                }else{
                  descripcionLabel = "Acompañante " + consecutivo;
                  consecutivo +=1;
                }
                CompraVm.objCompra.idaObj.pasajeros.push({tipo:"adulto", edad:"30", tipoPasajero: "A", genero:"H", nombrePasajero:"", extra: i, edadMin:13, edadMax:60, descripcion:descripcionLabel});
              }
              for (var i = 0; i < CompraVm.boletos.inapam; i++) {
                if (consecutivo === 0) {
                  consecutivo +=1;
                  descripcionLabel = "Invitado";
                }else{
                  descripcionLabel = "Acompañante " + consecutivo;
                  consecutivo +=1;
                }
                CompraVm.objCompra.idaObj.pasajeros.push({tipo:"inapam", edad:"50", tipoPasajero: "S", genero:"H", nombrePasajero:"",extra: i, edadMin:65, edadMax:100, descripcion:descripcionLabel});
              }
              for (var i = 0; i < CompraVm.boletos.ninos; i++) {
                if (consecutivo === 0) {
                  descripcionLabel = "Invitado";
                  consecutivo +=1;
                }else{
                  descripcionLabel = "Acompañante " + consecutivo;
                  consecutivo +=1;
                }
                CompraVm.objCompra.idaObj.pasajeros.push({tipo:"menor", edad:"8", tipoPasajero: "M", genero:"H", nombrePasajero:"", extra: i, edadMin:4, edadMax:13, descripcion:descripcionLabel});
              }
              descripcionBoton(CompraVm);
            }

            function descripcionBoton(CompraVm){
              if ($rootScope.isUserAgencia) {
                CompraVm.paginacion[CompraVm.page].bntDescripcion = "PAGAR";
              }
              if ($rootScope.cambioHorario) {
                if (CompraVm.CorridaSelectResumen.totalPago === 0) {
                  CompraVm.paginacion[CompraVm.page].bntDescripcion = "COMPRAR";
                  CompraVm.datosPasajeroBandera=true;
                }
                if (CompraVm.CorridaSelectResumen.totalPago < 0) {
                  CompraVm.paginacion[CompraVm.page].bntDescripcion = "REEMBOLSO";
                  CompraVm.datosPasajeroBandera=true;
                }
              }
            }
            function validaPasajeros(CompraVm){
              var returnValue = validaDatosPersonalesPasajero(CompraVm.objCompra.idaObj.pasajeros);
              if (!returnValue) {
                return returnValue;
              }
              if (!$rootScope.isUserAgencia && CompraVm.CorridaSelectResumen.totalPago > 0) {
                if(CompraVm.objCompra.cardObj.type === "" || CompraVm.objCompra.cardObj.type === undefined){
                    showNotif(true,'alerta','modal-azul',message.NO_PAY_FORM);
                    return false;
                }

                if(CompraVm.datosP.terminos!=="0"){
                    showNotif(true,'alerta','modal-azul',message.NO_TERM);
                    return false;
                }
              }
                if(CompraVm.objCompra.cardObj.type === '03'){

                    pagoViaje.pagoPaypal(CompraVm)

                    return false;
                }
                return returnValue;
            }

            function validaDatosPersonalesPasajero(arrPasajeros){
                var noComplete = false;
                for(var a = 0; a<arrPasajeros.length; a++){
                    var pasajeroValidate = arrPasajeros[a];
                    // console.log(pasajeroValidate);
                    if(pasajeroValidate.genero === "" ||
                        pasajeroValidate.edad === "" ||
                            pasajeroValidate.nombrePasajero === ""
                        ){
                        noComplete  = true;
                    }
                }
                if(noComplete){
                    showNotif(true,'alerta','modal-azul',message.NO_USERS);
                    return false;
                }

                return true;
            }

            return service;

        }

})();
