(function(){

function localizar(){
	navigator.geolocation.getCurrentPosition(mapa,error);
}


function mapa(pos){

	var latitud=pos.coords.latitude;
	var longitud=pos.coords.longitude;
	var precision=pos.coords.accurancy;


	alert("lat :"+latitud+ " longitud :"+longitud+" precision: "+precision);
}


function error(error){

	if(error.code == 0){
		alert("error desconocido");
	}else if(error.code == 1){
		alert("no me dejaste hubicarte");
	}else if(error.code == 2){
		alert("posicion no disponible");
	}else if(error.code == 3){
		alert("error");
	}

}

})();