(function(){
	angular.module('ERApp')
	.controller('EnviarQuejaController',EnviarQuejaController);

	function EnviarQuejaController($rootScope,$http,showNotif,$window,commonModal,commonFn){
		$rootScope.curretOption = "ENVIAR QUEJA";

		var EnviarQuejaVm = this;
		EnviarQuejaVm.enviarQueja = enviarQueja;
		EnviarQuejaVm.back = back;


		if ($rootScope.boleto === null){
			$window.location.href = "#/viajesAnteriores";
		}

		EnviarQuejaVm.tkt = $rootScope.boleto.folio;

		EnviarQuejaVm.reclamacion='';
		EnviarQuejaVm.listQuejas = [
			{value:"1",label:"EQUIPAJE"},
			{value:"2",label:"CONFORT"},
			{value:"3",label:"VIAJE"},
			{value:"4",label:"COMPRA"},
			{value:"5",label:"ATENCION"},
			{value:"6",label:"PUNTUALIDAD"},
			{value:"7",label:"OTRO"}]



 EnviarQuejaVm.queja = EnviarQuejaVm.listQuejas[0];


	function back(){
      $window.location.href = "#/viajesAnteriores"
	}


 function enviarQueja(){
	 	commonModal.showWait();
			$http.post($rootScope.contextoServices+'/webresources/viajes/enviarQueja',
			{ descripcion:EnviarQuejaVm.reclamacion,
				tipoQueja:EnviarQuejaVm.queja.value
			})
			.success(function(data){
				commonModal.hideWait();
			if(data.success){
							showNotif(
							 true,
							 'informacion',
							 'modal-azul',
							 'Su queja ha sido enviada SATISFACTORIAMENTE');
							$window.location.href = "#/viajesAnteriores"
				}
			})
			.error(commonFn.errorCommon);

}



	}// fin funcion DireccionClienteController

})();
