(function(){
    angular.module('ERApp')
        .controller('FacturacionController',FacturacionController);

    function FacturacionController($rootScope, $http, showNotif, commonModal, commonFn){
        $rootScope.curretOption = "FACTURACI\u00d3N";
        var FacturacionVm = this;
        FacturacionVm.showAddTaxi = false;
        FacturacionVm.listaEstacionamiento = false;
        FacturacionVm.flujoPrincipal = true;

        FacturacionVm.noGenerada = "SU FACTURA NO PUEDE SER PROCESADA, FAVOR DE VERIFICAR LOS DATOS";
        FacturacionVm.facturaGeneradaTxt = "SE HA GENERADO SU FACTURA Y SE ENVIAR\u00c1 A SU CORREO ELECTR\u00d3NICO GRACIAS";
        FacturacionVm.seleccionarRfcTxt = "FAVOR DE SELECCIONAR UN RFC V\u00c1LIDO";
        FacturacionVm.seleccionarBoletoTxt = "SELECCIONAR EL/LOS BOLETOS A FACTURAR";
        $rootScope.clase="facturaGenerada";

        FacturacionVm.factura ={
          tipo : 'B'
        }

        // FacturacionVm.formEstacionamiento.$setPristine();
        // FacturacionVm.formRFC.$setPristine();

        //Asociacion funciones
        FacturacionVm.dataValid = dataValid;
        FacturacionVm.listFacturacion = listFacturacion;
        FacturacionVm.changeListFactura = changeListFactura;
        FacturacionVm.getNoFacturados = getNoFacturados;
        FacturacionVm.clickRow = clickRow;
        FacturacionVm.showModal = showModal;
        FacturacionVm.addListTaxis = addListTaxis;
        FacturacionVm.removeRowTaxi = removeRowTaxi;
        FacturacionVm.getRfcList = getRfcList;
        FacturacionVm.regresarAddTaxi = regresarAddTaxi;
        FacturacionVm.addListEstacionamiento = addListEstacionamiento;
        FacturacionVm.regresarAddEstacionamiento = regresarAddEstacionamiento;
        FacturacionVm.addRfc = addRfc;
        FacturacionVm.closeAddRfc = closeAddRfc;
        FacturacionVm.getDatosAddRfc = getDatosAddRfc;
        FacturacionVm.llenaComboEstado = llenaComboEstado;
        FacturacionVm.llenaComboMunicipio = llenaComboMunicipio;
        FacturacionVm.agregarRFC = agregarRFC;
        FacturacionVm.facturarBoleto = facturarBoleto;
        FacturacionVm.facturarTaxi = facturarTaxi;
        FacturacionVm.facturarEstacionamiento = facturarEstacionamiento;
        FacturacionVm.remRowEstacionamiento = remRowEstacionamiento;
        //FacturacionVm.getPaises = getPaises;

        //FUNCTION INITIAL
        FacturacionVm.listFacturacion();
        FacturacionVm.changeListFactura();
        FacturacionVm.getNoFacturados();
        FacturacionVm.getRfcList();

        //Objects

        //Objeto Alta RFC
        FacturacionVm.datosRfc ={
          "tipoOper" : "N",
         "direccionFiscal" : {
            "rfc" : "",
            "razonSocial" : "",
            "domicilio" : "",
            "colonia" : "",
            "noExt" : "",
            "noInt" : "",
            "codigopostal" : "",
            "pais" : "",
            "estado" : "",
            "ciudad" : "",
            "email" : "",
            "direccionFiscalPredeterminada" : "",
          },
          "idCliente" : $rootScope.userVo.idCliente
        }

        //Objeto Facturar Boletos
        FacturacionVm.facturarData = {
          "numDireccion" : "",
          "concepto" : "",
          "producto" : "",
          "unidadNegocio" : "",
          "tickets" : [],
          "idCliente" : $rootScope.userVo.idCliente
        }

        FacturacionVm.boletos = {
          "id":"",
          "folio" :"",
          "numAsiento" : "",
          "origen" : "",
          "referencia" : ""
        }


        function dataValid(idTest){
          var rfc = idTest;
          var strCorrecta;
          console.log(idTest);
          if(rfc != null){
            if (rfc.length >= 12){
              if (rfc.length == 12){
                var valid = '^(([A-Z]|[a-z]|&){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
              }else if(rfc.length == 13){
                var valid = '^(([A-Z]|[a-z]|\s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
              }
              var validRfc = new RegExp(valid);
              var matchArray = rfc.match(validRfc);
              if (matchArray==null){
                console.log("RFC INCORRECTO");
                FacturacionVm.rfcIncorrecto=true;
              }
              else{
                console.log("RFC CORRECTO");
                FacturacionVm.rfcIncorrecto=false;
              }
            }
            else{
              console.log("RFC INCORRECTO");
              FacturacionVm.rfcIncorrecto=true;
            }
          }else{
            FacturacionVm.rfcIncorrecto=false;
          }
        }

          //Funcion que envia el listado de boletos a facturar
          function facturarBoleto(){
            console.log("Array de Boletos");
            console.log(FacturacionVm.listBoletos);
            var dat = FacturacionVm.numDireccionFiscal.value;
            if(FacturacionVm.numDireccionFiscal === undefined){
              showNotif(true,"alerta","modal-azul",FacturacionVm.seleccionarRfcTxt);
            }else if(FacturacionVm.listBoletos.length === 0){
              showNotif(true,"alerta","modal-azul",FacturacionVm.seleccionarBoletoTxt);
            }else{

              FacturacionVm.facturarData.numDireccion = FacturacionVm.numDireccionFiscal.value;
              FacturacionVm.facturarData.concepto = "Boleto";
              FacturacionVm.facturarData.producto = "Boleto";
              FacturacionVm.facturarData.unidadNegocio = "TMS";
              FacturacionVm.facturarData.tickets = FacturacionVm.listBoletos;

              console.log(":::::::::::Datos a enviar a Facturar:::::::");
              console.log(FacturacionVm.facturarData);
              commonModal.showWait();

              $http.post($rootScope.contextoServices+'/webresources/facturacion/emiteFactura',
                FacturacionVm.facturarData).success(function(data){
                  commonModal.hideWait();
                   console.log(data);
                   if(data.status === true){
                     FacturacionVm.datosBoletos = [];
                     getNoFacturados();
                     FacturacionVm.isGrey = [];
                     FacturacionVm.listBoletos = [];
                     FacturacionVm.boletos = {};
                     showNotif(true,"facturaGenerada","modal-azul",FacturacionVm.facturaGeneradaTxt);
                   }else{
                     console.log("Error al facturar");
                     showNotif(true,"alerta","modal-azul",FacturacionVm.noGenerada);
                   }
                 }).error(commonFn.errorCommon);
              }
          }//Fin funcion que envia el listado de boletos a facturar

          //Funcion que envia el listado de boletos a facturar
          function facturarTaxi(){
            if(FacturacionVm.numDireccionFiscal === undefined){
              showNotif(true,"alerta","modal-azul",FacturacionVm.seleccionarRfcTxt);
            }/*else if(FacturacionVm.listBoletos.length === 0){
              showNotif(true,"alerta","modal-azul",FacturacionVm.seleccionarBoletoTxt);
            }*/
            else{
              FacturacionVm.facturarData.numDireccion = FacturacionVm.numDireccionFiscal;
              FacturacionVm.facturarData.concepto = "TICKETTAXI";
              FacturacionVm.facturarData.producto = "TICKETTAXI";
              FacturacionVm.facturarData.unidadNegocio = "TOPDRIVER";
              FacturacionVm.facturarData.tickets = FacturacionVm.filas;

              console.log(":::::::::::Datos a enviar a Facturar:::::::");
              console.log(FacturacionVm.facturarData);
              commonModal.showWait();

              $http.post($rootScope.contextoServices+'/webresources/facturacion/emiteFactura',
                FacturacionVm.facturarData).success(function(data){
                  commonModal.hideWait();
                   console.log(data);
                   if(data.status === true){
                     FacturacionVm.datosBoletos = [];
                     FacturacionVm.boletos = {};
                     FacturacionVm.filas = [];
                     showNotif(true,"facturaGenerada","modal-azul",FacturacionVm.facturaGeneradaTxt);
                   }else{
                     console.log("Error al facturar");
                     showNotif(true,"alerta","modal-azul",FacturacionVm.noGenerada);
                   }
                 }).error(commonFn.errorCommon);
              }
          }//Fin funcion que envia el listado de Tickets de Taxis a facturar

          //Funcion que realiza la facturacion de los Tickets de estacionamiento
          function facturarEstacionamiento(){
            if(FacturacionVm.numDireccionFiscal === undefined){
              showNotif(true,"alerta","modal-azul",FacturacionVm.seleccionarRfcTxt);
            }/*else if(FacturacionVm.listBoletos.length === 0){
              showNotif(true,"alerta","modal-azul",FacturacionVm.seleccionarBoletoTxt);
            }*/
            else{
              FacturacionVm.facturarData.numDireccion = FacturacionVm.numDireccionFiscal;
              FacturacionVm.facturarData.concepto = "TICKET";
              FacturacionVm.facturarData.producto = "TICKET";
              FacturacionVm.facturarData.unidadNegocio = "ESTACIONAMIENTO";
              FacturacionVm.facturarData.tickets = FacturacionVm.rowEstacionamiento;

              console.log(":::::::::::Datos a enviar a Facturar:::::::");
              console.log(FacturacionVm.facturarData);
              commonModal.showWait();



              $http.post($rootScope.contextoServices+'/webresources/facturacion/emiteFactura',
                FacturacionVm.facturarData).success(function(data){
                  commonModal.hideWait();
                   console.log(data);
                   if(data.status === true){
                     FacturacionVm.datosBoletos = [];
                     FacturacionVm.boletos = {};
                     FacturacionVm.rowEstacionamiento = [];
                     showNotif(true,"facturaGenerada","modal-azul",FacturacionVm.facturaGeneradaTxt);
                   }else{
                     console.log("Error al facturar");
                     console.log("data.cause");
                     console.log(data.cause);
                     showNotif(true,"alerta","modal-azul",FacturacionVm.noGenerada);
                     //showNotif(true,"alerta","modal-azul",data.cause);
                   }
                 }).error(commonFn.errorCommon);
              }
          }//Fin funcion que envia el listado de Tickets de Taxis a facturar



          //Invoca el servicio para agregar un RFC
        function agregarRFC(){
          console.log("FacturacionVm.datosRfc");
          console.log(FacturacionVm.datosRfc);
          commonModal.showWait();
          var str = FacturacionVm.datosRfc.direccionFiscal.rfc;
          var res = str.toUpperCase();
          console.log(res);
          FacturacionVm.datosRfc.direccionFiscal.rfc = res;
          $http.post($rootScope.contextoServices+'/webresources/client/modificaDireccionFiscal',
            FacturacionVm.datosRfc).success(function(data){
              commonModal.hideWait();
               console.log(data);
               closeAddRfc();
               cleanRfcModel();
               FacturacionVm.numDireccionFiscal = "";
               municipiosList = [];
               estadosList = [];
               listaPaises = [];
               getRfcList();
          }).error(commonFn.errorCommon);
        }//Fin invoca el servicio para agregar RFC


        function cleanRfcModel(){
          FacturacionVm.datosRfc.direccionFiscal.rfc = " ";
          FacturacionVm.datosRfc.direccionFiscal.razonSocial = " ";
          FacturacionVm.datosRfc.direccionFiscal.domicilio = " ";
          FacturacionVm.datosRfc.direccionFiscal.colonia = " ";
          FacturacionVm.datosRfc.direccionFiscal.noExt = " ";
          FacturacionVm.datosRfc.direccionFiscal.noInt = " ";
          FacturacionVm.datosRfc.direccionFiscal.codigopostal = " ";
          FacturacionVm.datosRfc.direccionFiscal.pais = " ";
          FacturacionVm.datosRfc.direccionFiscal.estado = " ";
          FacturacionVm.datosRfc.direccionFiscal.ciudad = " ";
          FacturacionVm.datosRfc.direccionFiscal.email = " ";
          FacturacionVm.datosRfc.direccionFiscal.direccionFiscalPredeterminada = " ";
        }

        //Funcion que Llena el combo de paises en addRFC
        function getDatosAddRfc(){
          commonModal.showWait();
          $http.get($rootScope.contextoAdmin+'/REST/ERBUS/paises',{})
          .success(function(data){
              console.log("*** success *** ");
              commonModal.hideWait();
              if(data.estatus){
                  console.log("*** data *** "+data);
                  FacturacionVm.paisesList=data.result;
                  FacturacionVm.datosRfc.direccionFiscal.pais = "156";
                  FacturacionVm.datosRfc.direccionFiscal.estado = "1";
                  llenaComboEstado("");
                  llenaComboMunicipio("1");
              }else{
                  console.log("*** error getPaises *** ");
              }
          }).error(commonFn.errorCommon)
        }//Fin Funcion que Llena el combo de paises en addRFC




        //Inicio obtiene los boletos no facturados
        function getNoFacturados(){
          FacturacionVm.buscarData = {
            "inicio":"1",
            "fin" :"10000",
            "idCliente" : $rootScope.userVo.idCliente,
            "tipo" : "NF"
          }
          commonModal.showWait();
          $http.post($rootScope.contextoServices+'/webresources/viajes/getMoreViajes',
            FacturacionVm.buscarData).success(function(data){
            FacturacionVm.peticion = data;
               var boletosArray = [];
               console.log("FacturacionVm.peticion.result.length");
               console.log(FacturacionVm.peticion.result.length);
               for(var i=0;i<FacturacionVm.peticion.result.length;i++){
                 var data = FacturacionVm.peticion.result[i];
                 for(var j=0; j<data.boletos.length;j++){
                   boletosArray.push(data.boletos[j]);
                 }
               }
               commonModal.hideWait();
               FacturacionVm.datosBoletos = boletosArray;
          }).error(commonFn.errorCommon);
        }//Fin obtiene Boletos



        function removeListBoletos(index){
            FacturacionVm.listBoletos.splice(index,1);
            console.log("::::ListBoletos Delete:::::");
            console.log(FacturacionVm.listBoletos);
        }



        FacturacionVm.isGrey =[];

        function clickRow(id, registro){
          console.log("Agregando Boleto");
          console.log(id);
          console.log(registro);
          FacturacionVm.isGrey[id] = FacturacionVm.isGrey[id]=='error'?'':'error';
          console.log("FacturacionVm.isGrey[id]");
          console.log(FacturacionVm.isGrey[id]=='error'?'':'error');
          if(FacturacionVm.isGrey[id]=='error'?'':'error'==='error'){
            rmRowListBoletosAutobus(registro);
          }else{
            addListBoletos(registro);
          }
        }

        FacturacionVm.listBoletos = [];
        function addListBoletos(ticket){
          FacturacionVm.boletos.id = ticket.folio;
          FacturacionVm.boletos.folio = ticket.folio;
          FacturacionVm.boletos.numAsiento = ticket.noAsiento;
          FacturacionVm.boletos.origen = ticket.origen;
          FacturacionVm.listBoletos.push(FacturacionVm.boletos);
          FacturacionVm.boletos = {};
        }//Fin add List Boletos


        //Inicio rmRowListBoletosAutobus
        function rmRowListBoletosAutobus(registro){
          var index = -1;
          var comArr = eval (FacturacionVm.listBoletos);
          for(var i=0; i< comArr.length; i++){
            if(comArr[i].folio === registro.folio){
              console.log("encontrado");
              index = i;
              break;
            }
          }
          if(index === -1){
            console.log("Error Borrando Boleto");
          }
          console.log("index");
          console.log(index);
          FacturacionVm.listBoletos.splice(index,1);
          console.log("::::FacturacionVm.listBoletos::::");
          console.log(FacturacionVm.listBoletos);
        }//Fin rmRowListBoletosAutobus




        //Inicio Add Taxi Ticket
        FacturacionVm.filas = [];
        function addListTaxis(){
          var date = new Date(FacturacionVm.date);

          var dd = date.getDate();
          var mm = date.getMonth() + 1;
          var y = date.getFullYear();
          if(dd<10){
            dd ="0"+dd;
          }
          if(mm<10){
            mm="0"+mm;
          }
          var fecha = dd + '/'+ mm + '/'+ y;
          console.log("fecha");
          console.log(fecha);

          //var fecha = date.toLocaleDateString("en-US");
          FacturacionVm.boletos.folio = FacturacionVm.tkt;
          FacturacionVm.boletos.id = FacturacionVm.tkt;
          FacturacionVm.boletos.referencia = fecha;
          //FacturacionVm.filas.push({'tkt':FacturacionVm.tkt, 'fecha':fecha});
          FacturacionVm.filas.push(FacturacionVm.boletos);

          FacturacionVm.showAddTaxi = false;
          FacturacionVm.listaTaxis = true;
          FacturacionVm.showAddTaxi = false;

          FacturacionVm.tkt = "";
          FacturacionVm.date = "";
          FacturacionVm.boletos={};
        }//fin add Ticket Taxi

        //Inicio Add Ticket ESTACIONAMIENTO
        FacturacionVm.rowEstacionamiento = [];
        function addListEstacionamiento(){
          var date = new Date(FacturacionVm.dateEstacionamiento);

          var dd = date.getDate();
          var mm = date.getMonth() + 1;
          var y = date.getFullYear();
          if(dd<10){
            dd ="0"+dd;
          }
          if(mm<10){
            mm="0"+mm;
          }
          var fecha = dd + '/'+ mm + '/'+ y;

          //var fecha = date.toLocaleDateString("en-US");
          FacturacionVm.boletos.folio = FacturacionVm.tktEstacionamiento;
          FacturacionVm.boletos.id = FacturacionVm.tktEstacionamiento;
          FacturacionVm.boletos.referencia = fecha;

          FacturacionVm.rowEstacionamiento.push(FacturacionVm.boletos);
          FacturacionVm.vwEstacionamiento = false;
          FacturacionVm.btnEstacionamientoAdd = false;
          FacturacionVm.listaEstacionamiento = true;
          FacturacionVm.tktEstacionamiento = "";
          FacturacionVm.dateEstacionamiento = "";
          FacturacionVm.boletos = {};
        }//Fin add Ticket ESTACIONAMIENTO

        function removeRowTaxi(tkt){
          var index = -1;
          var comArr = eval (FacturacionVm.filas);
          for(var i=0; i< comArr.length; i++){
            if(comArr[i].tkt === tkt){
              index = i;
              break;
            }
          }
          if(index === -1){
            console.log("Error");
          }
          FacturacionVm.filas.splice(index,1)
        }

        function remRowEstacionamiento(tkt){
          console.log("Eliminando Ticket Estacionamiento");
          var index = -1;
          var comArr = eval (FacturacionVm.rowEstacionamiento);
          for(var i=0; i<comArr.length; i++){
            if(comArr[i].tktEstacionamiento === tkt){
              index = i;
              break;
            }
          }
          if(index === -1){
            console.log("Error Eliminar Tkt Estacionamiento");
          }
          FacturacionVm.rowEstacionamiento.splice(index, 1);
        }

      //  var datoDefecto = 0;
        //Obtiene el listado de RFCs de los clientes
        var d = 0;
        function getRfcList(){
          $http.post($rootScope.contextoServices+'/webresources/client/getDatosFactura',
            {"idCliente":$rootScope.userVo.idCliente}).success(function(data){
                if(data.result.direccionesFiscales === undefined){
                  console.log("data.result.length:::::::::");
                  console.log(":::::::::data::::::::::");
                  console.log(data);
                  FacturacionVm.mostrarRFC = true;
                  FacturacionVm.existeRfc = false;
                }else{
                 FacturacionVm.peticionRfcList = data;
                 var nElementos = FacturacionVm.peticionRfcList.result.direccionesFiscales.length;
                 if(nElementos > 0){
                   FacturacionVm.existeRfc = true;
                   var RfcArray = [];
                   for(var i=0;i<nElementos;i++){
                     var label = FacturacionVm.peticionRfcList.result.direccionesFiscales[i].rfc;
                     var value = FacturacionVm.peticionRfcList.result.direccionesFiscales[i].clientesDirFiscalesId;
                     if(label!="" && value!=""){ //Validamos que el RFC y su Id no sean vacios
                       var def = FacturacionVm.peticionRfcList.result.direccionesFiscales[i].direccionFiscalPredeterminada;
                       if(def === "S"){
                         d=i;
                         //datoDefecto = d;
                       }
                       RfcArray.push({'value':value, 'label':label});
                     }
                   }
                   FacturacionVm.listRfc = RfcArray;
                   console.log("FacturacionVm.listRfc");
                   console.log(FacturacionVm.listRfc);
                   FacturacionVm.numDireccionFiscal = RfcArray[d];
                   console.log("FacturacionVm.numDireccionFiscal");
                   console.log(FacturacionVm.numDireccionFiscal);
                 }else {
                   FacturacionVm.existeRfc = false;
                   FacturacionVm.mostrarRFC = true;
                 }
               }
          }).error(commonFn.errorCommon);
        }//Fin Obtiene Lista de RFC del Cliente



        // Llenar combos de Estados y Mucicipios
        //Llena COmbo Estados
        var estadosList = [];
        function llenaComboEstado(idPaises){
          estadosList = [];
          var idPais = FacturacionVm.datosRfc.direccionFiscal.pais;
          console.log("idPais");
          console.log(idPais);
          if(idPais==="156"){//156 idMexico

            console.log("Pais mexico");
            FacturacionVm.estadoLista=[];
            FacturacionVm.paisDiferente = false;
            FacturacionVm.selectPais= true;

            $http.get($rootScope.contextoAdmin+'/REST/ERBUS/estados',{})
       			  .success(function(data){
       				     console.log("*** success *** ");
       				        commonModal.hideWait();
             				if(data.estatus){
                      console.log("*** data *** "+data);
                      for (var i = 0; i < data.result.length; i++) {
                        console.log(data.result[i].ID_PAIS);
                        if(data.result[i].ID_PAIS === idPais){
                          estadosList.push(estados[i]);
                        }
                      }
                      FacturacionVm.selectEstados = estadosList;
                      FacturacionVm.datosRfc.direccionFiscal.estado = FacturacionVm.selectEstados[0].ID_ESTADO;
                      llenaComboMunicipio("");
             				}else{
             					console.log("*** error getEstados *** ");
             				}
       			}).error(function(status, error){
       				console.log("*** getEstados  ERROR *** ");
       			})
          }else{
            console.log("Pais diferente mexico");
            FacturacionVm.datosRfc.direccionFiscal.estado = "";
            FacturacionVm.datosRfc.direccionFiscal.ciudad = "";
            FacturacionVm.paisDiferente = true;
            FacturacionVm.selectPais= false;
          }
       }// fin funcion llenaComboEstado



       //Inicio Llena combo Municipios
       var municipiosList = [];
       function llenaComboMunicipio(idEstados){
         municipiosList = [];
         FacturacionVm.datosRfc.direccionFiscal.ciudad = "";
         console.log("FacturacionVm.datosRfc.direccionFiscal.estado");
         console.log(FacturacionVm.datosRfc.direccionFiscal.estado);
         var idEstados = FacturacionVm.datosRfc.direccionFiscal.estado;
         console.log(">>>>>>>>idEstado>>>>>>>>>");
         console.log(idEstados);
         $http.get($rootScope.contextoAdmin+'/REST/ERBUS/municipios/'+idEstados,{})
         .success(function(data){
                console.log("*** success *** ");
                commonModal.hideWait();

                if(data.estatus){
                  console.log("*** data *** ");
                  console.log(data);
                  for(var i=0; i<data.result.length; i++){
                    if(data.result[i].ID_ESTADO === idEstados){
                      municipiosList.push(data.result[i]);
                    }
                  }
                  FacturacionVm.selectMunicipios = municipiosList;
                  console.log("Lista de Municipios");
                  console.log(FacturacionVm.selectMunicipios);
                  console.log(":::::::::::::::::::");
                  FacturacionVm.datosRfc.direccionFiscal.ciudad = municipiosList[0].ID_MUNICIPIO;
                  //FacturacionVm.datosRfc.direccionFiscal.ciudad = FacturacionVm.selectMunicipios[0];
                }else{
                  console.log("*** error getMunicipios *** ");
                }
         })
       }//Fin Llena combo Municipios
       // Fin Llena combos Estados y municipios


       //Lista de Tipos de Facturacion
       function listFacturacion(){
           FacturacionVm.tipoFacturaList = [{
               value : 'B',
               label : 'BOLETO DE AUTOBUS'
           },
           {
               value : 'T',
               label : 'TICKET DE TAXI'
           },
           {
               value : 'E',
               label : 'TICKET DE ESTACIONAMIENTO'
           }];
       }//Fin Lista de Tipos de Facturacion


       //Funcion que oculta los divs de los tipos de facturacion
       function changeListFactura(){
         cleanComponents();
         FacturacionVm.isGrey = [];
         if(FacturacionVm.factura.tipo === 'B'){
           FacturacionVm.vwEstacionamiento = false;
           FacturacionVm.showAddTaxi = false;
           FacturacionVm.vwBoleto = true;
           FacturacionVm.listaTaxis = false;
           FacturacionVm.listaEstacionamiento = false;
           getNoFacturados();

         }else if(FacturacionVm.factura.tipo === 'T'){
           FacturacionVm.vwEstacionamiento = false;
           FacturacionVm.vwBoleto = false;
           FacturacionVm.listaTaxis = false;
           FacturacionVm.listaEstacionamiento = false;

           FacturacionVm.showAddTaxi = true;
           //FacturacionVm.showAddTaxi = true;
         }else if(FacturacionVm.factura.tipo === 'E'){
           FacturacionVm.vwBoleto = false;
           FacturacionVm.showAddTaxi = false;


           FacturacionVm.listaTaxis = false;
           FacturacionVm.listaEstacionamiento = false;

           FacturacionVm.vwEstacionamiento = true;
           FacturacionVm.btnEstacionamientoAdd = true;
         }
       }//ChangeListFacturaEnd

       //Show Modal
       function showModal(){
         showNotif(true,"alerta","modal-azul","LO SENTIMOS, NO HAY HORARIOS DISPONIBLES DESDE AEROPUERTO, PERO TENEMOS MÁS OPCIONES");
       }

       function cleanComponents(){
         FacturacionVm.datosBoletos = [];
         FacturacionVm.boletos = {};
         FacturacionVm.listBoletos = [];
         FacturacionVm.filas = [];
         FacturacionVm.rowEstacionamiento = [];
       }

       function regresarAddTaxi(){
         FacturacionVm.showAddTaxi = true;
         FacturacionVm.listaTaxis = false;
         FacturacionVm.showAddTaxi = true;
       }

       function regresarAddEstacionamiento(){
         FacturacionVm.listaEstacionamiento = false;
         FacturacionVm.vwEstacionamiento = true;
         FacturacionVm.btnEstacionamientoAdd = true;
       }

       //Inicio Show AddRFC
       function addRfc(){
         FacturacionVm.selectPais= true;
         getDatosAddRfc();
         FacturacionVm.flujoPrincipal = false;
         FacturacionVm.addRFC = true;
       }//Fin Show AddRFC

       //Ininio closeAddRfc
       function closeAddRfc(){
         FacturacionVm.flujoPrincipal = true;
         FacturacionVm.addRFC = false;
       }//Fin closeAddRfc

    } //FIN DEL FUNCTIONCONTROLLER

})();
