(function(){
    angular.module('ERApp')
        .controller('VerBoletoController',VerBoletoController);

    function VerBoletoController($rootScope,$window){
        $rootScope.curretOption = "VIAJES FUTUROS";

        var VerBoletoVm = this;

                if ($rootScope.boleto == null){
                  $window.location.href = "#/home-viajes";
                }
        VerBoletoVm.precarga =  precarga;
        VerBoletoVm.activo = [];
        VerBoletoVm.uniqueTicket ={};
        VerBoletoVm.thestring = "YOUR TEXT TO ENCODE";
        VerBoletoVm.boleto = $rootScope.boleto;
        console.log($rootScope.boleto);
        precarga();
        getCodigo();


        function precarga(){
          var aux = VerBoletoVm.boleto.fechaViaje;
          if(aux===""){
            console.log("Fecha No Definida");
          }else{
            fecha = aux.split("/");
            var today = new Date(fecha[1]+"/"+fecha[0]+"/"+fecha[2]);
            var dateString = today.format("dddd dd mmm yyyy");
            VerBoletoVm.boleto.fechaViaje = dateString;
          }
            VerBoletoVm.boleto.subtotal = "$"+VerBoletoVm.boleto.subtotal;
            VerBoletoVm.boleto.iva = "$"+VerBoletoVm.boleto.iva;
            VerBoletoVm.boleto.importe = "$"+VerBoletoVm.boleto.importe;
        }

        function getCodigo(){
          $("#codigoBarraBoletoDetail").barcode(VerBoletoVm.boleto.codigoBarras, "datamatrix", {
                            'color': '#000000',
                            'bgColor': 'rgba(0,0,0,0)',
                            'fontSize':10,
                            'showHRI': false,
                            'moduleSize':4
                        });
        }
      }


      var dateFormat = function () {
          var    token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g,
              timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
              timezoneClip = /[^-+\dA-Z]/g,
              pad = function (val, len) {
                  val = String(val);
                  len = len || 2;
                  while (val.length < len) val = "0" + val;
                  return val;
              };

          // Regexes and supporting functions are cached through closure
          return function (date, mask, utc) {
              var dF = dateFormat;

              // You can't provide utc if you skip other args (use the "UTC:" mask prefix)
              if (arguments.length == 1 && Object.prototype.toString.call(date) == "[object String]" && !/\d/.test(date)) {
                  mask = date;
                  date = undefined;
              }

              // Passing date through Date applies Date.parse, if necessary
              date = date ? new Date(date) : new Date;
              if (isNaN(date)) throw SyntaxError("invalid date");

              mask = String(dF.masks[mask] || mask || dF.masks["default"]);

              // Allow setting the utc argument via the mask
              if (mask.slice(0, 4) == "UTC:") {
                  mask = mask.slice(4);
                  utc = true;
              }

              var    _ = utc ? "getUTC" : "get",
                  d = date[_ + "Date"](),
                  D = date[_ + "Day"](),
                  m = date[_ + "Month"](),
                  y = date[_ + "FullYear"](),
                  H = date[_ + "Hours"](),
                  M = date[_ + "Minutes"](),
                  s = date[_ + "Seconds"](),
                  L = date[_ + "Milliseconds"](),
                  o = utc ? 0 : date.getTimezoneOffset(),
                  flags = {
                      d:    d,
                      dd:   pad(d),
                      ddd:  dF.i18n.dayNames[D],
                      dddd: dF.i18n.dayNames[D + 7],
                      m:    m + 1,
                      mm:   pad(m + 1),
                      mmm:  dF.i18n.monthNames[m],
                      mmmm: dF.i18n.monthNames[m + 12],
                      yy:   String(y).slice(2),
                      yyyy: y,
                      h:    H % 12 || 12,
                      hh:   pad(H % 12 || 12),
                      H:    H,
                      HH:   pad(H),
                      M:    M,
                      MM:   pad(M),
                      s:    s,
                      ss:   pad(s),
                      l:    pad(L, 3),
                      L:    pad(L > 99 ? Math.round(L / 10) : L),
                      t:    H < 12 ? "a"  : "p",
                      tt:   H < 12 ? "am" : "pm",
                      T:    H < 12 ? "A"  : "P",
                      TT:   H < 12 ? "AM" : "PM",
                      Z:    utc ? "UTC" : (String(date).match(timezone) || [""]).pop().replace(timezoneClip, ""),
                      o:    (o > 0 ? "-" : "+") + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
                      S:    ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
                  };

              return mask.replace(token, function ($0) {
                  return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
              });
          };
      }();

      // Some common format strings
      dateFormat.masks = {
          "default":      "ddd mmm dd yyyy HH:MM:ss",
          shortDate:      "m/d/yy",
          mediumDate:     "mmm d, yyyy",
          longDate:       "mmmm d, yyyy",
          fullDate:       "dddd, mmmm d, yyyy",
          shortTime:      "h:MM TT",
          mediumTime:     "h:MM:ss TT",
          longTime:       "h:MM:ss TT Z",
          isoDate:        "yyyy-mm-dd",
          isoTime:        "HH:MM:ss",
          isoDateTime:    "yyyy-mm-dd'T'HH:MM:ss",
          isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
      };

      // Internationalization strings
      dateFormat.i18n = {
          dayNames: [
              "Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab",
              "Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"
          ],
          monthNames: [
              "de Ene de", "de Feb de", "de Mar de", "de Abr de", "de May de", "de Jun de", "de Jul de", "de Ago de", "de Sep de", "de Oct de", "de Nov de", "de Dic de",
              "de Enero de", "de Febrero de", "de Marzo de", "de Abril de", "de Mayo de", "de Junio de", "de Julio de", "de Agosto de", "de Septiembre de", "de Octubre de", "de Noviembre de", "de Diciembre de"
          ]
      };

      // For convenience...
      Date.prototype.format = function (mask, utc) {
          return dateFormat(this, mask, utc);
      };
})();
