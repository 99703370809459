(function(){

    angular.module("ERApp")
        .factory('pagoViaje', ['$http','commonModal','showNotif','$rootScope', 'commonFn','$location', "$window", pagoViaje]);

        function pagoViaje($http,commonModal,showNotif,$rootScope, commonFn,$location, $window){
            var service = {};
            service.URL_PAGO_CARD = $rootScope.contextoServices + "/webresources/PayService/apply";
            service.URL_PAGO_PAYPAL = $rootScope.contextoServices + "/webresources/PayService/applyPaypal";
            var code = {
            }
            var message = {
                NO_DATA : "ES NECESARIO CAPTURAR TODOS LOS DATOS DE TU TARJETA",
                VALIDA_DATOS : "AL PARECER LOS DATOS DE TU TARJETA SON INCORRECTOS, FAVOR DE VALIDARLOS",
                DECLINADA : "TARJETA DECLINADA",
                RECHAZADA : "TU TARJETA FUE RECHAZADA",
                NO_TRANSACCION : "NO SE PUDO PROCESAR LA TRANSACCIÓN",
                INFORMACION_VERIFICA : "VERIFICA LA INFORMACIÓN DE TU TARJETA",
                CAPTCHAINVALID : "CAPTCHAS INVÁLIDOS",
                CARD_NUMBER : "NÚMERO DE TARJETA INCORRECTO",
                CVV_NUMBER : "CVV INCORRECTO",
                MAIL: "EMAIL INCORRECTO"
            }
            var regex = {
                  CARD_NUMBER : "^[0-9]{16}$",
                  CVV_NUMBER : "^[0-9]{3}$"
            }
            service.YEARS_CARD = 10;
            service.inicioPagoPage = inicioPagoPage;
            service.emitePago = emitePago;
            service.pagoPaypal = pagoPaypal;
            service.changeCardData =changeCardData;

            function inicioPagoPage(CompraVm){
                CompraVm.listaAnios = getAnios();
                CompraVm.months = getMonths();
                CompraVm.captchaSelect1 = Math.floor(Math.random() * (4 - 0 + 1)) + 0;
                CompraVm.captchaSelect2 = Math.floor(Math.random() * (4 - 0 + 1)) + 0;
                var captchas = ["Maleta","Volante","Mapa","Boleto","Autobús"];
                while (CompraVm.captchaSelect1 === CompraVm.captchaSelect2) {
                  CompraVm.captchaSelect2 = Math.floor(Math.random() * (4 - 0 + 1)) + 0;
                }
                CompraVm.captchaText = "Para continuar selecciona "+captchas[CompraVm.captchaSelect1]+" y "+captchas[CompraVm.captchaSelect2];
                if($rootScope.userVo.userFirmado) {
                  loadCard(CompraVm);
                }
            }
            function changeCardData(CompraVm) {
              // body...
              // console.log("Tarjeta recibida");
              // console.log(tarjeta);
              CompraVm.objCompra.cardObj.nombre = CompraVm.card.firstName;
              CompraVm.objCompra.cardObj.number = CompraVm.card.cardNumber;
              CompraVm.objCompra.meses = CompraVm.months[CompraVm.card.expirationMonth-1];
              var añoTarjeta = CompraVm.card.exprirationYear.toString();
              CompraVm.objCompra.cardObj.year = añoTarjeta.substr(añoTarjeta.length - 2);
            }
            function loadCard(CompraVm){
              commonModal.showWait();
              getCard = {
                  mail : CompraVm.objCompra.mailCliente,
                  typeCard : CompraVm.objCompra.cardObj.type
              }
              $http.post($rootScope.contextoServices+'/webresources/tokenService/getByToken', getCard)
                  .success(function(data){
                      var element = {cardNumber:"", descTkn:"Seleccione una tarjeta", firstName:"", expirationMonth:0, exprirationYear:0, lastName:"quita"}
                      data.cards.splice(0,0,element)
                      CompraVm.tarjetas = data.cards;
                      CompraVm.card = CompraVm.tarjetas[0];
                      CompraVm.tarjetaShow = true;
                      commonModal.hideWait();
                  }).error(commonFn.errorCommon);
            }
            function validaPago(cardObj, CompraVm){
                // console.log("<=== VALIDANDO PAGO ====>");
                // console.log(cardObj);
                // console.log("validando");
                // console.log(CompraVm.objCompra.mailCliente);
                // console.log(CompraVm.objCompra.cardObj.year);
                // console.log(CompraVm.objCompra.cardObj.month);
                if (CompraVm.datosPasajeroBandera || $rootScope.isUserAgencia) {
                  return true;
                }

                if(cardObj.nombre === ""  ||
                    cardObj.number === "" ||
                     cardObj.secCode === "" ||
                        CompraVm.objCompra.mailCliente === "" ||
                        CompraVm.objCompra.cardObj.year === "" ||
                        CompraVm.objCompra.meses === undefined
                    ){
                        showNotif(true, 'alerta', 'modal-azul',message.NO_DATA);
                        // console.log("incorrecto");
                        return false;
                }else{
                  var patt = "";
                  var res = "";
                  for (var i = 0; i < 4; i++) {
                    switch (i) {
                      case 0:
                      patt = new RegExp(regex.CARD_NUMBER);
                      res = patt.test(cardObj.number);
                      break;
                      case 1:
                      patt = new RegExp(regex.CVV_NUMBER);
                      res = patt.test(cardObj.secCode);
                      break;
                      case 2:
                      patt = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                      res = patt.test(CompraVm.objCompra.mailCliente);
                      break;
                      case 3:
                      res = captchaValida(CompraVm);
                      break;
                    }
                    if (!res) {
                      switch (i) {
                        case 0:
                        res = message.CARD_NUMBER;
                        break;
                        case 1:
                        res = message.CVV_NUMBER;
                        break;
                        case 2:
                        res = message.MAIL;
                        break;
                        case 3:
                        res = message.CAPTCHAINVALID;
                        break;
                      }
                      showNotif(true, 'alerta', 'modal-azul',res);
                      return false;
                    }
                  }
                }
                CompraVm.objCompra.cardObj.month = CompraVm.objCompra.meses.value;
                console.log(CompraVm.objCompra.cardObj.month);
                    console.log("Correcto!");
                    return true;
            }

            function captchaValida(CompraVm){
              var captchaMax=0;
              var bandera = false;

              for (var captcha in CompraVm.captchasValor) {
                var captchaSelected =  CompraVm.captchasValor[captcha];
                if (captchaSelected.checar) {
                  if (captchaSelected.valor === CompraVm.captchaSelect1 || captchaSelected.valor === CompraVm.captchaSelect2) {
                      captchaMax +=1;
                  }
                }
              }
              if (captchaMax == 2) {
                  bandera = true;
              }
              return bandera;
            }

            function emitePago(CompraVm){
              //  console.log("Es Agencia: "+$rootScope.isUserAgencia);
              //  console.log("Total "+CompraVm.CorridaSelectResumen.totalPago);
               if (CompraVm.CorridaSelectResumen.totalPago <= 0) {
                  console.log("Es correcto");
               }
                if(!validaPago(CompraVm.objCompra.cardObj, CompraVm) && !$rootScope.isUserAgencia && !(CompraVm.CorridaSelectResumen.totalPago <= 0)) return;
                fillAsiento(CompraVm.numeroDeAsiento.asientosIDA, CompraVm.objCompra.idaObj.pasajeros);
                CompraVm.objCompra.tipoViaje = 1;
                if ($rootScope.isUserAgencia) {
                  CompraVm.objCompra.tipoClienteId = $rootScope.userVo.tipoClienteId;
                }
                if ($rootScope.cambioHorario) {
                  CompraVm.objCompra.folioCambio = $rootScope.boleto.folio;
                  CompraVm.objCompra.asientoCambio = $rootScope.boleto.noAsiento;
                  CompraVm.objCompra.origenCambio = $rootScope.boleto.origen;
                }
                if (CompraVm.viaje.tipo == 'R') {
                  CompraVm.objCompra.tipoViaje = 2;
                  CompraVm.objCompra.regresoObj.pasajeros = angular.copy(CompraVm.objCompra.idaObj.pasajeros);
                  fillAsiento(CompraVm.numeroDeAsiento.AsientosVuelta, CompraVm.objCompra.regresoObj.pasajeros);
                }
                var tarjetaDatos = CompraVm.numTarjetaSave;
                CompraVm.objCompra.saveToken = ( tarjetaDatos.checar && $rootScope.userVo.userFirmado ) ? 'S' : 'N';
                if (CompraVm.datosPasajeroBandera) {
                  CompraVm.objCompra.saveToken = 'N';
                  CompraVm.objCompra.tipoCambio = true;
                }
                commonModal.showWait();
                if(CompraVm.objCompra.cardObj.type === '03'){
                    console.log("PAGANDO POR PAYPAL");
                    paypal(CompraVm.objCompra);
                }else{
                    console.log("PAGANDO POR TARJETA");
                    cardPay(CompraVm.objCompra, CompraVm);
                }
            }

            function pagoPaypal(CompraVm){
                fillAsiento(CompraVm.numeroDeAsiento.asientosIDA, CompraVm.objCompra.idaObj.pasajeros);
                console.log("Paypal 2.1");
                console.log(CompraVm.objCompra);
                    if (CompraVm.viaje.tipo == 'R') {
                      CompraVm.objCompra.regresoObj.pasajeros = angular.copy(CompraVm.objCompra.idaObj.pasajeros);
                      fillAsiento(CompraVm.numeroDeAsiento.AsientosVuelta, CompraVm.objCompra.regresoObj.pasajeros);
                    }
                commonModal.showWait();
                $http.post($rootScope.contextoServices+'/webresources/PayService/applyPaypal', CompraVm.objCompra)
                    .success(function(data){
                        console.log(data);
                        if(data.success){
                            console.log("PATH => " + $location.protocol() + "://" + $location.host() + ":" +  $location.port() + "/esb/paymentwithpaypalMobile?idContext="+data.idContext);
                            $window.location.href = $location.protocol() + "://" + $location.host() + ":" +  $location.port() +$rootScope.contextoServices+"/paymentwithpaypalMobile?idContext="+data.idContext;
                            //$location.path($rootScope.contextoServices+'/paymentwithpaypalMobile?idContext='+data.idContext);
                        }
                    }).error(commonFn.errorCommon);
            }

            function fillAsiento(arrAsientos, pasajeros){
                var asientos = arrAsientos.split(",");
                for(var as = 0; as<pasajeros.length; as++){
                    pasajeros[as].asiento = asientos[as];
                }
            }
            function cardPay(objPago, CompraVm){
                console.log("CARD PAY....");
                // console.log($rootScope.contextoServices + "/webresources/PayService/apply");
                // console.log(objPago);
                $rootScope.pagoAsyn = true;
                $http.post($rootScope.contextoServices + "/webresources/PayService/apply",objPago)
                    .success(function(data){
                        commonModal.hideWait();
                        // console.log(data);

                        switch (data.status) {
                            case ("DP"):
                                    $rootScope.pagoAsyn = false;
                                    if (CompraVm.tiempoExcedido) {
                                      CompraVm.showModalTerminoTiempo();
                                    }else {
                                        showNotif(true, 'alerta', 'modal-azul',message.INFORMACION_VERIFICA);
                                    }
                            break;
                            case ("R"):
                                    $rootScope.pagoAsyn = false;
                                    if (CompraVm.tiempoExcedido) {
                                      CompraVm.showModalTerminoTiempo();
                                    }else {
                                        showNotif(true, 'alerta', 'modal-azul',message.RECHAZADA);
                                    }
                            break;
                            case ("N"):
                                    $rootScope.pagoAsyn = false;
                                    if (CompraVm.tiempoExcedido) {
                                      CompraVm.showModalTerminoTiempo();
                                    }else {
                                        showNotif(true, 'alerta', 'modal-azul',message.NO_TRANSACCION);
                                    }
                            break;
                            case ("D"):
                                    $rootScope.pagoAsyn = false;
                                    if (CompraVm.tiempoExcedido) {
                                      CompraVm.showModalTerminoTiempo();
                                    }else {
                                        showNotif(true, 'alerta', 'modal-azul',message.DECLINADA);
                                    }
                            break;
                            case ("A"):
                                CompraVm = {};
                                $location.path("/felizViaje").search({success:true, idTransactional:data.transactionId});
                            break;
                            default:

                        }
                        if (!$rootScope.pagoAsyn && CompraVm.tiempoExcedido) {
                          CompraVm.showModalTerminoTiempo();
                          $rootScope.pagoAsyn = false;
                        }
                    }).error(commonFn.errorCommon);
            }

            function paypal(objPago){

            }

            function getAnios(){
                var currentDate = new Date();
                //var year = currentDate.getFullYear();
                var year = 15;
                var arrYears = [];
                for(var y=0; y<=service.YEARS_CARD; y++){
                    arrYears.push(year + y);
                }
                return arrYears;
            }

            function getMonths(){
                return [{
                    "label" : "Enero",
                    "value" : "01"
                },
                {
                    "label" : "Febrero",
                    "value" : "02"
                },
                {
                    "label" : "Marzo",
                    "value" : "03"
                },
                {
                    "label" : "Abril",
                    "value" : "04"
                },
                {
                    "label" : "Mayo",
                    "value" : "05"
                },
                {
                    "label" : "Junio",
                    "value" : "06"
                },
                {
                    "label" : "Julio",
                    "value" : "07"
                },
                {
                    "label" : "Agosto",
                    "value" : "08"
                },
                {
                    "label" : "Septiembre",
                    "value" : "09"
                },
                {
                    "label" : "Octubre",
                    "value" : "10"
                },
                {
                    "label" : "Noviembre",
                    "value" : "11"
                },
                {
                    "label" : "Diciembre",
                    "value" : "12"
                }
            ]
            }

            return service;
        }

})();
