(function(){
    angular.module('ERApp')
        .factory('asientosService', ['$http','$rootScope','commonModal','commonFn',"showNotif",'$q','MenuService', asientosService]);

        function asientosService($http, $rootScope, commonModal, commonFn,showNotif,$q, MenuService){
            //Opciones de componentes de un autobus
            var translate = {
                e : "espacio",
                wc : "wc",
                etv:"etv",
                tv:"tv"
            };

            //"esb/ER-ESB-1.0"
            var service = {};
            service.accionAsientos = accionAsientos;
            service.asientoValidacion = asientoValidacion;
            service.validarPersonas = validarPersonas;

            function getAsientos(objectRequest, modules){

                commonModal.showWait();
//                console.log("*******************AQUI EL OBJETO CORRIDA*******************");
//                console.log(objectRequest);
//                console.log("clave: "+objectRequest.claveCorrida+" destino: "+ objectRequest.destinoCorrida+" origen: "+objectRequest.origenCorrida);
                var asientosBloqueado = $http.post($rootScope.contextoServices+'/webresources/search/buscaAsientos',{"cveCorrida" : objectRequest.claveCorrida,"destinoCorrida" : objectRequest.destinoCorrida,"origen" : objectRequest.origenCorrida});

//                console.log(asientosBloqueado);
//                console.log("Id del asiento:-->"+ objectRequest.plantillaId);

                $q.all([$http.post($rootScope.contextoAdmin+'/REST/ERBUS/autobuses',{"idBus":objectRequest.plantillaId}), asientosBloqueado])
                    .then(function(data1){
                         commonModal.hideWait();
//                         console.log(data1);
                         var asientosServices = data1[1].data.asientosDisponibles;
                         var busModelo= data1[0].data.result.PLANTILLA;//"etv,tv,etv,e,e,e,3,4,1,2,e,7,8,etv,etv,tv,5,6,e,11,12,9,10,e,15,16,13,14,e,19,20,tv,etv,etv,17,18,e,23,24,21,22,e,27,28,25,26,e,31,32,etv,etv,tv,29,30,e,35,36,33,34,e,39,40,37,38,e,41,42,,43,44,wc,e,wc";
//                         console.log("busModel:-->"+busModelo);
                         var arrayModel = busModelo.split(',');
                         var arrAsientosLibres = asientosServices.split(',');

                         var countc = 0;
                         for(var m = 0; m < arrayModel.length; m++){

                                 var asiento = arrayModel[m];
                                 var numAsiento = parseInt(asiento);

                                 if(!isNaN(numAsiento) && arrAsientosLibres.length >= countc){

                                     for(var i=0;i<arrAsientosLibres.length;i++){
                                        if(numAsiento==(i+1)){
                                          try{
                                              var asientoL = arrAsientosLibres[i];
                                              var parseo = asientoL.split('-');
                                              modules.push({title:numAsiento,'l':parseo[0]});
                                              countc++;
                                          }catch(e){
//                                        	  console.log(e);
                                          }
                                        }
                                     }
                                 }else{
                                     var obj = {};
                                     obj[translate[asiento]] = true;
                                     modules.push(obj);
                                 }
                         }

                    });

            }

            //bloqueo selección IDA
            service.lockAsientosIDA = function(CompraVm,paginacion,resumenService,cveCorrida, type, asientos, tipoPasajeros, origen){
            	//alert(asientos+ "---"+ tipoPasajeros);
            	$http.post($rootScope.contextoServices+"/webresources/reserva/bloqueaAsientos",
                {
                    'cveCorrida' : cveCorrida,
                    'type' : type,
                    'asientos' : ","+asientos,
                    'tipoPasajeros' : ","+tipoPasajeros,
                    'origen' : origen
                }
                ).success(function(data){
                	console.log("<------Objeto del servicio------>");
                	console.log(data);
                	if(data.success){
                		if(CompraVm.page == paginacion.RESUMEN_IDA){
                            CompraVm.CorridaSelectResumen.Corrida = CompraVm.CorridaSelectIDA;
                            CompraVm.CorridaSelectResumen.boletos =  CompraVm.boletosIda;
                            CompraVm.CorridaSelectResumen.fecha = CompraVm.dateDeparture;
                            CompraVm.CorridaSelectResumen.selectAsiento = CompraVm.numeroDeAsiento.asientosIDA;
                            CompraVm.CorridaSelectResumen.totalPago = 0;
                            console.log(CompraVm.CorridaSelectResumen);
                            resumenService.accionResumen(CompraVm, "I");
                          }else if(CompraVm.page == paginacion.SELECCIONAR_ASIENTO_REGRESO){
                        	  CompraVm.arrayAsientos=[];
                        	  CompraVm.arrayAsientosRegreso=[];
                        	  CompraVm.numeroDeAsiento.AsientosVuelta='';
                          	CompraVm.totalSeleccionados=0;
                            accionAsientos(CompraVm, "CorridaSelectVUELTA");
                          }
                	}else if(data.success==false){
                    		//Aquiiiiiiiii filtra ida
                            showNotif(true,"alerta","modal-azul", "LO SENTIMOS TUS ASIENTOS NO PUDIERON SER APARTADOS, INTENTA CON OTRA SELECCIÓN.");
                            CompraVm.numeroDeAsiento.asientosIDA="";
                        	CompraVm.page=2;
                            CompraVm.arrayAsientos=[];
                            CompraVm.numeroDeAsiento.asientosIDA='';
                        	CompraVm.totalSeleccionados=0;
                        	accionAsientosConcurrencia(CompraVm, "CorridaSelectIDA");
                            paginacion.paginarNext(CompraVm);

                	}
                }).error(commonFn.errorCommon);
            }

            //bloqueo selección regreso
            service.lockAsientosREGRESO = function(CompraVm,paginacion,resumenService,cveCorrida, type, asientos, tipoPasajeros, origen){
            	//alert(asientos+ "---"+ tipoPasajeros);
            	$http.post($rootScope.contextoServices+"/webresources/reserva/bloqueaAsientos",
                {
                    'cveCorrida' : cveCorrida,
                    'type' : type,
                    'asientos' : ","+asientos,
                    'tipoPasajeros' : ","+tipoPasajeros,
                    'origen' : origen
                }
                ).success(function(data){
                	console.log("<------Objeto del servicio regreso------>");
                	console.log(data);
                	//alert(data.success);
                	if(data.success==true){
                        CompraVm.CorridaSelectResumen.Corrida = CompraVm.CorridaSelectIDA;
                        CompraVm.CorridaSelectResumen.boletos =  CompraVm.boletosIda;
                        CompraVm.CorridaSelectResumen.fecha = CompraVm.dateDeparture;
                        CompraVm.CorridaSelectResumen.selectAsiento = CompraVm.numeroDeAsiento.asientosIDA;
                        resumenService.accionResumen(CompraVm, "I");
                	}else if(data.success==false){
                        showNotif(true,"alerta","modal-azul", "LO SENTIMOS TUS ASIENTOS NO PUDIERON SER APARTADOS, INTENTA CON OTRA SELECCIÓN.");
                        CompraVm.numeroDeAsiento.AsientosVuelta="";
                        CompraVm.page=3;
                        CompraVm.arrayAsientosRegreso=[];
                        CompraVm.numeroDeAsiento.AsientosVuelta='';
                        CompraVm.totalSeleccionados=0;
                        accionAsientosConcurrencia(CompraVm, "CorridaSelectVUELTA");
                        paginacion.paginarNext(CompraVm);
                	}
                }).error(commonFn.errorCommon);
            }

            //Desbloquear asientos
            service.lockAsientosClean = function(CompraVm,paginacion,resumenService,cveCorrida, type, asientos, tipoPasajeros, origen){
            	$http.post($rootScope.contextoServices+"/webresources/reserva/bloqueaAsientos",
                {
                    'cveCorrida' : cveCorrida,
                    'type' : type,
                    'asientos' : ","+asientos,
                    'tipoPasajeros' : ","+tipoPasajeros,
                    'origen' : origen
                }
                ).success(function(data){
                	console.log("<------Objeto del servicio limpiar asientos regreso------>");
                	console.log(data);

                	CompraVm.page=2;
                	CompraVm.totalSeleccionados=0;
                    CompraVm.numeroDeAsiento.asientosIDA="";
                    CompraVm.arrayAsientos=[];
                	CompraVm.arrayAsientosRegreso=[]
                    accionAsientos(CompraVm, "CorridaSelectIDA");
                    paginacion.paginarNext(CompraVm);

                }).error(commonFn.errorCommon);
            }

            service.desLockAsientos = function(CompraVm,cveCorrida, type, asientos, tipoPasajeros, origen, tipoDes){
              commonModal.showWait();
              $http.post($rootScope.contextoServices+"/webresources/reserva/bloqueaAsientos",
                {
                    'cveCorrida' : cveCorrida,
                    'type' : type,
                    'asientos' : ","+asientos,
                    'tipoPasajeros' : ","+tipoPasajeros,
                    'origen' : origen
                }
                ).success(function(data){
                  if (CompraVm.viaje.tipo == 'R'){
                    if (tipoDes === 'R') {
                      MenuService.changePage();
                      commonModal.hideWait();
                    }
                  }else{
                    MenuService.changePage();
                    commonModal.hideWait();
                  }
                }).error(commonFn.errorCommon);
            }

            function accionAsientos(CompraVm, CorridaSelect){
                if(CompraVm[CorridaSelect] === undefined) return;
                CompraVm.arrayAsientos = [];
                CompraVm.autobusCombinado = {modules : []};
                CompraVm.corridaIdaSelected = CompraVm.corridaSelected;
                getAsientos(CompraVm[CorridaSelect], CompraVm.autobusCombinado.modules);
                validarPersonas(CompraVm);
            }
            function accionAsientosConcurrencia(CompraVm, CorridaSelect){
                if(CompraVm[CorridaSelect] === undefined) return;
                CompraVm.arrayAsientos = [];
                CompraVm.autobusCombinado = {modules : []};
                CompraVm.corridaIdaSelected = CompraVm.corridaSelected;
                getAsientos(CompraVm[CorridaSelect], CompraVm.autobusCombinado.modules);
            }

           function asientoValidacion(CompraVm){
              if (CompraVm.totalSeleccionados <CompraVm.maxCompra) {
                showNotif(true,"alerta","modal-azul", "DEBE SELECCIONAR TODOS SUS ASIENTOS.");
                return;
              }
              CompraVm.flagBuy = true;
            }

            function validarPersonas(CompraVm,Compra){
//              console.log("entro validar personas--->");
//              console.log(CompraVm.boletos);
//              console.log("Obj compra");
//              console.log();
              if(CompraVm.boletos.ninos>0){
                showNotif(true,"alerta","modal-azul", "ESTRELLA ROJA RECOMIENDA SENTAR A LOS MENORES A PARTIR DE LA SEGUNDA FILA.");
              }
              CompraVm.flagValidaPersonas = true;
            }

            return service;
        }

})();
