(function(){
	angular.module('ERApp')
	.controller('DireccionClienteController',DireccionClienteController);

	function DireccionClienteController($rootScope,  $http, showNotif, commonModal,$q,commonFn, catalogosService,$window){
		$rootScope.curretOption = "DIRECCIÒN";



		var DireccionClienteVm = this;
		var mexico=156;
		var contexto=$rootScope.contextoServices;
		 DireccionClienteVm.large = $window.innerHeight;
		DireccionClienteVm.llenaFrm = llenaFrm;


		DireccionClienteVm.estadoLista=[];
		DireccionClienteVm.municipioLista=[];


		/* se inicializan controladores para habilitar campos
		o mostrar y ocultar campos */
		DireccionClienteVm.habilitarCampos=true;
		DireccionClienteVm.btnGuardarDireccion=false;
		DireccionClienteVm.mostrarCbCalle=true;
		DireccionClienteVm.nuevo=false;
		DireccionClienteVm.mostrarInputs=false;

		DireccionClienteVm.direccion={
			calle: "",
			ciudad: "",
			clienteId: 0,
			clientesDireccionesId: 0,
			codigoPostal: 0,
			colonia: "",
			direccionPredeterminada: "",
			estado: "",
			noExt: "",
			noInt: "",
			pais: "",
			telefono: "",
			telefonoCelular: "",
			telefonoFax: "",
			telefonoOficina: ""
		}

		function limpiaVarDireccion(){
			DireccionClienteVm.direccion={
				calle: "",
				ciudad: "",
				clienteId: DireccionClienteVm.direccion.clienteId,
				clientesDireccionesId: 0,
				codigoPostal: 0,
				colonia: "",
				direccionPredeterminada: "N",
				estado: "",
				noExt: "",
				noInt: "",
				pais: "",
				telefono: "",
				telefonoCelular: "",
				telefonoFax: "",
				telefonoOficina: ""
			}
		}

		var direccionesCliente;


		//catalogosService.loadCatalogs(DireccionClienteVm);
		/*se llenan las listas de pais , estado y municipio*/
		getMunicipios();
		getPaises();
		getEstados();


		getDireccionCliente();

		/*metodo para obtener la direccion del cliente */
		function getDireccionCliente() {

			console.log("***** getDireccionCliente ****");


			console.log("*** getDireccionCliente post *** ");
			commonModal.showWait();
			$http.post(contexto+'/webresources/client/getDireccionescliente',{"idCliente": $rootScope.userVo.idCliente })
			.success(function(data){

				console.log("*** success *** ");
				commonModal.hideWait();


				console.log("*** data *** ");
				console.log(data);
				console.log(data.success);



				if(data.success && (data.result.direcciones !== undefined) ){
							/*lista de estados y paises */
							DireccionClienteVm.direccionesLista=data.result.direcciones;
							DireccionClienteVm.direccionesListaCombo=data.result.direcciones;
							 /*se selecciona el la direccion correspondiente en el combobox
			   			 	y se llena el formulario con los campos correspondientes
			   			 	a la opcion seleccionada en el ocmbo*/

			   			 	seleccionaCbSelected();
			   			 	DireccionClienteVm.llenaFrm();
			   	  }else if(data.result.direcciones == undefined){
			   	  	console.log(" NO HAY REGISTROS ");

			   	  	for (var indx = 0; indx < DireccionClienteVm.paisesLista.length; indx++) {

							if(DireccionClienteVm.paisesLista[indx].ID_PAIS == mexico){
								DireccionClienteVm.paisSelect=DireccionClienteVm.paisesLista[indx]
								DireccionClienteVm.llenaComboEstado(mexico);
							}
					};

			   	  }
				}).error(commonFn.errorCommon)
		}// fin metodo getDireccionCliente



		function getPaises(){

			console.log("*** getPaises get *** ");
			commonModal.showWait();
			$http.get( $rootScope.contextoAdmin+'/REST/ERBUS/paises',{})
			.success(function(data){

				console.log("*** success *** ");
				commonModal.hideWait();

				if(data.estatus){
					console.log("*** data *** "+data);
					console.log(data);
					DireccionClienteVm.paisesLista=data.result;

				}else{
					console.log("*** error getPaises *** ");

				}



			}).error(commonFn.errorCommon)

		}// fin getPaises


		function getEstados(){

			console.log("*** getEstados get *** ");
			commonModal.showWait();
			$http.get( $rootScope.contextoAdmin+'/REST/ERBUS/estados',{})
			.success(function(data){

				console.log("*** success *** ");
				commonModal.hideWait();

				if(data.estatus){
					console.log("*** data *** "+data);
					console.log(data);
					DireccionClienteVm.estadoLista=data.result;

					getMunicipios(data.result[0].ID_ESTADO);
				}else{
					console.log("*** error getEstados *** ");

				}



			}).error(commonFn.errorCommon)

		}// fin getEstados

		function getMunicipios(ID_ESTADO){
			var defered = $q.defer();
			var promise = defered.promise;

			try {
				console.log("*** getMunicipios get *** "+ID_ESTADO);
				commonModal.showWait();
				$http.get( $rootScope.contextoAdmin+'/REST/ERBUS/municipios/'+ID_ESTADO,{})
				.success(function(data){

					console.log("*** success *** ");
					commonModal.hideWait();

					if(data.estatus){
						console.log("*** data *** "+data);
						console.log(data);
						DireccionClienteVm.municipioLista=data.result;

						$rootScope.permission = data.result;
						defered.resolve($rootScope.permission);
						console.log($rootScope.permission);


					}else{
						console.log("*** error getMunicipios *** ");

					}
				}).error(commonFn.errorCommon)

			}catch (e) {
				defered.reject(e);
			}
			return promise;

		}// fin getEstados



		/* metodo para seleccionar al inicio el option indicado en el combobox selected*/
		function seleccionaCbSelected() {

			console.log("***** seleccionaCbSelected ****");

			for (var i = 0; i < DireccionClienteVm.direccionesLista.length; i++) {


				console.log(DireccionClienteVm.direccionesLista[i].direccionPredeterminada +" pos="+i);
				if(DireccionClienteVm.direccionesLista[i].direccionPredeterminada == 'S'){

					console.log("ES S ");
					DireccionClienteVm.selected=DireccionClienteVm.direccionesLista[i];

					break;
				}else{

					console.log("ES N ");
					DireccionClienteVm.selected=DireccionClienteVm.direccionesLista[0];
				}
			};

		}//fin seleccionaCbSelected

	    /*	metodo que llena el resto del formulario dependiendo
	    lo que se selecciones en el combo DireccionClienteVm.cbCalle */
	    function llenaFrm(){


	    	console.log("***** llenaFrm ****");

	    	for (var i = DireccionClienteVm.direccionesLista.length - 1; i >= 0; i--) {
	    		console.log("Busca direccion seleccionada...");
	    		if(DireccionClienteVm.direccionesLista[i].clientesDireccionesId == DireccionClienteVm.selected.clientesDireccionesId){
	    			DireccionClienteVm.direccion=DireccionClienteVm.direccionesLista[i];

	    			//valida si el check box chkDireccionPredeterminada se debe seleccionar
	    			if(DireccionClienteVm.direccion.direccionPredeterminada == 'S'){
	    				DireccionClienteVm.chkDireccionPredeterminada=true;
	    			}else{
	    				DireccionClienteVm.chkDireccionPredeterminada=false;
	    			}

	    			for (var j = DireccionClienteVm.paisesLista.length - 1; j >= 0; j--) {
	    				console.log("Busca y selecciona pais...");


	    				if(DireccionClienteVm.paisesLista[j].DS_NOMBRE == DireccionClienteVm.direccion.pais){
	    					DireccionClienteVm.paisSelect=DireccionClienteVm.paisesLista[j];

	    					console.log("id Pais..."+DireccionClienteVm.paisesLista[j].ID_PAIS);
	    					if(DireccionClienteVm.paisesLista[j].ID_PAIS != mexico ){
	    						DireccionClienteVm.mostrarInputs=true;
	    					}else{
	    						DireccionClienteVm.mostrarInputs=false;
	    					}

	    					for (var k = DireccionClienteVm.estadoLista.length - 1; k >= 0; k--) {
	    						console.log("Busca y selecciona estado...");
	    						console.log(DireccionClienteVm.estadoLista[k].DS_NOMBRE+" == ?"+DireccionClienteVm.direccion.estado);
	    						if(DireccionClienteVm.estadoLista[k].DS_NOMBRE == DireccionClienteVm.direccion.estado){

	    							DireccionClienteVm.estadoSelect=DireccionClienteVm.estadoLista[k];


	    							var promise = getMunicipios(DireccionClienteVm.estadoLista[k].ID_ESTADO);
	    							promise.then(function(resultado) {

	    								if ( resultado != null  ){
	    									for (var x = 0; x < DireccionClienteVm.municipioLista.length; x++) {
	    										console.log("Busca y selecciona municipio...");
	    										if(DireccionClienteVm.municipioLista[x].DS_NOMBRE == DireccionClienteVm.direccion.ciudad){
	    											DireccionClienteVm.municipioSelect=DireccionClienteVm.municipioLista[x]
	    										}

	    												};//for x

	    											}
	    										}, function(error) { });

	    						}

	    					};//for k
	    				}

	    			};// for j

	    		}
	    	};// for i
	    } //fin llena Frm




	    /* funcion al dar clic en el icono editar , habilita inputs ,
	    	muestra botones guardar y cancelar
	    	y esconde icono y combo calle  */
	    	DireccionClienteVm.editarDireccion = function(){

	    		console.log("***** editarDireccion ****");



	    		console.log(DireccionClienteVm.direccionesLista);

		 	//se verifica si hay un regustro seleccionado en el combo calle
		 	if(DireccionClienteVm.direccionesLista === undefined){

		 		console.log("***** NO HAY REGISTROS ****");



		 	}else{
		 		//se habilitan inputs y se muestran botones de guardar cancelar
		 		DireccionClienteVm.habilitarCampos=false;
		 		DireccionClienteVm.btnGuardarDireccion=true;
		 		DireccionClienteVm.mostrarCbCalle=false;
		 	}

		 }// fin editarDireccion

		 /* funcion boton cancelar */
		 DireccionClienteVm.cancelar = function (){
		 	console.log("***** cancelar ****");
		 	//se des-habilitan inputs y se  ocultan botones de guardar cancelar
		 	limpiaVarDireccion();

		 	DireccionClienteVm.habilitarCampos=true;
		 	DireccionClienteVm.btnGuardarDireccion=false;
		 	DireccionClienteVm.mostrarCbCalle=true;


		 	getDireccionCliente();
		 	seleccionaCbSelected();
		 	DireccionClienteVm.llenaFrm();

		 }// fin funcion boton cancelar

		 /* funcion boton actualizar */
		 DireccionClienteVm.actualizar = function (){

		 	console.log("***** actualizar ****");



		 	if(DireccionClienteVm.nuevo){

		 		console.log("***** actualizar nuevo registro ****");

		 		DireccionClienteVm.direccion.calle=DireccionClienteVm.direccion.calle;

		 		DireccionClienteVm.direccion.clienteId = DireccionClienteVm.direccion.clienteId;
		 		DireccionClienteVm.direccion.clientesDireccionesId = DireccionClienteVm.direccion.clientesDireccionesId ;
		 		DireccionClienteVm.direccion.codigoPostal = DireccionClienteVm.direccion.codigoPostal;
		 		DireccionClienteVm.direccion.colonia = DireccionClienteVm.direccion.colonia;


		 		/* validadndo el checkbox Direccion predeterminada */
		 		if(DireccionClienteVm.chkDireccionPredeterminada){
		 			DireccionClienteVm.direccion.direccionPredeterminada='S';
		 		}else{
		 			DireccionClienteVm.direccion.direccionPredeterminada='N';
		 		}


		 		DireccionClienteVm.direccion.noExt = DireccionClienteVm.direccion.noExt;
		 		DireccionClienteVm.direccion.noInt = DireccionClienteVm.direccion.noExt;
		 		DireccionClienteVm.direccion.pais = DireccionClienteVm.paisSelect.DS_NOMBRE;

		 		if(DireccionClienteVm.paisSelect.ID_PAIS == mexico){

		 			DireccionClienteVm.direccion.estado = DireccionClienteVm.estadoSelect.DS_NOMBRE;
		 			DireccionClienteVm.direccion.ciudad = DireccionClienteVm.municipioSelect.DS_NOMBRE;


		 		}else{
		 			DireccionClienteVm.direccion.estado = DireccionClienteVm.direccion.estado;
		 			DireccionClienteVm.direccion.ciudad = DireccionClienteVm.direccion.ciudad;
		 		}





		 		var objNuevaDireccion={
		 			"direcciones": [{
		 				"codigoPostal": DireccionClienteVm.direccion.codigoPostal,
		 				"direccionPredeterminada": DireccionClienteVm.direccion.direccionPredeterminada,
		 				"ciudad": DireccionClienteVm.direccion.ciudad,
		 				"estado": DireccionClienteVm.direccion.estado,
		 				"pais": DireccionClienteVm.direccion.pais ,
		 				"noInt": DireccionClienteVm.direccion.noInt,
		 				"clienteId":  $rootScope.userVo.idCliente,
		 				"noExt": DireccionClienteVm.direccion.noExt,
		 				"calle": DireccionClienteVm.direccion.calle,
		 				"colonia":  DireccionClienteVm.direccion.colonia

		 			}],

		 			"clienteId":  $rootScope.userVo.idCliente

		 		}


		 		console.log("**** objNuevaDireccion *****");
		 		console.log(objNuevaDireccion);
		 		insertaNuevaDireccion(objNuevaDireccion);


		 	}else{


		 		console.log("***** actualizar update ****");

		 		/* validadndo el checkbox Direccion predeterminada */
		 		if(DireccionClienteVm.chkDireccionPredeterminada){
		 			DireccionClienteVm.direccion.direccionPredeterminada='S';
		 		}else{
		 			DireccionClienteVm.direccion.direccionPredeterminada='N';
		 		}
				 /* valida si selecciono mexico ,
				 para obtener el valor de los combos o de los inputs*/
				 if(DireccionClienteVm.paisSelect.ID_PAIS == mexico){
				 	DireccionClienteVm.direccion.estado = DireccionClienteVm.estadoSelect.DS_NOMBRE;
				 	DireccionClienteVm.direccion.ciudad = DireccionClienteVm.municipioSelect.DS_NOMBRE;
				 }else{
				 	DireccionClienteVm.direccion.estado = DireccionClienteVm.direccion.estado;
				 	DireccionClienteVm.direccion.ciudad = DireccionClienteVm.direccion.ciudad;
				 }

				 var objDireccion={
				 	"codigoPostal": DireccionClienteVm.direccion.codigoPostal ,
				 	"direccionPredeterminada": DireccionClienteVm.direccion.direccionPredeterminada,
				 	"ciudad": DireccionClienteVm.direccion.ciudad,
				 	"clientesDireccionesId": DireccionClienteVm.direccion.clientesDireccionesId ,
				 	"estado": DireccionClienteVm.direccion.estado,
				 	"pais": DireccionClienteVm.paisSelect.DS_NOMBRE,
				 	"noInt": DireccionClienteVm.direccion.noInt,
				 	"clienteId": DireccionClienteVm.direccion.clienteId ,
				 	"noExt": DireccionClienteVm.direccion.noExt ,
				 	"calle": DireccionClienteVm.direccion.calle,
				 	"colonia": DireccionClienteVm.direccion.colonia

				 };


				 actualizarDireccion(objDireccion);
		}// fin else

}// fin funcion boton actualizar





/* funcion boton nuvaDireccion */
DireccionClienteVm.nuevaDireccion = function (){


	console.log("***** nuevaDireccion ****");

	/* se inicializa los parametros a actualizar */
	limpiaVarDireccion();

	DireccionClienteVm.chkDireccionPredeterminada=false;


	console.log("***** antes del for ****");

	for (var indx = 0; indx < DireccionClienteVm.paisesLista.length; indx++) {

		if(DireccionClienteVm.paisesLista[indx].ID_PAIS == mexico){
			DireccionClienteVm.paisSelect=DireccionClienteVm.paisesLista[indx]
			DireccionClienteVm.llenaComboEstado(mexico);
		}
	};

				//se des-habilitan inputs y se  ocultan botones de guardar cancelar
				DireccionClienteVm.nuevo=true;
				DireccionClienteVm.habilitarCampos=false;
				DireccionClienteVm.btnGuardarDireccion=true;
				DireccionClienteVm.mostrarCbCalle=false;

}// fin funcion boton nuvaDireccion


		 /* funcion para llenar el combo estado
		 dependiendo el pais que se selecciones */
		 DireccionClienteVm.llenaComboEstado = function (ID_PAIS){

		 	console.log("***** llenaComboEstado ****");
		 	console.log(ID_PAIS);

		 	DireccionClienteVm.estadoLista=[];


		 	// valida que se va a mostrar  inputs o combos
		 	if(ID_PAIS != mexico){
		 		DireccionClienteVm.mostrarInputs=true;
		 		DireccionClienteVm.direccion.estado="";
		 		DireccionClienteVm.direccion.ciudad="";
		 	}else if(ID_PAIS == mexico){
		 		DireccionClienteVm.mostrarInputs=false;
		 	}


		 	for (var i = 0; i < estados.length; i++) {

		 		console.log(estados[i].ID_PAIS +"=="+ ID_PAIS);
		 		if(estados[i].ID_PAIS == ID_PAIS){
		 			DireccionClienteVm.estadoLista.push(estados[i]);
		 		}
		 	};


		 	DireccionClienteVm.estadoSelect=estados[0];
		 	DireccionClienteVm.llenaComboMunicipio(estados[0].ID_ESTADO);

		 }// fin funcion llenaComboEstado



		  /* funcion para llenar el combo estado
		  dependiendo el pais que se selecciones */
		  DireccionClienteVm.llenaComboMunicipio = function  (ID_ESTADO) {
		  	console.log("***LLENA COMBO MUNICIPIO ****");
		  	console.log(ID_ESTADO);



		  	var promise = getMunicipios(ID_ESTADO);
		  	promise.then(function(resultado) {

		  		if(resultado != null ){
		  			DireccionClienteVm.municipioSelect=DireccionClienteVm.municipioLista[0];
		  		}


		  	}, function(error) { });


		  }


		  function actualizarDireccion(obj){

		  	console.log("*** ACTUALIZAR DIRECCION POST *** ");

		  	console.log(obj);


		  	commonModal.showWait();
		  	$http.post(contexto+'/webresources/client/modificaDireccionCliente',obj)
		  	.success(function(data){
		  		console.log("*** success *** ");
		  		commonModal.hideWait();

		  		console.log("*** data *** "+data.result.operacionExitosa);
		  		if(data.result.operacionExitosa){

		  			showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());
		  			DireccionClienteVm.habilitarCampos=true;
		  			DireccionClienteVm.btnGuardarDireccion=false;
		  			DireccionClienteVm.mostrarCbCalle=true;
		  			DireccionClienteVm.nuevo=false;
		  			getDireccionCliente();

		  		}else{
		  			showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());
		  			console.log(" ACTUALIZAR DIRECCION postO currio un error");
		  		}
		  	}).error(commonFn.errorCommon)

		 }// fin actualizarDireccion

		 function insertaNuevaDireccion(obj){

		 	console.log("*** INSERTA NUEVA DIRECCION  POST *** ");

		 	console.log(obj);



		 	commonModal.showWait();
		 	$http.post(contexto+'/webresources/client/addDireccionCliente',obj)
		 	.success(function(data){
		 		console.log("*** success *** ");
		 		commonModal.hideWait();

		 		console.log("*** data *** "+data.result.operacionExitosa);
		 		if(data.result.operacionExitosa){


		 			console.log("*** INSERCCION EXITOSA *** ");

		 			console.log(data);


		 			showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());

		 			DireccionClienteVm.habilitarCampos=true;
		 			DireccionClienteVm.btnGuardarDireccion=false;
		 			DireccionClienteVm.mostrarCbCalle=true;
		 			DireccionClienteVm.nuevo=false;
		 					// llama al metodo para volver a cargar el formulario
		 					getDireccionCliente();


		 				}else{
		 					showNotif(true,"informacion","modal-azul",data.result.errorMsg.toUpperCase());
		 					console.log(" insertaNuevaDireccion postO currio un error");
		 				}
		 			}).error(commonFn.errorCommon)

		 }// fin insertaNuevaDireccion





		/*funcion que oculta el boton del footer cuando el usuario
		 empieza a capturar datos con el movil*/

		  angular.element($window).bind('resize', function(e) {
            console.log("Inicial :" + DireccionClienteVm.large);
            console.log("Cambios  :" + $window.innerHeight);
          if(parseInt(DireccionClienteVm.large) > parseInt($window.innerHeight)){

               console.log("--- SE OCULTA BOTON ---" );
              angular.element(document.getElementById('validate')).removeClass( "mostrarBtn" ).addClass( "ocultarBtn" );

          }else{

          	console.log("--- SE MUESTRA BOTON ---" );
            angular.element(document.getElementById('validate')).removeClass( "ocultarBtn" ).addClass( "mostrarBtn" );

          }

         })



	}// fin funcion DireccionClienteController

})();
