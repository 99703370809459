var estados = [
      {
         "ID_ESTADO": "1",
         "ID_PAIS": "156",
         "DS_NOMBRE": "AGUASCALIENTES",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "2",
         "ID_PAIS": "156",
         "DS_NOMBRE": "BAJA CALIFORNIA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "3",
         "ID_PAIS": "156",
         "DS_NOMBRE": "BAJA CALIFORNIA SUR",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "4",
         "ID_PAIS": "156",
         "DS_NOMBRE": "CAMPECHE",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "7",
         "ID_PAIS": "156",
         "DS_NOMBRE": "CHIAPAS",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "8",
         "ID_PAIS": "156",
         "DS_NOMBRE": "CHIHUAHUA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "5",
         "ID_PAIS": "156",
         "DS_NOMBRE": "COAHUILA DE ZARAGOZA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "6",
         "ID_PAIS": "156",
         "DS_NOMBRE": "COLIMA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "9",
         "ID_PAIS": "156",
         "DS_NOMBRE": "DISTRITO FEDERAL",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "10",
         "ID_PAIS": "156",
         "DS_NOMBRE": "DURANGO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "11",
         "ID_PAIS": "156",
         "DS_NOMBRE": "GUANAJUATO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "12",
         "ID_PAIS": "156",
         "DS_NOMBRE": "GUERRERO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "13",
         "ID_PAIS": "156",
         "DS_NOMBRE": "HIDALGO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "14",
         "ID_PAIS": "156",
         "DS_NOMBRE": "JALISCO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "15",
         "ID_PAIS": "156",
         "DS_NOMBRE": "MEXICO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "16",
         "ID_PAIS": "156",
         "DS_NOMBRE": "MICHOACAN DE OCAMPO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "17",
         "ID_PAIS": "156",
         "DS_NOMBRE": "MORELOS",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "18",
         "ID_PAIS": "156",
         "DS_NOMBRE": "NAYARIT",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "19",
         "ID_PAIS": "156",
         "DS_NOMBRE": "NUEVO LEON",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "20",
         "ID_PAIS": "156",
         "DS_NOMBRE": "OAXACA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "21",
         "ID_PAIS": "156",
         "DS_NOMBRE": "PUEBLA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "22",
         "ID_PAIS": "156",
         "DS_NOMBRE": "QUERETARO DE ARTEAGA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "23",
         "ID_PAIS": "156",
         "DS_NOMBRE": "QUINTANA ROO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "24",
         "ID_PAIS": "156",
         "DS_NOMBRE": "SAN LUIS POTOSI",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "25",
         "ID_PAIS": "156",
         "DS_NOMBRE": "SINALOA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "26",
         "ID_PAIS": "156",
         "DS_NOMBRE": "SONORA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "27",
         "ID_PAIS": "156",
         "DS_NOMBRE": "TABASCO",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "28",
         "ID_PAIS": "156",
         "DS_NOMBRE": "TAMAULIPAS",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "29",
         "ID_PAIS": "156",
         "DS_NOMBRE": "TLAXCALA",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "30",
         "ID_PAIS": "156",
         "DS_NOMBRE": "VERACRUZ DE IGNACIO DE LA LLAVE",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "31",
         "ID_PAIS": "156",
         "DS_NOMBRE": "YUCATAN",
         "estatus": "A"
      },
      {
         "ID_ESTADO": "32",
         "ID_PAIS": "156",
         "DS_NOMBRE": "ZACATECAS",
         "estatus": "A"
      }
   ];