(function(){
    angular.module('ERApp')
        .controller('FacturadosController',FacturadosController);

    function FacturadosController($rootScope, $http, showNotif, commonModal, commonFn){
        $rootScope.curretOption = "VIAJES FACTURADOS";
        var FacturadosVm = this;
        FacturadosVm.getFacturados = getFacturados;
        FacturadosVm.viewFactura = viewFactura;
        FacturadosVm.userId = $rootScope.userVo.idCliente;

        FacturadosVm.buscarData = {
          "inicio":"1",
          "fin" :"100000",
          "idCliente" : $rootScope.userVo.idCliente,
          "tipo" : "SF"
        }

        //Initial function
        getFacturados();

        function getFacturados(){
          var boletosArray = [];
          commonModal.showWait();
          $http.post($rootScope.contextoServices+'/webresources/viajes/getMoreViajes',
            FacturadosVm.buscarData).success(function(data){
              console.log("data");
              console.log(data);
              if(data === undefined){
                console.log("Undefined Lista de boletos");
                commonFn.errorCommon;
              }else{
              FacturadosVm.peticion = data;
              console.log("Numero de boletos");
              console.log(FacturadosVm.peticion.result.length);
              for(var i=0; i<FacturadosVm.peticion.result.length;i++){
                var data = FacturadosVm.peticion.result[i];
                for(var j=0;j<data.boletos.length;j++){
                  boletosArray.push(data.boletos[j]);
                }
              }
              FacturadosVm.datosBoletos = boletosArray;
              commonModal.hideWait();
            }
          }).error(commonFn.errorCommon);
          console.log(boletosArray);
        }

        function viewFactura(folioFactura){
          console.log("::::folioFactura::::");
          console.log(folioFactura);
          console.log($rootScope.userVo.idCliente);
          FacturadosVm.verFactura = {
            "idClient": $rootScope.userVo.idCliente,
	          "folio": folioFactura
          };
          console.log($rootScope.contextoServices+"/muestraFacturaPdf")
          $http.get($rootScope.contextoServices+'/muestraFacturaPdf',
          FacturadosVm.verFactura).success(function(data){
            console.log(data);
          });
        }
    } //FIN DEL FUNCTIONCONTROLLER
})();
