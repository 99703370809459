(function(){
    angular.module('ERApp')
        .factory('showNotif', ['$window','$rootScope', showNotif]);
    function showNotif(win,$rootScope){
          return function(modalMessage,classIcon,claseModal,message){
            $rootScope.classIcon = classIcon;
            $rootScope.claseModal = claseModal;
            $rootScope.modalMessage = modalMessage;
            $rootScope.message = message;
        }
    }
})();
