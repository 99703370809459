(function(){
    angular.module('ERApp')
    .controller('MenuController', MenuController)

    function MenuController(
        $rootScope,$window, $route, showNotif, MenuService
      ){
        var MenuVm = this;

        MenuVm.opcionMenu = opcionMenu;

        function opcionMenu (menuopc){
          MenuService.pagina = menuopc;
          if ($rootScope.page > 3 && $rootScope.page < 9) {
            showModal();
          }else{
            MenuService.changePage();
          }
          // $location.path(MenuVm.paginacion[menuopc].url);
        }
        function showModal(){
          console.log($rootScope.modalMessageDesbloqueo);
          $rootScope.classIcon = "informacion";
          $rootScope.claseModal = "modal-azul";
          $rootScope.modalMessageDesbloqueo = true;
          $rootScope.message = "ESTAS ABANDONANDO TU COMPRA Y TUS ASIENTOS SERÁN DESBLOQUEADOS, ¿DESEAS CONTINUAR?";
        }
      };
    })();
