(function(){
    angular.module('ERApp')
    .controller('ResumenCompraController', ResumenCompraController)

    function ResumenCompraController($rootScope, $http, showNotif){
        $rootScope.curretOption = "";
        $rootScope.headCompra = "";
        $rootScope.classCompra = "seccionCompra";
        var ResumenVm = this;
        ResumenVm.titulo = "RESUMEN DE COMPRA";
    }//FIN DEL FUNCTIONCONTROLLER

})();
