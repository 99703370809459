(function(){

    angular.module("ERApp")
        .factory('corridasService', ['$http','commonModal','commonFn','showNotif','$rootScope', corridasService]);

        function corridasService($http, commonModal, commonFn, showNotif,$rootScope){

            var service = {};
            //PROPIEDADES DEL SERVICIO
            service.URL_CORRIDAS = $rootScope.contextoServices+'/webresources/search/corridas';
            service.EXTERNAS_CORRIDAS = false;
            //METODOS DISPONIBLES
            // service.getCorridas = getCorridas;
            service.formarCorrida = formarCorrida;
            // service.cabeceraAsientos = cabeceraAsientos;
            // service.searchCorrida = searchCorrida;
            service.corridaValidacion = corridaValidacion;
            service.accionCorrida = accionCorrida;
            service.fillObjCompra = fillObjCompra;
            service.validaCorrida = validaCorrida;
            service.formarBackCorrida = formarBackCorrida;
            var message = {
                MENORES_MENSAJE : '1. Al abordar el autobús se deberá presentar el acta de nacimiento de cada niño y deberá estar acompañado de un adulto. 2. Toda persona con descuento INAPAM deberá presentar su credencial para poder abordar el autobús.',
            }

            function accionCorrida(CompraVm) {
              //  console.log(CompraVm);
              var papa = true;
//                console.log("Origen: " + CompraVm.viaje.origen + " Destino: " + CompraVm.viaje.destino);
              if(CompraVm.page == 2){
//                console.log("Entro pagina 2 corridas Origen: " + CompraVm.viaje.cveOrigen + " Destino: " + CompraVm.viaje.cveDestino);
//                console.log(CompraVm.dateDeparture);
//                console.log(CompraVm.dateReturn);

                var origen = CompraVm.viaje.cveOrigen;
                var destino = CompraVm.viaje.cveDestino;
                CompraVm.fechaElegida = CompraVm.dateReturn;
                CompraVm.viaje.cveDestino = origen;
                CompraVm.viaje.cveOrigen = destino;

                if (CompraVm.dateDeparture.getTime() === CompraVm.dateReturn.getTime()) {
                    CompraVm.viaje.fecha = (CompraVm.dateDeparture.getFullYear()) + "-" + ((CompraVm.dateDeparture.getMonth() + 1) < 10 ? '0' + (CompraVm.dateDeparture.getMonth()+1) : '' + (CompraVm.dateDeparture.getMonth()+1)) + "-" + (CompraVm.dateDeparture.getDate() < 10 ? '0' + CompraVm.dateDeparture.getDate() : '' + CompraVm.dateDeparture.getDate());
                    CompraVm.viaje.fecha = CompraVm.viaje.fecha + "T" + pad(CompraVm.CorridaSelectIDA.horaLLegada.hour,2) + ":" + pad(CompraVm.CorridaSelectIDA.horaLLegada.minute,2) + ":" + pad(CompraVm.CorridaSelectIDA.horaLLegada.second,2);
                if (!(CompraVm.CorridaSelectIDA.horaLLegada.hour > CompraVm.CorridaSelectIDA.horaCorrida.hour)) {
                  CompraVm.corridaExt = 2;
                  papa = false;
                }
                }else {
                  CompraVm.viaje.fecha = (CompraVm.dateReturn.getFullYear()) + "-" + ((CompraVm.dateReturn.getMonth() + 1) < 10 ? '0' + (CompraVm.dateReturn.getMonth()+1) : '' + (CompraVm.dateReturn.getMonth()+1)) + "-" + (CompraVm.dateReturn.getDate() < 10 ? '0' + CompraVm.dateReturn.getDate() : '' + CompraVm.dateReturn.getDate());
//                  console.log("fecha diferente dia");
//                  console.log(CompraVm.viaje.fecha);
                }
                CompraVm.viaje.regreso = "1";
              }else {
                CompraVm.fechaElegida = CompraVm.dateDeparture;
                CompraVm.viaje.fecha = (CompraVm.dateDeparture.getFullYear()) + "-" + ((CompraVm.dateDeparture.getMonth() + 1) < 10 ? '0' + (CompraVm.dateDeparture.getMonth()+1) : '' + (CompraVm.dateDeparture.getMonth()+1)) + "-" + (CompraVm.dateDeparture.getDate() < 10 ? '0' + CompraVm.dateDeparture.getDate() : '' + CompraVm.dateDeparture.getDate());
                CompraVm.viaje.regreso = "";
              }
              cabeceraAsientos(CompraVm);
              if (papa) {
                getCorridas(CompraVm);
              }
            }


            function cabeceraAsientos(CompraVm) {
                CompraVm.headAdulto ="";
                CompraVm.headNino ="";
                if(CompraVm.page == 2){
                  CompraVm.headAdulto = (CompraVm.boletosVuelta.adultos + CompraVm.boletosVuelta.inapam)+ " Adultos " + CompraVm.headAdulto + "("+ CompraVm.boletosVuelta.inapam+ " INAPAM)";
                }else {
                  CompraVm.headAdulto = (CompraVm.boletosIda.adultos + CompraVm.boletosIda.inapam)+ " Adultos " + CompraVm.headAdulto + "("+ CompraVm.boletosIda.inapam+ " INAPAM)";
                }
                CompraVm.headNino = CompraVm.boletos.ninos+ " Menores";
            }

            //DEFINICION DE FUNCIONES

            function fillObjCompra(CompraObj, corridaObj){
                CompraObj.origenCorrida = corridaObj.origenCorrida;
                //CompraObj.fecha = corridaObj.fechaCorrida.year + "-" + corridaObj.fechaCorrida.month + "-" + corridaObj.fechaCorrida.day;
                CompraObj.fecha = corridaObj.fechaCorrida.year + "-" + (corridaObj.fechaCorrida.month < 10 ? '0' + corridaObj.fechaCorrida.month : '' + corridaObj.fechaCorrida.month) + "-" + (corridaObj.fechaCorrida.day < 10 ? '0' + corridaObj.fechaCorrida.day : '' + corridaObj.fechaCorrida.day);
                CompraObj.idCorrida = corridaObj.claveCorrida;
                CompraObj.origenSolicitado = corridaObj.origenSolicitado;
                CompraObj.destinoSolicitado = corridaObj.destinoSolicitado;
                CompraObj.destinoCorrida = corridaObj.destinoCorrida;
                CompraObj.servicioNombre = corridaObj.servicioNombre;
                CompraObj.servicio = corridaObj.servicio;
            }

            function getCorridas(CompraVm){
                commonModal.showWait();
              //   console.log("Objeto viaje");
              //  console.log(CompraVm.viaje);
               CompraVm.Corridas = new Array();
                  $http.post(
                      service.URL_CORRIDAS,
                     CompraVm.viaje
                    )
                        .success(function(data){
                          // console.log("Datos Corridas");
                          // console.log(data);
                            if(data.operacionExitosa){
                              if (data.corridas != undefined) {
                                if(data.corridas.length === 0){
                                    showNotif(true,"alerta","modal-azul", data.errorMsg);
                                    CompraVm.page = 0;
                                }else{
                                  if(CompraVm.page == 2){
                                    CompraVm.CorridaVUELTA = data.corridas;
//                                    console.log("corridas vuelta");
//                                    console.log(CompraVm.CorridaVUELTA);
                                  }else {
                                    CompraVm.CorridaIDA = data.corridas;
//                                    console.log("corridas ida");
//                                    console.log(CompraVm.CorridaIDA);
                                  }
                                    CompraVm.corridaExt = 1;
                                    formarCorrida(CompraVm);
                                }
                              }else{
                                showNotif(true,"alerta","modal-azul", data.errorMsg);
                                backNoneCorrida(CompraVm);
                              }
                            }else{
                              showNotif(true,"alerta","modal-azul", data.errorMsg);
                              backNoneCorrida(CompraVm);
                            }
                            commonModal.hideWait();
                        }).error(commonFn.errorCommon);
            }

            function backNoneCorrida(CompraVm){
              CompraVm.page = 0;
              $rootScope.page = CompraVm.page;
              CompraVm.botonReturn = "btn-registro";
              CompraVm.corridaExt = 1;
              $rootScope.relojMenuOpc = true;
            }
             function formarCorrida(CompraVm){

              var contar= 0;
              var total = 0;
                CompraVm.Corridas = new Array();
                CompraVm.CorridasE = new Array();
                if(CompraVm.page == 2){
                  CompraVm.fechaElegida = CompraVm.dateReturn;
                  for (var corridaVUELTA in CompraVm.CorridaVUELTA) {

                      var vueltaCorrida = CompraVm.CorridaVUELTA[corridaVUELTA];

                      var hora = hourFormat(vueltaCorrida.fechaHoraSalida.hour,vueltaCorrida.fechaHoraSalida.minute);
                      var res = vueltaCorrida.tarifaSencillo.split(",");

                      vueltaCorrida.tarifaRedondo= "$"+res[0];
                      vueltaCorrida.hora = hora;
                      vueltaCorrida.operador = vueltaCorrida.claveCorrida+""+vueltaCorrida.destinoSolicitado+""+vueltaCorrida.hora;

                      if (vueltaCorrida.tipoCorrida === 'E') {
                        CompraVm.CorridasE.push(vueltaCorrida);
                        contar++;
                      }else{
                        CompraVm.Corridas.push(vueltaCorrida);
                      }
                  }
                  total = CompraVm.CorridaVUELTA.length;
                }else {
                  CompraVm.fechaElegida = CompraVm.dateDeparture;
                  for (var corridaIDA in CompraVm.CorridaIDA) {

                      var idaCorrida = CompraVm.CorridaIDA[corridaIDA];

                      var hora = hourFormat(idaCorrida.fechaHoraSalida.hour,idaCorrida.fechaHoraSalida.minute);
                      var res = idaCorrida.tarifaSencillo.split(",");

                      idaCorrida.tarifaRedondo= "$"+res[0];
                      idaCorrida.hora = hora;
                      idaCorrida.operador = idaCorrida.claveCorrida+""+idaCorrida.destinoSolicitado+""+idaCorrida.hora;

                      if (idaCorrida.tipoCorrida === 'E') {
                        CompraVm.CorridasE.push(idaCorrida);
                        contar++;
                      }else{
                        CompraVm.Corridas.push(idaCorrida);
                      }
                  }
                  total = CompraVm.CorridaIDA.length;
                }
                // console.log("Contar:"+contar+" Total"+total);
                if (contar === total) {
                  CompraVm.corridaExt = 0;
                  service.EXTERNAS_CORRIDAS = true;
                  CompraVm.paginacion[CompraVm.page].bntDescripcion = "SIGUIENTE";
                }else{
                  service.EXTERNAS_CORRIDAS = false;
                  if (CompraVm.boletos.inapam > 0 || CompraVm.boletos.ninos > 0) {
                    showNotif(true,"","modal-azul", message.MENORES_MENSAJE);
                  }
                }
            }


            function formarBackCorrida(CompraVm){
             var contar= 0;
             var total = 0;
               CompraVm.Corridas = new Array();
               CompraVm.CorridasE = new Array();
                 for (var corridaBACK in CompraVm.CorridasBack) {

                     var backCorrida = CompraVm.CorridasBack[corridaBACK];

                     var hora = hourFormat(backCorrida.fechaHoraSalida.hour,backCorrida.fechaHoraSalida.minute);
                     var res = backCorrida.tarifaSencillo.split(",");

                     backCorrida.tarifaRedondo= "$"+res[0];
                     backCorrida.hora = hora;
                     backCorrida.operador = backCorrida.claveCorrida+""+backCorrida.destinoSolicitado+""+backCorrida.hora;

                     if (backCorrida.tipoCorrida === 'E') {
                       CompraVm.CorridasE.push(backCorrida);
                       contar++;
                     }else{
                       CompraVm.Corridas.push(backCorrida);
                     }
                 total = CompraVm.CorridasBack.length;
               }
               if (contar === total) {
                 CompraVm.Corridas = CompraVm.CorridasE;
               }
           }

            function hourFormat(hora, minutos){
                return pad(hora,2) + ":" + pad(minutos,2);
            }

            function pad(num, size) {
                var s = num+"";
                while (s.length < size) s = "0" + s;
                return s;
            }

            function corridaValidacion(CompraVm){

              if(CompraVm.corridaExt === 0){
//                console.log("Corridas exte");
//                console.log(CompraVm.CorridasE);
//                console.log("Corridas");
//                console.log(CompraVm.Corridas);
                if (CompraVm.page == 2) {
                  CompraVm.Corridas = CompraVm.CorridaVUELTA;
                }else{
                  CompraVm.Corridas = CompraVm.CorridasE;
                }

                CompraVm.paginacion[CompraVm.page].bntDescripcion = "SIGUIENTE";
                CompraVm.corridaExt = 1;
              }else if (CompraVm.corridaExt === 1) {
//                console.log("Valor corridas Ext: "+service.EXTERNAS_CORRIDAS);
                if (CompraVm.corridaSelected === "" || (CompraVm.page == 2 && CompraVm.corridaSelected  === "")) {
                  showNotif(true,"alerta","modal-azul", "DEBE SELECCIONAR UNA CORRIDA PARA CONTINUAR");
                  return;
                }
                CompraVm.flagBuy = true;
              }
              if (CompraVm.boletos.inapam > 0 || CompraVm.boletos.ninos > 0) {
                showNotif(true,"","modal-azul", message.MENORES_MENSAJE);
              }
            }

            function validaCorrida(CompraVm, cveCorridaSelected){
              searchCorrida(CompraVm, cveCorridaSelected);
              var respuesta = true;
              if(service.EXTERNAS_CORRIDAS){
                showNotif(true,"alerta","modal-azul", "RECUERDA QUE SELECCIONASTE UNA UBICACIÓN DISTINTA A TU BÚSQUEDA.");
              }
              if (CompraVm.maxCompra <= CompraVm.corridaSelected.pasajerosDisponibles) {
                if (CompraVm.page == 2) {
                  CompraVm.CorridaSelectVUELTA = CompraVm.corridaSelected;
                }else {
                  CompraVm.CorridaSelectIDA = CompraVm.corridaSelected;
                }
              }else {
                showNotif(true,"alerta","modal-azul", "LO SENTIMOS, EL NÚMERO DE PASAJEROS QUE INDICÓ ES MAYOR A LOS ASIENTOS DISPONIBLES, VUELVA A SELECCIONAR OTRO HORARIO");
                var respuesta = false;
              }
              if (CompraVm.boletos.inapam > CompraVm.corridaSelected.insenDisponibles) {
                  showNotif(true,"alerta","modal-azul", "SU BOLETO SERÁ TOMADO COMO ADULTO.");
                  if (CompraVm.page == 2) {
                    CompraVm.boletosVuelta.inapam = CompraVm.corridaSelected.insenDisponibles;
                    CompraVm.boletosVuelta.adultos = CompraVm.boletos.adultos + CompraVm.boletos.inapam - CompraVm.corridaSelected.insenDisponibles;
                  }else {
                    CompraVm.boletosIda.inapam = CompraVm.corridaSelected.insenDisponibles;
                    CompraVm.boletosIda.adultos = CompraVm.boletos.adultos + CompraVm.boletos.inapam - CompraVm.corridaSelected.insenDisponibles;
                  }
                  console.log("nuevo cambio");
                  console.log(CompraVm.boletosIda);
              }else{
                if (CompraVm.page == 2) {
                  CompraVm.boletosVuelta = angular.copy(CompraVm.boletos);
                }else {
                  CompraVm.boletosIda = angular.copy(CompraVm.boletos);
                }
              }
              cabeceraAsientos(CompraVm);
              return respuesta;
            }

            function searchCorrida(CompraVm, cveCorridaSelected){
              if (CompraVm.page === 2) {
                for (var corridaVUELTA in CompraVm.CorridaVUELTA) {
                    var vueltaCorrida = CompraVm.CorridaVUELTA[corridaVUELTA];
                    if (vueltaCorrida.operador === cveCorridaSelected) {
                      CompraVm.corridaSelected = vueltaCorrida ;
                      break;
                    }
                }
              }else{
                for (var corridaIDA in CompraVm.CorridaIDA) {
                    var idaCorrida = CompraVm.CorridaIDA[corridaIDA];
                    if (idaCorrida.operador === cveCorridaSelected) {
                      CompraVm.corridaSelected = idaCorrida ;
                      break;
                    }
                }
              }
            }

            return service;

        }

})();
