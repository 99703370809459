var municipios=[
      {
         "ID_MUNICIPIO": "1",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "1",
         "DS_NOMBRE": "AGUASCALIENTES",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "2",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "2",
         "DS_NOMBRE": "ASIENTOS",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "3",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "3",
         "DS_NOMBRE": "CALVILLO",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "4",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "4",
         "DS_NOMBRE": "COSIO",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "10",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "10",
         "DS_NOMBRE": "EL LLANO",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "5",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "5",
         "DS_NOMBRE": "JESUS MARIA",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "6",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "6",
         "DS_NOMBRE": "PABELLON DE ARTEAGA",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "7",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "7",
         "DS_NOMBRE": "RINCON DE ROMOS",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "11",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "11",
         "DS_NOMBRE": "SAN FRANCISCO DE LOS ROMO",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "8",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "8",
         "DS_NOMBRE": "SAN JOSE DE GRACIA",
         "estatus": "A"
      },
      {
         "ID_MUNICIPIO": "9",
         "ID_ESTADO": "1",
         "ID_CODIGO_MUNICIPIO": "9",
         "DS_NOMBRE": "TEPEZALA",
         "estatus": "A"
      }
   ];