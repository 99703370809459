(function(){
    angular.module('ERApp')
        .controller('EditarViajeController',EditarViajeController);

    function EditarViajeController($rootScope,$window,$http,$filter,commonModal,commonFn,showNotif){
        $rootScope.curretOption = "VIAJES FUTUROS";

        var EditarBoletoVm = this;
        EditarBoletoVm.loadDestinos = loadDestinos;
        EditarBoletoVm.loadOrigen = loadOrigen;
        EditarBoletoVm.validarCancelacion = validarCancelacion;
        EditarBoletoVm.sendCambioBoleto = sendCambioBoleto;
        EditarBoletoVm.mostrar = mostrar;
        EditarBoletoVm.ocultarModal = ocultarModal;
        EditarBoletoVm.showModal = showModal;
        EditarBoletoVm.back = back;
        EditarBoletoVm.viaje ={};
        EditarBoletoVm.ocultar = false;
        EditarBoletoVm.showError = false;
        EditarBoletoVm.initcarga = true;
        EditarBoletoVm.loadOrigen();
        EditarBoletoVm.uniqueTicket = $rootScope.boleto;
        EditarBoletoVm.show = false;
        console.log("Boleto en editar viaje");
        console.log($rootScope.boleto);

        if ($rootScope.boleto === null){
          $window.location.href = "#/viajesFuturos";
        }


        function mostrar(){
                  $rootScope.classIcon = "informacion";
                  $rootScope.claseModal = "modal-azul";
                  EditarBoletoVm.show = true;

        }


        function ocultarModal(){
                  EditarBoletoVm.show = false;
        }

     		// $scope.vigencia = $estatusRol[0];

  //      EditarBoletoVm.fechaHora = EditarBoletoVm.uniqueTicket.boletos[0].fechaViaje +" "+ EditarBoletoVm.uniqueTicket.boletos[0].horaViaje;


          function showModal(){
              $rootScope.classIcon = "alerta";
              $rootScope.claseModal = "modal-azul";
              $rootScope.modalMessage = true;
              $rootScope.message =EditarBoletoVm.validEdit.result.msj;
          }




         function sendCambioBoleto(){
           $rootScope.cambioHorario = true;
           $window.location.href = "#/";
         }
         $rootScope.$on('$routeChangeStart',function(angularEvent,next,current) {
         //do you work here
           if (EditarBoletoVm.ocultar){
             EditarBoletoVm.ocultar = false;
             angularEvent.defaultPrevented = true;
           }

         });

         function validarCancelacion(){
           commonModal.showWait();
           $http.post($rootScope.contextoServices+'/webresources/viajes/validaCambioHorario',
           {
             asiento:EditarBoletoVm.uniqueTicket.noAsiento,
               folio: EditarBoletoVm.uniqueTicket.folio,
               tipoValidacion: "T",
               origen: EditarBoletoVm.uniqueTicket.origen,}
            )
             .success(function(data){
              commonModal.hideWait();
              EditarBoletoVm.validEdit = data;
              if (!EditarBoletoVm.validEdit.result.validCancel){
                 showNotif(
                  true,
                  'alerta',
                  'modal-azul',
                  EditarBoletoVm.validEdit.result.msj
                )

                  EditarBoletoVm.showError = false;
                }else {

                   $rootScope.boleto.cargo = EditarBoletoVm.validEdit.result.importeCargo;
                   $rootScope.boleto.newFecha =EditarBoletoVm.FechaIda;
                   $rootScope.boleto.newOrigen =EditarBoletoVm.viaje.origen;
                   $rootScope.boleto.newDestino = EditarBoletoVm.viaje.destino;
                   EditarBoletoVm.showError = true;
                   EditarBoletoVm.message = 'El Boleto es Válido para cambio, Al aceptar el cambio se Hará un cargo de : $' +
                   EditarBoletoVm.validEdit.result.importeCargo+".00";
                   mostrar();

                }
           })
           .error(commonFn.errorCommon);


         }

        function back(){
             $window.location.href = "#/viajesFuturos";
        }


        function loadDestinos(){
          if(EditarBoletoVm.initcarga)
           {
             EditarBoletoVm.viaje.origen = EditarBoletoVm.uniqueTicket.origen;

           }
        //
            console.log('loadDestinos ' + EditarBoletoVm.viaje.origen);
            if(EditarBoletoVm.viaje.origen === ''){return;}
            for(var i=0; i<EditarBoletoVm.origenDestinoList.length; i++){
                var auxOrigen = EditarBoletoVm.origenDestinoList[i];
                if(auxOrigen !== undefined && auxOrigen !== null){
                    console.log("Compare " + EditarBoletoVm.viaje.origen +" === "+ auxOrigen.origen);
                    if(EditarBoletoVm.viaje.origen === auxOrigen.origen){
                        EditarBoletoVm.listaDestinos = auxOrigen.destinos;
                        EditarBoletoVm.viaje.destino = auxOrigen.destinos[0].destino;
                    }
                }
            }
            if(EditarBoletoVm.initcarga)  {
            EditarBoletoVm.viaje.destino = EditarBoletoVm.uniqueTicket.destino;
            EditarBoletoVm.initcarga = false;
          }
            commonModal.hideWait();
        }


        function loadOrigen(){
          commonModal.showWait();
                   EditarBoletoVm.tiposViajesList = [{
                       value : 'S',
                       label : 'Viaje sencillo'
                   },
                   {
                       value : 'R',
                       label : 'Viaje redondo'
                   }];
                   $http.get($rootScope.contextoServices+'/webresources/search/origen',{})
                       .success(function(data){
                           if(data.success){
                               EditarBoletoVm.origenDestinoList = data.result.origenes;
                               EditarBoletoVm.viaje.cveOrigen = EditarBoletoVm.origenDestinoList[1].origen;
                               EditarBoletoVm.loadDestinos();
                           }else{
                               console.log("Ocurrio un error");
                           }

                       }).error(commonFn.errorCommon);

               }
               var parts = EditarBoletoVm.uniqueTicket.fechaViaje.split('/');
               EditarBoletoVm.FechaIda = new Date(parts[2], parts[1]-1, parts[0]);




}
})();
