(function(){

angular.module('ERApp')
.controller('FelizViajeController',['$rootScope', '$http','$window','showNotif','commonModal','commonFn', '$location', "$route", FelizViajeController])

function FelizViajeController($rootScope, $http,$window,showNotif,commonModal,commonFn, $location , $route){
    $rootScope.curretOption = "";
    $rootScope.page = 9;
    $rootScope.cambioHorario = false;
    var FelizViajeVm = this;
    FelizViajeVm.finalizar = finalizar;
    var qry = $location.search('success', 'idTransactional');
    console.log("Transaction => " + qry.search.idTransactional );
    console.log($location.search().idTransactional);
    FelizViajeVm.transaction = $location.search().idTransactional;

    function finalizar(){
      $rootScope.page = 0;
      $window.location.href = "#/";
      $route.reload();
    }




}
})();
