(function(){

  angular.module('ERApp')
  .controller('MapaController',MapaController);

  function MapaController($rootScope, $http, showNotif, commonModal,commonFn){

    $rootScope.curretOption = "PUNTOS DE VENTA";
    var mapaVm = this;
    var terminalesLista ;
    var contexto= $rootScope.contextoServices;



            //agustar el largo del mapa en la pantalla
            var h = window.innerHeight;
            var h1=h-170;
            document.getElementById("map").style.height = h1+"px";



            loadOrigen();
      /* variable tipo json que contiene la estructura
      y definicios de las sucursales */
      var cities = [];// fin cities


/*  funcionalidad para establecer opciones
          determinadas de ubicacion del mapa*/
          var mapOptions = {
            zoom: 25,
            center: new google.maps.LatLng(19.3424516,-99.1408573)
              //mapTypeId: google.maps.MapTypeId.SATELLITE

            }


            /* se establece el mapa */
            $rootScope.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            /* variable para almacenar los marcadores aplicados */
            $rootScope.markers = [];



            var infoWindow = new google.maps.InfoWindow();

            /* metodo para llenar el arreglo cities */
            function llenaCitiesList (){
              console.log("***llenaCitiesList**");

              for (var i = 0; i < terminalesLista.length; i++) {


                var newCity={
                  city : terminalesLista[i].cvePoint,
                  desc : terminalesLista[i].cvePoint,
                  lat : terminalesLista[i].origen.latitud,
                  long : terminalesLista[i].origen.longitud
                }


                console.log("*** add city **"+newCity);
                cities.push(newCity);


              };


              console.log("***fin llenaCitiesList**");

         }  //fin llenaCitiesList



            /* metodo para definir los marcadores ,
            osea estableder o definir la ubicacion de las sucursales
            dentro del mapa */
            var createMarker = function (info){

            // se establecen las propiedades del marcador
            var marker = new google.maps.Marker({
              map: $rootScope.map,
              position: new google.maps.LatLng(info.lat, info.long),
              title: info.city,
              icon:'images/icon_location_azul.png'
            });
            marker.content = '<div class="infoWindowContent">' + info.desc + '</div>';

            // evento al hacer click al marcado rl cual desplegara informacion
            google.maps.event.addListener(marker, 'click', function(){
              infoWindow.setContent('<h4>' + marker.title + '</h4>' + marker.content);
              infoWindow.open($rootScope.map, marker);
            });

            $rootScope.markers.push(marker);

         }  //fin createMarker




         /*para mandar a llamar la funcion callback para
         establecer la posicion actual del usuario */

         function geolocalizacion(){

          console.log("*** geolocalizacion **");
           commonModal.showWait();
          navigator.geolocation.getCurrentPosition(callback);
           commonModal.hideWait();
        }



        var latLong;
        var lat ;
        var lon ;

        /*metodo para hubicar la posicion actual del usuario */
        function callback(position) {


          console.log("*** callback **");


          // se obtiene la latitud y longitud ,
          // de la posicion actual del usuario
          lat = position.coords.latitude;
          lon = position.coords.longitude;

             // se establece la ubicacion actual al mapa por medio de un marcador
             latLong = new google.maps.LatLng(lat, lon);


            // latLong = new google.maps.LatLng(19.0754195,-98.207425);

             var marker = new google.maps.Marker({
              map: $rootScope.map,
              position: latLong,
              title: "Tu Posicion Actual .",
              icon:'images/icon_location_naranja.png',
            });

             marker.setMap(map);
             $rootScope.map.setZoom(12);
             $rootScope.map.setCenter(marker.getPosition());


             infoWindow.setContent('<h4>' +'Tu Posiciòn Actual .'+ '</h4>' );
             infoWindow.open($rootScope.map, marker);

             // evento al hacer click al marcador el cual desplegara informacion
             google.maps.event.addListener(marker, 'click', function(){
              infoWindow.setContent('<h4>' +'Tu Posiciòn Actual .'+ '</h4>' );
              infoWindow.open($rootScope.map, marker);
            });



             /* ************** inicia funcionalidad del maker mas cercano ******************** */

             /*definicion de variables a utilizar*/
             var latLong_cercano,distancia_cercano,distancia_cercano_fin,latLong_cercano_fin;

             var latLong_cercano_fin =new google.maps.LatLng(cities[0].lat,cities[0].long);
             var pos=0;
             var distancia_cercano_fin=getDistance(latLong,latLong_cercano_fin);


             var marker_cercano;


             /*inicia la busqueda de las dictancia mas cercana a la ubicacion del usuario */
             for (i = 0; i < cities.length; i++){

              /*de la lista de ubicaciones se obtiene la latitud y longitud */
              latLong_cercano = new google.maps.LatLng(cities[i].lat,cities[i].long);
              /*se obtiene la distancia de la posicion al marcador */


              distancia_cercano=getDistance(latLong,latLong_cercano);

              console.log("distancia :"+getDistance(latLong,latLong_cercano));
              /*se comparan las distancias */

              console.log(distancia_cercano+" < "+distancia_cercano_fin);
              if (distancia_cercano<distancia_cercano_fin) {


                console.log("distancia_cercano_fin :"+distancia_cercano_fin);


                distancia_cercano_fin=distancia_cercano;
                latLong_cercano_fin=latLong_cercano;
                pos=i;

              }


             }// fin for cities

             /* se agrega el marcador mas cercano al mapa */
             marker_cercano= new google.maps.Marker({
              map: $rootScope.map,
              position: latLong_cercano_fin,
              title: cities[pos].city,
              icon:'images/icon_location_rojo.png'
            });


             marker_cercano.setMap(map);


             marker_cercano.content = '<div class="infoWindowContent">' + 'TERMINAL MAS CERCANA A TU POSICIÒN'+ '</div>';

            // evento al hacer click al marcador el cual desplegara informacion
            google.maps.event.addListener(marker_cercano, 'click', function(){
              infoWindow.setContent('<h4>' + marker_cercano.title + '</h4>' + marker_cercano.content);
              infoWindow.open($rootScope.map, marker_cercano);
            });


            /*inicia el llenado de los marcadores */

            for (i = 0; i < cities.length; i++){

              console.log("city "+cities[i].city);
              if( pos!= i  ){

                console.log("city "+cities[i].city);
                console.log("pos "+pos);
                console.log("city "+i);
                createMarker(cities[i]);
              }


            }// fin for cities

            commonModal.hideWait();
    }//fin calback function



    function rad(x) {
      return x * Math.PI / 180;
    }

    /* funcion para obtener la distancia en metros entre 2 marcadores*/
    function getDistance(p1, p2) {

        var R = 6378137; //radio de la tierra en metros
        var dLat = rad(p2.lat() - p1.lat());
        var dLong = rad(p2.lng() - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
      }



              //Funcion para la carga de origenes
      function loadOrigen(){


                console.log("*** loadOrigen *** ");
               commonModal.showWait();
               $http.get(contexto+'/webresources/coordendadas',{})
               .success(function(data){

                 console.log("*** loadOrigen  success *** ");
                commonModal.hideWait();


                 console.log("*** data *** "+data);
                terminalesLista=data;
                llenaCitiesList();
                geolocalizacion();


              })
               .error(commonFn.errorCommon)


             }

  }//fin  MapaController



})();
